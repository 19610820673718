import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';

import Show from '../HOC/Show';
import { createDispatchBindings } from '../../utils/redux';
import config from '../../config';
import { layers, toggleMRMSLayer, createMRMSLayer } from '../../utils/map/layers';
import { setMrmsLayerActive } from '../../reducers/mrmsLayer';
import { setUnit, setAmount, getUnit, getAmount } from '../../reducers/mrms';

const { drawerMinWidth, drawerMaxWidth } = config;

const useStyles = makeStyles({
  radarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    paddingBottom: 0,
  },
  layerSectionTitle: {
    margin: 0,
    marginLeft: 10,
    textAlign: 'left',
  },
  radarContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    minWidth: drawerMinWidth,
    maxWidth: drawerMaxWidth,
    overflowX: 'hidden',
  },
  mrmsControls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },
  dropdown: {
    margin: '0px 5px',
    fontSize: 14,
    padding: 0,
  },
});

const MRMSControls = ({ mrmsLayer, setMrmsLayerActive }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const unit = useSelector(getUnit);
  const amount = useSelector(getAmount);

  const toggleLayer = (e) => {
    setMrmsLayerActive(e.target.checked);
    if (e.target.checked) createMRMSLayer({ unit, amount });
    if (layers.mrms) toggleMRMSLayer(e.target.checked);
  };


  return (
    <div className={classes.radarContentWrapper}>
      <div className={classes.radarHeader}>
        <p className={classes.layerSectionTitle}>MRMS Rainfall</p>
        <Switch
          checked={mrmsLayer.active}
          onChange={toggleLayer}
        />
      </div>
      <Show test={mrmsLayer.active}>
        <div className={classes.mrmsControls}>
          <Select
            className={classes.dropdown}
            value={amount}
            onChange={(e) => dispatch(setAmount(e.target.value))}
          >
            {unit === 'minutes' && (
              Array.from({ length: 60 }, (_, i) => i + 1).map((value) => (
                <MenuItem key={value} value={value}>{value}</MenuItem>
              ))
            )}
            {unit === 'hours' && (
              Array.from({ length: 24 }, (_, i) => i + 1).map((value) => (
                <MenuItem key={value} value={value}>{value}</MenuItem>
              ))
            )}
            {unit === 'days' && (
              Array.from({ length: 3 }, (_, i) => i + 1).map((value) => (
                <MenuItem key={value} value={value}>{value}</MenuItem>
              ))
            )}
          </Select>
          <Select
            className={classes.dropdown}
            value={unit}
            onChange={(e) => dispatch(setUnit(e.target.value))}
          >
            <MenuItem value="minutes">Minutes</MenuItem>
            <MenuItem value="hours">Hours</MenuItem>
            <MenuItem value="days">Days</MenuItem>
          </Select>
          <Tooltip title="Refresh">
            <IconButton size="small" onClick={() => createMRMSLayer({ unit, amount })}>
              <RefreshIcon size="small" />
            </IconButton>
          </Tooltip>
        </div>
      </Show>
    </div>
  );
};

const mapStateToProps = ({ mrmsLayer }) => ({ mrmsLayer });
const mapDispatchToProps = createDispatchBindings({ setMrmsLayerActive });

export default connect(mapStateToProps, mapDispatchToProps)(MRMSControls);
