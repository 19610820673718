/* eslint-disable import/no-cycle */
import L from 'leaflet';
import qs from 'querystring';

import reduxStore from '../../store';
import { onEachFeature, setActiveMarker } from './gaugeMarkers';
import { updateLayerValue  } from '../../reducers/mapLayers';
import getTimeCode from '../get-time-code';
import { getMap } from './leaflet';
import { setGauges, maybeRunIconStuff } from '../../reducers/gauges';
import { setMRMSPointRainfall } from '../../reducers/mrmsPointRainfall';
import { openDrawer, setGaugeInfoDrawerTab } from '../../reducers/gaugeInfoDrawer';
import { setSidebar } from '../../reducers/sidebar';
import { unselectedFlowMarker, getFlowMarkerStyling } from './flowStatusMarkers';
import loading from '../loading';
import { getRainMarkerStyling, unselectedRainMarker } from './rainfallStatusMarkers';
import history from '../../history';
import mapLayers from '../../reducers/mapLayers';


const myRenderer = L.canvas({ padding: 0.5 });

let initialLayerSettings;
export const setInitialLayerSettings = (mapLayerSettings) => {
  initialLayerSettings = mapLayerSettings;
};

// =====================================================
// LAYER UPDATE FUNCTIONS
// =====================================================
export const layers = {};

export const getLayer = (layerName) => layers[layerName];

export const addLayer = (layerName) => {
  const map = getMap();
  const activeLayer = reduxStore.getState().mapLayers.gaugeIconType;
  if (layerName === 'gaugeIcons') {
    if (activeLayer === 'flow') {
      if (layers.flowIcons) layers.flowIcons.addTo(map);
    }
    if (activeLayer === 'rain') {
      if (layers.rainIcons) layers.rainIcons.addTo(map);
    }
  } else {
    layers[layerName].addTo(map);
  }
  // make sure gauges interactable layer is always on top
  if (layerName !== 'flowIcons' && layerName !== 'rainIcons') {
    if (activeLayer === 'flow' && layers.flowIcons) layers.flowIcons.bringToFront();
    if (activeLayer === 'rain' && layers.rainIcons) layers.rainIcons.bringToFront();
  }
};

export const removeLayer = (layerName) => {
  const map = getMap();
  if (layerName === 'gaugeIcons') {
    if (layers.flowIcons) layers.flowIcons.removeFrom(map);
    if (layers.rainIcons) layers.rainIcons.removeFrom(map);
  } else if (layers[layerName]) layers[layerName].removeFrom(map);
};

export const changeLayerOpacity = (layerName, opacity) => {
  if (layers[layerName]) {
    if (layerName === 'inundationLayer') layers[layerName].setOpacity(opacity);
    else {
      layers[layerName].setStyle({
        ...layers[layerName].options.style,
        opacity,
        fillOpacity: opacity / 5,
      });
    }
  }
};

export const setLayer = (name, layer, options = {}) => {
  layers[name] = layer;
  if (options.addToMap) addLayer(name);
  if (options.active) layers[name].active = true;
};

export const updateLayerObject = ({ layerName, key, value }) => {
  layers[layerName][key] = value;
};

// =====================================================
// CREATE LAYER FUNCTIONS
// =====================================================

export const createFlowStatusLayer = async (layerData) => {
  if (layers.flowIcons) removeLayer('flowIcons');
  const { forecastIconData, gaugeThresholds, dateRange } = await reduxStore.getState();
  const AGOLayer = L.geoJSON(layerData, {
    style: { weight: 2, radius: 8 },
    pointToLayer: (geoJsonPoint, latlng) => {
      const { iconText, iconColor, forecastCircleColor } = getFlowMarkerStyling(geoJsonPoint.properties.site_id, forecastIconData, gaugeThresholds, dateRange);
      if (iconText === undefined && iconColor === undefined && forecastCircleColor === undefined) return;
      const icon = unselectedFlowMarker({ iconColor, iconText, forecastCircleColor });
      return L.marker(latlng, { icon });
    },
    onEachFeature,
    renderer: myRenderer,
  });
  const { opacity, visible } = initialLayerSettings.gaugeIcons;
  const iconType = reduxStore.getState().mapLayers.gaugeIconType;  
  setLayer('flowIcons', AGOLayer, { addToMap: iconType === 'flow' && visible, active: iconType === 'flow' });  
  if (opacity < 1) changeLayerOpacity('flowIcons', initialLayerSettings.gaugeIcons.opacity);
  const selectedGaugeId = history.location.pathname.split('/')[2];
  reduxStore.dispatch(updateLayerValue({layerName: 'gaugeIcons', key: 'visible', value: true }));
  if (selectedGaugeId) setActiveMarker(selectedGaugeId);
};

export const createRainfallStatusLayer = (layerData) => {
  if (layers.rainIcons) removeLayer('rainIcons');
  const AGOLayer = L.geoJSON(layerData, {
    style: { weight: 2, radius: 8 },
    pointToLayer: (geoJsonPoint, latlng) => {
      const { iconColor } = getRainMarkerStyling(geoJsonPoint.properties.site_id);
      const icon = unselectedRainMarker({ iconColor });
      return L.marker(latlng, { icon });
    },
    onEachFeature,
    renderer: myRenderer,
  });
  const { opacity, visible } = initialLayerSettings.gaugeIcons;
  const iconType = reduxStore.getState().mapLayers.gaugeIconType;
  setLayer('rainIcons', AGOLayer, { addToMap: iconType === 'rain' && visible, active: iconType === 'rain' });
  if (opacity < 1) changeLayerOpacity('rainIcons', initialLayerSettings.gaugeIcons.opacity);
};

export const createAGOLayers = async (AGOLayerData) => {
  const AGOLayerNames = ['gaugeIcons', 'AGOChannels', 'AGOAddicksBarkerFedLands', 'AGOCatchments', 'AGOWatersheds'];
  for (let i = 0; i < AGOLayerData.length; i += 1) {
    let AGOLayer;
    const layerName = AGOLayerNames[i];
    if (i === 0) {
      layers.gauges = AGOLayerData[i];
      await reduxStore.dispatch(setGauges(AGOLayerData[i]));
      await reduxStore.dispatch(maybeRunIconStuff());
    } else {
      AGOLayer = L.geoJSON(AGOLayerData[i], {
        style: {
          color: initialLayerSettings[layerName].color,
          weight: 0.75,
        },
      });
      //Correct layering feature  
      if (layers[AGOLayerNames[i]] == null )                         
        setLayer(layerName, AGOLayer, { addToMap: initialLayerSettings[layerName].visible });

      if (initialLayerSettings[layerName].opacity < 1) changeLayerOpacity(layerName, initialLayerSettings[layerName].opacity);
    }
    loading.end('Fetching AGO Layer Data...');
  }
};

export const createInundationLayer = async () => {
  const dateObj = new Date();
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const hour = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const timeCode = await getTimeCode({ year, month, day, hour, minutes });
  const inundationLayer = new L.InundationLayer(timeCode, {
    renderer: myRenderer,
  });  
  setLayer('inundationLayer', inundationLayer, { addToMap: initialLayerSettings.inundationLayer.visible });
  if (initialLayerSettings.inundationLayer.opacity < 1) changeLayerOpacity('inundationLayer', initialLayerSettings.inundationLayer.opacity);
};

const fetchMRMSPointRainfall = async (lat, lng) => {
  const { unit, amount } = reduxStore.getState().mrms;
  const res = await fetch(`/api/mrms/point?${qs.stringify({ lat, lng, unit, amount })}`);
  if (res.status > 299) return console.error('failed to fetch mrms point rainfall data');
  const data = await res.json();
  reduxStore.dispatch(setMRMSPointRainfall(data));
};

export const createMRMSLayer = ({ unit, amount }) => {
  if (layers.mrms) removeLayer('mrms');
  const mrmsLayer = L.imageOverlay(`/api/mrms?${qs.stringify({ unit, amount })}`, [[28.295340844, -97.368378102], [31.591755731, -93.739021959]]);
  setLayer('mrms', mrmsLayer, { addToMap: true });
  const map = getMap();
  map.on('click', (e) => {
    if (map.hasLayer(layers.mrms)) {
      fetchMRMSPointRainfall(e.latlng.lat, e.latlng.lng);
      reduxStore.dispatch(openDrawer());
      reduxStore.dispatch(setSidebar(false));
      reduxStore.dispatch(setGaugeInfoDrawerTab(1));
    }
  });
};

export const toggleMRMSLayer = async (show) => {
  if (show) addLayer('mrms');
  else removeLayer('mrms');
};

