// action type constants
export const GET_ADMIN_FORECAST_ENSEMBLE = 'GET_ADMIN_FORECAST_ENSEMBLE';
export const SET_ADMIN_FORECAST_ENSEMBLE = 'SET_ADMIN_FORECAST_ENSEMBLE';
export const SET_ENSEMBLE_DISABLE = 'SET_ENSEMBLE_DISABLE';

// actionCreators
export const getAdminForecastEnsembles = () => ({ type: GET_ADMIN_FORECAST_ENSEMBLE });
export const setAdminForecastEnsembles = (payload) => ({ type: SET_ADMIN_FORECAST_ENSEMBLE, payload });
export const setEnsembleDisable = (payload) => ({ type: SET_ENSEMBLE_DISABLE, payload });

const initialState = [];

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ADMIN_FORECAST_ENSEMBLE:
      state = payload;
      return state;
    case SET_ENSEMBLE_DISABLE: {
      const { name, disable } = payload;
      console.log(name, disable);
      let indexToChange;
      state.find((ensemble, index) => {
        if (ensemble.name === name) {
          indexToChange = index;
          return ensemble;
        }
      });
      console.log(indexToChange);
      state[indexToChange] = { ...state[indexToChange], disable };
      return state;
    }
    default:
      return state;
  }
};
