import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Show from '../HOC/Show';
import NotificationsTable from './NotificationsTable';
import PersonalNotifications from './PersonalNotifications';

const useStyles = makeStyles({
  tableTitle: {
    fontWeight: 'bold',
    marginLeft: 20,
  },
  noNotifications: {
    width: '100%',
    textAlign: 'center',
    marginTop: 20,
  },
});

const sortNotifications = (notifications) => {
  const sortedNotifications = [...notifications];
  sortedNotifications.sort((a, b) => {
    // sort notifications based on forecasted stage if forecast notification or on stage if real-time notification
    if (a.reading_source && b.reading_source) return (b.forecast_current - b.threshold) - (a.forecast_current - a.threshold);
    if (a.reading_source) return (b.stage - b.threshold) - (a.forecast_current - a.threshold);
    if (b.reading_source) return (b.forecast_current - b.threshold) - (a.stage - a.threshold);
    return (b.stage - b.threshold) - (a.stage - a.threshold);
  });
  return sortedNotifications;
};

const NotificationContent = ({ notifications }) => {
  const classes = useStyles();
  const { globalNotifications, personalNotifications } = notifications;
  const [sortedGlobalNotifications, setSortedGlobalNotifications] = useState([]);
  const [sortedPersonalNotifications, setSortedPersonalNotifications] = useState([]);

  useEffect(() => {
    if (globalNotifications?.length) setSortedGlobalNotifications(sortNotifications(globalNotifications));
  }, [globalNotifications]);

  useEffect(() => {
    if (personalNotifications?.length) setSortedPersonalNotifications(sortNotifications(personalNotifications));
  }, [personalNotifications]);

  return (
    <>
      {(globalNotifications?.length || personalNotifications?.length) ? (
        <div className={classes.notificationWrapper}>
          <Show test={globalNotifications?.length}>
            <p className={classes.tableTitle}>Global Notifications</p>
            <NotificationsTable data={sortedGlobalNotifications} notification_type="globalNotifications" />
          </Show>
          <Show test={personalNotifications?.length}>
            <p className={classes.tableTitle}>Personal Notifications</p>
            <PersonalNotifications data={sortedPersonalNotifications} />
          </Show>
        </div>
      ) : <p className={classes.noNotifications}>No new notifications at this time...</p> }
    </>
  );
};

const mapStateToProps = ({ notifications }) => ({ notifications });

export default connect(mapStateToProps)(NotificationContent);
