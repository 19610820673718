import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Tooltip from '@material-ui/core/Tooltip';

import GaugeInfoDrawer from './GaugeInfoDrawer/GaugeInfoDrawer';
import { createDispatchBindings } from '../../utils/redux';
import { setOnMap } from '../../reducers/onMap';
import { setSidebar } from '../../reducers/sidebar';
import { fetchAGOLayers } from '../../reducers/mapLayers';
import LeafletMap from '../LeafletMap';
import { layers } from '../../utils/map/layers';
import { closeDrawer } from '../../reducers/gaugeInfoDrawer';
import { fetchRainfallStatus } from '../../reducers/rainfallStatus';

const useStyles = makeStyles({
  mapWrapper: {
    border: '1px solid black',
  },
  menuButton: {
    position: 'absolute',
    left: 20,
    top: 10,
    zIndex: 999,
    backgroundColor: '#efefef',
    opacity: 0.9,
    '&:hover': {
      opacity: 1,
      backgroundColor: '#eee',
    },
  },
  hiddenMenuButton: {
    display: 'none',
  },
});

const MapPage = ({ sidebar, setSidebar, fetchAGOLayers, closeDrawer, start, fetchRainfallStatus }) => {
  const classes = useStyles();

  const handleOpen = () => {
    setSidebar(true);
    closeDrawer();
  };

  useEffect(() => {
    if (!layers.flowIcons && !layers.rainIcons) {
      fetchAGOLayers();
    }
  }, []);
  useEffect(() => {
    if (start) {
      fetchRainfallStatus({ data_start: start });
    }
  }, [start]);
  return (
    <>
      <Tooltip title="Layer Settings">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleOpen}
          className={sidebar ? classes.hiddenMenuButton : classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <LeafletMap />
      <GaugeInfoDrawer />
    </>
  );
};

const mapStateToProps = ({ sidebar, gauges, mapLayers }) => ({ sidebar, gauges, mapLayers });
const mapDispatchToProps = createDispatchBindings({ setSidebar, setOnMap, fetchAGOLayers, closeDrawer });

export default connect(mapStateToProps, mapDispatchToProps)(MapPage);
