import { takeLatest, put } from 'redux-saga/effects';
import qs from 'querystring';
import { GET_MRMS_RAINFALL, setMRMSRainfall } from '../../reducers/mrmsRainfall';

import loading from '../../utils/loading';

function* fetchMRMSRainfall({ lat, lng, fromDate, toDate }) {
  const loadingMsg = 'Fetching MRMS Rainfall Data...';
  loading.start(loadingMsg);
  try {
    const resp = yield fetch(`/api/mrms/point?${qs.stringify({ lat, lng, fromDate, toDate })}`);
    if (resp.status > 299) throw new Error('Invalid Response Code');
    const data = yield resp.json();
    yield put(setMRMSRainfall(data));
  } catch (e) {
    console.log('ERROR fetching MRMS Rainfall data', e);
    // handle error
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(GET_MRMS_RAINFALL, fetchMRMSRainfall);
}
