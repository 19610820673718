import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import BasemapDropdown from './BasemapDropdown';
import LayerSettings from './LayerSettings';
import { setSidebar } from '../../reducers/sidebar';
import { openModal } from '../../reducers/modal';
import { setupMapServices } from '../../reducers/mapRefs';
import { createDispatchBindings } from '../../utils/redux';
import { getMap } from '../../utils/map/leaflet';
import GaugeFilters from './GaugeFilters';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    overflowX: 'hidden',
    position: 'absolute',
    height: 'calc(100vh - 64px)',
    left: 0,
    top: 64,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: '#fafafa',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  sidebarContent: {
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    height: '100%',
  },
  toolbarIcon: {
    minHeight: 64,
    display: 'flex',
    borderBottom: '1px solid #dcdcdc',
  },
}));

const Sidebar = ({ sidebar, setSidebar, setupMapServices }) => {
  const classes = useStyles({ sidebar });

  if (getMap()) {
    setupMapServices();
  }

  const handleClose = () => setSidebar(false);

  return (
    <Drawer
      classes={{
        paper: clsx(classes.drawerPaper, !sidebar && classes.drawerPaperClose),
      }}
      variant="persistent"
      anchor="left"
      open={sidebar}
    >
      <div className={classes.toolbarIcon}>
        <Tooltip title="Collapse Menu">
          <IconButton onClick={handleClose}>
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.sidebarContent}>
        <BasemapDropdown />
        <Divider />
        <GaugeFilters />
        <Divider />
        <LayerSettings />
      </div>
    </Drawer>
  );
};

const mapStateToProps = ({ sidebar, currentUser }) => ({ sidebar, currentUser });
const mapDispatchToProps = createDispatchBindings({ setSidebar, openModal, setupMapServices });

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
