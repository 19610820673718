import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import IconButton from '@material-ui/core/IconButton';

import CustomDateSection from './CustomDateSection';
import { createDispatchBindings } from '../../utils/redux';

const useStyles = makeStyles({
  datePickerIcon: {
    color: 'white',
  },
});

const DatePickerPopoverButton = ({  }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton variant="contained" color="primary" onClick={handleClick}>
        <CalendarTodayIcon className={classes.datePickerIcon} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CustomDateSection />
      </Popover>
    </div>
  );
};

const mapStateToProps = ({  }) => ({  });
const mapDispatchToProps = createDispatchBindings({  });

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerPopoverButton);
