import { takeLatest, put, select } from "redux-saga/effects";
import moment from "moment";

import loading from "../utils/loading";
import { FETCH_GAUGE_DATA, setGaugeData } from "../reducers/selectedGaugeData";
import { getDateRange } from "../reducers/dateRange";
import { setSummaryTableRowValues } from "../reducers/summaryTable";
import { getGaugeThresholds } from "../reducers/gaugeThresholds";
import {GetDateWithoutTZ} from "../utils/convertTime";

function* fetchGaugeData({ site_id }) {
  const loadingMsg = "Fetching Gauge Data...";
  loading.start(loadingMsg);
  const { start, end } = yield select(getDateRange);
  
  try {
    

    const response = yield fetch(
      `/api/gauges/site/${site_id}?data_start=${start}&data_end=${end}`
    );
    if (response.status > 299) throw new Error("Error fetching Gauge Data");
    const gaugeData = yield response.json();
   
    yield put(
      setGaugeData({
        10: gaugeData["10"] || [],
        11: gaugeData["11"] || [],
        20: gaugeData["20"] || [],
        25: gaugeData["25"] || [],
        site_id: gaugeData.site_id,
      })
    );
    let peakStagePoint = false;
    if (gaugeData["20"]?.length) {
      const sortedGaugeData = [...gaugeData["20"]].sort(
        (a, b) => b.data_time - a.data_time
      );
      // eslint-disable-next-line prefer-destructuring
      peakStagePoint = gaugeData["20"][0];
      for (let i = 0; i < sortedGaugeData.length; i += 1) {
        if (
          sortedGaugeData[i].data_value >= peakStagePoint.data_value &&
          moment(sortedGaugeData[i].data_time).valueOf() <
            moment(peakStagePoint.data_time).valueOf()
        ) {
          peakStagePoint = sortedGaugeData[i];
        }
      }
    }
    const gaugeThresholds = yield select(getGaugeThresholds);
    let currentGaugeThresholds;
    let floodStatus = "Normal";
    if (gaugeThresholds.length) {
      currentGaugeThresholds = gaugeThresholds.find(
        (gauge) => Number(gauge.gauge_id) === Number(gaugeData.site_id)
      );
      if (currentGaugeThresholds) {
        if (peakStagePoint.data_value < currentGaugeThresholds.el_fld_act) {
          floodStatus = "Normal";
        } else if (
          currentGaugeThresholds.el_chtop &&
          peakStagePoint.data_value < currentGaugeThresholds.el_chtop
        ) {
          floodStatus = "Near Flooding";
        } else if (
          currentGaugeThresholds.el_chtop &&
          peakStagePoint.data_value >= currentGaugeThresholds.el_chtop
        ) {
          floodStatus = "Flooding";
        }
      }
    }
    const stateConfig = {
      rowKey: "Observed",
      keyValueArray: [],
    };
    if (peakStagePoint) {
      stateConfig.keyValueArray.push(
        { key: "stagePeak", value: peakStagePoint.data_value },
        { key: "timeOfPeak", value: peakStagePoint.data_time },
        { key: "floodStatus", value: floodStatus }
      );
    }
    let flowPointAtPeakStage = false;
    let isValueForPeakPointZero=false;
    if (gaugeData["25"]?.length) {
      gaugeData["25"].forEach((point) => {
        if (point.data_time === peakStagePoint.data_time) {
          if (point.data_value === 0)
              isValueForPeakPointZero=true;

          flowPointAtPeakStage = point;
        }
      });

      if (!isValueForPeakPointZero && !flowPointAtPeakStage  && peakStagePoint)
          flowPointAtPeakStage = flowVolumeToNearestPickPoint(peakStagePoint,flowPointAtPeakStage,gaugeData[25],start);
    }
    
    stateConfig.keyValueArray.push({
      key: "flow",
      value: flowPointAtPeakStage.data_value ? flowPointAtPeakStage.data_value : '' ,
    });
    
   
    if (stateConfig.keyValueArray.length) {
      yield put(setSummaryTableRowValues(stateConfig));
    }
  } catch (e) {
    // TODO: alert user
    console.log("ERROR fetching gauge stage data", e);
  } finally {
    loading.end(loadingMsg);
  }
}

function flowVolumeToNearestPickPoint(peakStagePoint,flowPointAtPeakStage,gaugeData,start)
{
  let lowerLimitForFVDataValue = moment.utc(peakStagePoint.data_time).subtract(30, 'minutes').format();
  let upperLimitForFVDataValue = moment.utc(peakStagePoint.data_time).add(30, 'minutes').format();

  /* let lowerLimitRecords= gaugeData.filter(
     (gauge) => gauge.data_time >= lowerLimitForFVDataValue && gauge.data_time <= peakStagePoint.data_time
  ); */
  
  /* const sortedLowerLimitRecords = [...lowerLimitRecords].sort(
    (a, b) => b.data_time - a.data_time
  ); */

  /* let lowerLimitPoint= lowerLimitRecords.find(
    (gauge) => gauge.data_value && gauge.data_value > 0
  );
 */
  let lowerLimitPoint = [...gaugeData].filter(
    (gauge) => gauge.data_time >= lowerLimitForFVDataValue && gauge.data_time <= peakStagePoint.data_time
  ).find(
    (gauge) => gauge.data_value && gauge.data_value > 0
  ); 

  /* let upperLimitRecords= gaugeData.filter(
     (gauge) => gauge.data_time >= peakStagePoint.data_time && gauge.data_time <= upperLimitForFVDataValue
  );
  
  let mappedUpperLimitRecords=  upperLimitRecords.map(gauge =>  ( { ...gauge, date_time_asc: new Date(GetDateWithoutTZ(gauge.data_time))  } ));

  const sortedUpperLimitRecords = mappedUpperLimitRecords.sort(
    (a, b) => a.date_time_asc - b.date_time_asc
  );

  let upperLimitPoint= sortedUpperLimitRecords.find(
    (gauge) => gauge.data_value && gauge.data_value > 0
  ); */

  let upperLimitPoint = [...gaugeData].filter(
    (gauge) => gauge.data_time >= peakStagePoint.data_time && gauge.data_time <= upperLimitForFVDataValue
  ).map(
    gauge =>  ( { ...gauge, date_time_asc: new Date(GetDateWithoutTZ(gauge.data_time))  } )
  ).sort(
    (a, b) => a.date_time_asc - b.date_time_asc
  ).find(
    (gauge) => gauge.data_value && gauge.data_value > 0
  );
  
  if (upperLimitPoint && lowerLimitPoint)
  {
      let lowerLimitDifference = new Date(GetDateWithoutTZ(peakStagePoint.data_time)) - new Date(GetDateWithoutTZ(lowerLimitPoint.data_time));   //Math.abs(moment.utc(peakStagePoint.data_time).format - moment.utc(lowerLimitPoint.data_time).format);
      let upperLimitDifference = new Date(GetDateWithoutTZ(upperLimitPoint.data_time)) - new Date(GetDateWithoutTZ(peakStagePoint.data_time));

      if (lowerLimitDifference < upperLimitDifference)
        flowPointAtPeakStage = lowerLimitPoint;
      else
        flowPointAtPeakStage = upperLimitPoint;
  }
  else if (upperLimitPoint)
    flowPointAtPeakStage=upperLimitPoint;
  else if (lowerLimitPoint)
    flowPointAtPeakStage = lowerLimitPoint;

  return flowPointAtPeakStage;
}

export default function* watch() {
  yield takeLatest(FETCH_GAUGE_DATA, fetchGaugeData);
}
