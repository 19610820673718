// action type constants
export const SET_MRMS_POINT_RAINFALL = 'SET_MRMS_POINT_RAINFALL';

// actionCreators
export const setMRMSPointRainfall = (payload) => ({ type: SET_MRMS_POINT_RAINFALL, payload });

const initialState = {
  active: false,
  data: [],
};

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MRMS_POINT_RAINFALL:
      state = {
        active: true,
        data: payload,
      };
      return state;
    default:
      return state;
  }
};
