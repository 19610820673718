import { allWatersheds } from "../config/constants";

export default ({ features }) => {
  if (!features?.length) return [];
  const dropdownOptions = [{ label: allWatersheds, value: allWatersheds }];
  const watershedOptionsToExclude = 'Chocolate Bayou,Dickinson Bayou,Willow Bayou,Fair Childs Creek,Trinity River,Mustang Bayou,Brazos River,San Jacinto & Galveston Bay'
  const watershedValuesSplitted= watershedOptionsToExclude.split(',');
  
  features.forEach((gauge) => {
    const watershed = gauge.properties.Watershed;
    for (let i = 0; i < dropdownOptions.length; i += 1) { 
      if (dropdownOptions[i].value === watershed  || watershedValuesSplitted.indexOf(watershed) !== -1 ) return;
    }
    if (watershed.length > 1) dropdownOptions.push({ label: watershed, value: watershed });
  });
  return dropdownOptions;
};
