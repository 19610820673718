import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import StageFlowPlot from '../../Graphs/StageFlowPlot/StageFlowPlot';
import ExportCSVButton from '../../Graphs/ExportCSVButton';
import history from '../../../history';
import Show from '../../HOC/Show';
import NoData from '../../NoData';

import { GAUGE_SUB_MODAL, thresholdConfig } from '../../../config/constants';
import { setActiveMarker, clearActiveMarker } from '../../../utils/map/gaugeMarkers';
import { createDispatchBindings } from '../../../utils/redux';
import { updateToggleState, setToggleState } from '../../../reducers/gaugeThresholds';
import { openDrawer, closeDrawer, setGaugeInfoDrawerTab } from '../../../reducers/gaugeInfoDrawer';
import { setSidebar } from '../../../reducers/sidebar';
import { openModal } from '../../../reducers/modal';
import config from '../../../config';
import MrmsRainfallChart from '../../Graphs/MrmsRainfallPlot/MrmsRainfallChart';
import { fetchGaugeForecast } from '../../../reducers/gaugeForecast';
import  store  from '../../../store';
const { drawerHeight } = config;

const useStyles = makeStyles({
  drawerContentWrapper: {
    position: 'relative',
    width: '100%',
    height: drawerHeight,
    border: '2px 0 0 0 solid rgba(0,0,0,0.2)',
    boxShadow: '0px 18px 20px 20px rgba(0,0,0,0.75)',
    zIndex: 9999,
    padding: '0px 10px',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  giveShadow: {
    boxShadow: '0px 18px 20px 20px rgba(0,0,0,0.75)',
  },
  gaugeDetailsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'fixed',
    marginTop: -20,
  },
  gaugeDetail: {
    margin: '5px 5px 0 5px',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '2px 10px',
    border: '1px solid #ccc',
    borderRadius: 5,
  },
  dashboardButton: {
    marginLeft: 10,
  },
  graphControls: {
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftButtonGroup: {
    display: 'flex',
  },
  thresholdToggles: {
    position: 'absolute',
    top: 15,
    right: 60,
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: 10,
    borderRadius: 5,
    background: '#efefef',
  },
  checkboxInputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    margin: 0,
  },
  tabsWrapper: {
    position: 'absolute',
    top: 0,
    left: 10,
    display: 'flex',
    zIndex: 9999,
  },
  tab: {
    labelIcon: {
      fontSize: 12,
      padding: 0,
    },
  },
  timezone: {
    marginLeft: -200,
  },
});

const TabWrapper = withStyles({
  root: {
    minHeight: 'auto',
  },
})(Tabs);

const SmallTab = withStyles({
  root: {
    minHeight: 'auto',
    padding: '2px 4px',
    fontSize: 14,
  },
})(Tab);

const GaugeInfoDrawer = ({
  gauges,
  selectedGaugeData,
  gaugeThresholds,
  gaugeInfoDrawer,
  openDrawer,
  setSidebar,
  closeDrawer,  
  fetchGaugeForecast,
  openModal,
  updateToggleState,
  mrmsPointRainfall,
  setGaugeInfoDrawerTab,
  // setToggleState,
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const classes = useStyles();
  const location = useLocation();
  const gaugeId = location.pathname.split('/')[2];
  const [siteName, setSiteName] = useState('');

  const handleChangingTab = (e, newValue) => {
    setGaugeInfoDrawerTab(newValue);
  };

  useEffect(() => {
    if (Number(gaugeId)) {
      openDrawer();
      setSidebar(false);
      if (gauges) {
        setActiveMarker(gaugeId);
      }
    }
  }, [gaugeId, gauges]);

  useEffect(() => {
    if (gaugeId && selectedGaugeData.site_id !== gaugeId) {      
      const { hiddenForecastModels } = store.getState();
      fetchGaugeForecast({ forecast_id: hiddenForecastModels.selectedForecastId, gauge_id: gaugeId });
    }
  }, [gaugeId]);

  useEffect(() => {
    if (gaugeId && gaugeThresholds?.data?.length) {
      const currentGaugeThresholds = gaugeThresholds.data.find((gaugeThresholds) => Number(gaugeThresholds.gauge_id) === Number(gaugeId));
      if (currentGaugeThresholds) {
        setSiteName(currentGaugeThresholds.name);
      }
    }
  }, [gaugeId, gaugeThresholds?.data]);

  const handleNavToDashboard = () => {
    history.push({
      pathname: `/dashboard/${gaugeId}`,
      search: history.location.search,
    });
    closeDrawer();
  };

  const handleClosingDrawer = () => {
    clearActiveMarker();
    closeDrawer();
  };

  return (
    <Drawer variant="persistent" anchor="bottom" open={gaugeInfoDrawer.open} onClose={() => closeDrawer()} className={classes.drawer}>
      <div className={classes.drawerContentWrapper}>
        <Show test={!mrmsPointRainfall.data.length}>
          <TabWrapper value={gaugeInfoDrawer.tab} onChange={handleChangingTab}>
            <SmallTab className={classes.tab} label="Stage/Flow" />
          </TabWrapper>
        </Show>
        <Show test={mrmsPointRainfall.data.length}>
          <TabWrapper value={gaugeInfoDrawer.tab} onChange={handleChangingTab}>
            <SmallTab className={classes.tab} label="Stage/Flow" />
            <SmallTab className={classes.tab} label="MRMS Rainfall" />
          </TabWrapper>
        </Show>
        <Show test={gaugeInfoDrawer.tab === 0}>
          <div className={classes.thresholdToggles}>
            {Object.entries(gaugeThresholds.toggleState).map(([key, value]) => (
              <div key={key} className={classes.checkboxInputWrapper}>
                <Checkbox
                  checked={value}
                  onChange={(e) => updateToggleState({ key, value: e.target.checked })}
                  size="small"
                  style={{ color: thresholdConfig[key].color }}
                />
                <p className={classes.checkboxLabel}>{thresholdConfig[key].label}</p>
              </div>
            ))}
          </div>
          <div className={classes.gaugeDetailsWrapper}>
            <p className={classes.gaugeDetail}>{gaugeId} - {siteName}</p>
          </div>
          <StageFlowPlot
            gauge_id={gaugeId}
            thresholds={gaugeThresholds.data}
            thresholdToggleState={gaugeThresholds.toggleState}
            infoDrawer
          />
          <div className={classes.graphControls}>
            <div className={classes.leftButtonGroup}>
              <Button
                variant="contained"
                color="primary"
                className={classes.dashboardButton}
                onClick={handleNavToDashboard}
              >
                See in Dashboard
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.dashboardButton}
                onClick={() => openModal({
                  type: GAUGE_SUB_MODAL,
                  data: { gaugeId },
                })}
              >
                Notifications
              </Button>
            </div>
            <p className={classes.timezone}>{timezone === 'America/Chicago' ? 'CST/CDT' : timezone}</p>
            <ExportCSVButton graphType="stageFlow" />
          </div>
        </Show>
        <Show test={gaugeInfoDrawer.tab === 1}>
          <Show test={mrmsPointRainfall.data.length}>
            <MrmsRainfallChart />
          </Show>
          <Show test={!mrmsPointRainfall.data.length}>
            <NoData />
          </Show>
        </Show>
        <IconButton onClick={handleClosingDrawer} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </div>
    </Drawer>
  );
};

const mapStateToProps = ({ gauges, gaugeThresholds, gaugeInfoDrawer, selectedGaugeSensors, selectedGaugeData, mapRefs: { mapDiv }, dateRange, mrmsPointRainfall }) => ({ gauges, selectedGaugeSensors, gaugeInfoDrawer, selectedGaugeData, mapDiv, gaugeThresholds, dateRange, mrmsPointRainfall });
const mapDispatchToProps = createDispatchBindings({ fetchGaugeForecast, openDrawer, closeDrawer, setSidebar, openModal, updateToggleState, setToggleState, setGaugeInfoDrawerTab });

export default connect(mapStateToProps, mapDispatchToProps)(GaugeInfoDrawer);
