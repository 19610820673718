// action type constants
export const SET_CHART_REF = 'SET_CHART_REF';

// actionCreators
export const setChartRef = ({ chartId, chartInstance }) => ({ type: SET_CHART_REF, chartId, chartInstance });

const initialState = {
  stageFlowChart: false,
  rainfallChart: false,
};

// reducer
export default (state = initialState, { type, chartInstance, chartId }) => {
  switch (type) {
    case SET_CHART_REF:
      state[chartId] = chartInstance;
      return;
    default:
      return state;
  }
};
