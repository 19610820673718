import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import EditIcon from '@material-ui/icons/Edit';

import { getUsers, updateUser } from '../../reducers/users';
import { openModal } from '../../reducers/modal';
import { createDispatchBindings } from '../../utils/redux';
import { EDIT_USER_MODAL } from '../../config/constants';

const useStyles = makeStyles({
  userControlsWrapper: {
    width: '100%',
    height: '100%',
    padding: 20,
  },
  roleDropdown: {
    minWidth: 200,
  },
  deleteIcon: {
    color: 'red',
  },
});

const StyledTableRow = withStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#efefef',
    },
  },
})(TableRow);

const HeaderCell = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(TableCell);

const TableWrapper = withStyles({
  root: {
    marginTop: 20,
  },
})(Paper);

const UserControls = ({ users, getUsers, openModal }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    getUsers();
  }, []);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  return (
    <div className={classes.userControlsWrapper}>
      <TableContainer component={TableWrapper}>
        <Table size="small" className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <HeaderCell>Name</HeaderCell>
              <HeaderCell align="left">Email</HeaderCell>
              <HeaderCell align="left">Role</HeaderCell>
              <HeaderCell align="left">Controls</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <StyledTableRow key={user.id}>
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="left">
                    {user.role}
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      onClick={() => openModal({
                        type: EDIT_USER_MODAL,
                        data: user,
                      })}
                    >
                      <EditIcon className={classes.editIcon} fontSize="small" />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 57 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
        />
      </TableContainer>
    </div>
  );
};

const mapStateToProps = ({ users }) => ({ users });
const mapDispatchToProps = createDispatchBindings({ getUsers, updateUser, openModal });

export default connect(mapStateToProps, mapDispatchToProps)(UserControls);
