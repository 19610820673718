import qs from 'querystring';

export const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  let hours = `${d.getHours()}`;
  let minutes = `${d.getMinutes()}`;

  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return `${[year, month, day].join('-')}T${hours}:${minutes}`;
};

export const formatPlotDate = (dateString) => {
  const [date, time] = dateString.split('T');
  const formattedDate = date.substring(5).split('-').join('/');
  const formattedTime = time.substring(0, 5);
  return `${formattedDate} ${formattedTime}`;
};

export const parseStateIntoParams = (mapLayers, dateRange = { startActive: false, endActive: false }) => {
  const paramsObject = {};
  Object.entries(mapLayers).forEach(([layerName, value]) => {
    if (layerName === 'gaugeIconType') paramsObject.gaugeIconType = value;
    else {
      paramsObject[layerName] = value.visible;
      paramsObject[`${layerName}Opacity`] = value.opacity;
    }
  });
  if (dateRange.startActive) paramsObject.start = dateRange.start;
  if (dateRange.endActive) paramsObject.end = dateRange.end;
  return qs.stringify(paramsObject);
};

export const parseParamsIntoStateSettings = (paramsObject) => {
  const stateSettings = {
    inundationLayer: {
      visible: paramsObject.inundationLayer === 'true',
      opacity: paramsObject.inundationLayerOpacity,
      color: '#005CE6',
    },
    gaugeIcons: {
      visible: paramsObject.gaugeIcons === 'true',
      opacity: paramsObject.gaugeIconsOpacity,
      color: '#3387ff',
    },
    AGOChannels: {
      visible: paramsObject.AGOChannels === 'true',
      opacity: paramsObject.AGOChannelsOpacity,
      color: '#00EAFF',
    },
    AGOAddicksBarkerFedLands: {
      visible: paramsObject.AGOAddicksBarkerFedLands === 'true',
      opacity: paramsObject.AGOAddicksBarkerFedLandsOpacity,
      color: '#000000',
    },
    AGOCatchments: {
      visible: paramsObject.AGOCatchments === 'true',
      opacity: paramsObject.AGOCatchmentsOpacity,
      color: '#F88410',
    },
    AGOWatersheds: {
      visible: paramsObject.AGOWatersheds === 'true',
      opacity: paramsObject.AGOWatershedsOpacity,
      color: '#38A800',
    },
    gaugeIconType: paramsObject.gaugeIconType,
  };
  return stateSettings;
};
