// action type constants
export const SET_WATERSHED_OPTIONS = 'SET_WATERSHED_OPTIONS';
export const SET_WATERSHED_VALUE = 'SET_WATERSHED_VALUE';

// actionCreators
export const setWatershedOptions = (options) => ({ type: SET_WATERSHED_OPTIONS, options });
export const setWatershedValue = (value) => ({ type: SET_WATERSHED_VALUE, value });

const initialState = {
  options: [],
  value: '',
};

// reducer
export default (state = initialState, { type, options, value }) => {
  switch (type) {
    case SET_WATERSHED_OPTIONS:
      state.options = options;
      return state;
    case SET_WATERSHED_VALUE:
      state.value = value;
      return state;
    default:
      return state;
  }
};
