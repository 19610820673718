import { takeLatest, put } from 'redux-saga/effects';
import { setError } from '../reducers/errors';
import { SET_GAUGE_FILTER, setGaugeFilterState } from '../reducers/gaugeFilters';
import { updateGaugeIcons } from '../utils/map/gaugeMarkers';

import loading from '../utils/loading';

function* updateGaugeFilters({ key, value }) {
  const loadingMsg = 'Setting Gauge Filters...';
  loading.start(loadingMsg);
  try {
    console.log({ key, value });
    yield put(setGaugeFilterState({ key, value }));
    updateGaugeIcons();
  } catch (e) {
    console.log('ERROR setting gauge filters', e);
    yield put(setError({ errorId: 'setGaugeFiltersError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(SET_GAUGE_FILTER, updateGaugeFilters);
}
