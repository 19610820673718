import L from 'leaflet';

import { takeLatest, put, select } from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { GET_TIMES, setTimes, getLayer } from '../../reducers/radar-ogc';
import { getMap } from '../../reducers/mapRefs';
import loading from '../../utils/loading';

function* fetchTime({ payload: isUpdate }) {
  const map = yield select(getMap);
  const timeDimension = map?.timeDimension;
  if (!timeDimension) {
    return;
  }
  if (!isUpdate) {
    const currentTimes = timeDimension.getAvailableTimes();
    if (currentTimes?.length) {
      yield put(setTimes(currentTimes));
      return;
    }
  }
  const tProm = yield new Promise((yes) => {
    timeDimension.once('availabletimeschanged', ({ availableTimes }) => {
      yes(availableTimes);
    });
  });
  if (isUpdate) {
    const layer = yield select(getLayer);
    if (layer) {
      layer._capabilitiesRequested = false;
      layer._requestTimeDimensionFromCapabilities();
    }
  }
  const times = yield tProm;
  yield put(setTimes(times));
}

export default function* watch() {
  yield takeLatest(GET_TIMES, fetchTime);
}
