import { takeLatest, select } from 'redux-saga/effects';

import { getForecastIconData } from '../reducers/forecastIconData';
import { createFlowStatusLayer } from '../utils/map/layers';
import { getFilteredGauges } from '../utils/map/flowStatusMarkers';
import parseForecastsForSummaryTable from '../utils/parseForecastsForSummaryTable';
import { MAYBE_RUN_ICON_STUFF, getGauges } from '../reducers/gauges';
import reduxStore from '../store';

function* iconStuff() {
  const gaugeGeoJSON = yield select(getGauges);
  const forecast = yield select(getForecastIconData);
  if (!gaugeGeoJSON || !gaugeGeoJSON.features || !gaugeGeoJSON.features.length || !forecast || !forecast.length) {
    return;
  }
  const { gaugeFilters, mapLayers, watershed } = yield reduxStore.getState();
  const filteredGaugeGeojson = yield getFilteredGauges(gaugeGeoJSON, gaugeFilters, { watershed: watershed.value, type: mapLayers.gaugeIconType });
  createFlowStatusLayer(filteredGaugeGeojson);
  yield parseForecastsForSummaryTable(forecast);
}
export default function* watch() {
  yield takeLatest(MAYBE_RUN_ICON_STUFF, iconStuff);
}
