import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const MessageModal = ({ data, handleClose }) => (
  <>
    <DialogTitle id="responsive-dialog-title">{data.title}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {data.msg}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" autoFocus onClick={handleClose} color="primary">
        close
      </Button>
    </DialogActions>
  </>
);

export default MessageModal;
