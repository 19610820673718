// action type constants
export const FETCH_GAUGE_FORECAST = 'FETCH_GAUGE_FORECAST';
export const SET_FORECAST = 'SET_FORECAST';

// actionCreators
export const fetchGaugeForecast = ({ forecast_id, gauge_id }) => ({ type: FETCH_GAUGE_FORECAST, forecast_id, gauge_id });
export const setGaugeForecast = (data) => ({ type: SET_FORECAST, data });

const initialState = false;

// reducer
export default (state = initialState, { type, data }) => {
  switch (type) {
    case SET_FORECAST:
      return data;
    default:
      return state;
  }
};
