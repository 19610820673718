// action type constants
export const GET_GAUGE_DESCRIPTION = 'GET_GAUGE_DESCRIPTION';
export const SET_GAUGE_DESCRIPTION = 'SET_GAUGE_DESCRIPTION';

// actionCreators
export const getGaugeDescription = (gauge_id) => ({ type: GET_GAUGE_DESCRIPTION, gauge_id });
export const setGaugeDescription = (payload) => ({ type: SET_GAUGE_DESCRIPTION, payload });

const initialState = '';

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GAUGE_DESCRIPTION:
      state = payload;
      return state;
    default:
      return state;
  }
};
