/* eslint-disable no-unused-expressions */
import React from 'react';
import { connect } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';

import Show from '../HOC/Show';
import GuageIconToggle from './GuageIconToggle';

import { createDispatchBindings } from '../../utils/redux';
import { addLayer, removeLayer, layers, createFlowStatusLayer, createRainfallStatusLayer } from '../../utils/map/layers';
import { updateLayerValue } from '../../reducers/mapLayers';

const useStyles = makeStyles({
  toggleSectionWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 10px 0px 20px',
  },
  toggleTitle: {
    margin: 0,
  },
  toggleButton: {
    height: 26,
    width: 26,
    padding: 5,
  },
  toggleButtonGroup: {
    margin: 5,
  },
  rightAlign: {
    display: 'flex',
    alignItems: 'center',
  },
});

const LayerToggle = ({ title, layerName, mapLayers, updateLayerValue, gauges }) => {
  const classes = useStyles();
  const { color } = mapLayers[layerName];

  const ColoredSwitch = withStyles({
    switchBase: {
      color,
      '&$checked': {
        color,
      },
      '&$checked + $track': {
        backgroundColor: color,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleLayerToggle = (layerName, value) => {
    if (layerName === 'gaugeIcons') {
      const { gaugeIconType } = mapLayers;
      if (gaugeIconType === 'flow') {
        layers.flowIcons ? addLayer(layerName) : gauges && createFlowStatusLayer(gauges);
      }
      if (gaugeIconType === 'rain') {
        layers.rainIcons ? addLayer(layerName) : createRainfallStatusLayer(gauges);
      }
    }
    value
      ? addLayer(layerName)
      : removeLayer(layerName);
    updateLayerValue({ layerName, key: 'visible', value });
  };

  return (
    <div className={classes.toggleSectionWrapper}>
      <p className={classes.toggleTitle}>{title}</p>
      <div className={classes.rightAlign}>
        <Show test={layerName === 'gaugeIcons'}>
          <GuageIconToggle />
        </Show>
        <Tooltip title="Toggle Layer On/Off">
          <ColoredSwitch
            checked={mapLayers[layerName].visible}
            onChange={(e) => handleLayerToggle(layerName, e.target.checked)}
          />
        </Tooltip>
      </div>
    </div>
  );
};

const mapStateToProps = ({ gauges }) => ({ gauges });
const mapDispatchToProps = createDispatchBindings({ updateLayerValue });

export default connect(mapStateToProps, mapDispatchToProps)(LayerToggle);
