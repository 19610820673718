// action type constants
export const OPEN_NOTIFICATION_DRAWER = 'OPEN_NOTIFICATION_DRAWER';
export const CLOSE_NOTIFICATION_DRAWER = 'CLOSE_NOTIFICATION_DRAWER';

// actionCreators
export const openNotificationDrawer = () => ({ type: OPEN_NOTIFICATION_DRAWER });
export const closeNotificationDrawer = () => ({ type: CLOSE_NOTIFICATION_DRAWER });

const initialState = {
  open: false,
};

// reducer
export default (state = initialState, { type }) => {
  switch (type) {
    case OPEN_NOTIFICATION_DRAWER:
      return { ...state, open: true };
    case CLOSE_NOTIFICATION_DRAWER:
      return initialState;
    default:
      return state;
  }
};
