/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import NotificationActionButtons from './NotificationActionButtons';
import NotificationStatus from './NotificationStatus';

const useStyles = makeStyles({
  table: {
    maxWidth: '100%',
  },
  notificationStatus: {
    height: 20,
    width: 20,
    padding: 0,
    margin: 0,
  },
  forecastSpacer: {
    padding: 5,
    margin: '0 10px',
    fontWeight: 'bold',
  },
});

const HeaderCell = withStyles({
  root: {
    fontWeight: 'bold',
    padding: 0,
  },
})(TableCell);

const StatusCell = withStyles({
  root: {
    padding: 0,
    paddingLeft: 10,
  },
})(TableCell);

const NotificationsTable = ({ data, notification_type }) => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (data?.length) setNotifications(data);
  }, [data]);

  const sortNotifications = (column) => {
    if (notification_type === 'personalNotifications') return;
    const sortedNotifications = [...notifications];
    sortedNotifications.sort((a, b) => {
      switch (column) {
        case 'gauge_id':
          return b.gauge_id - a.gauge_id;
        case 'stage':
          return b.stage - a.stage;
        case 'forecast_current':
          return b.forecast_current - a.forecast_current;
        case 'created_at': {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        }
        default:
          break;
      }
    });
    setNotifications(sortedNotifications);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StatusCell />
            <HeaderCell align="center">
              <Button onClick={() => sortNotifications('gauge_id')}>
                Gauge Id
              </Button>
            </HeaderCell>
            <HeaderCell align="center">
              <Button onClick={() => sortNotifications('stage')}>
                Stage
              </Button>
            </HeaderCell>
            <HeaderCell align="center">
              <Button onClick={() => sortNotifications('forecast_current')}>
                Stage (Forecast)
              </Button>
            </HeaderCell>
            <HeaderCell align="center">
              <Button onClick={() => sortNotifications('created_at')}>
                Local Time
              </Button>
            </HeaderCell>
            <HeaderCell align="center">
              Zoom To
            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map((notification) => (
            <TableRow key={notification.gauge_id}>
              <NotificationStatus threshold={notification.threshold} change={notification.display_change} />
              <TableCell align="center">{notification.gauge_id}</TableCell>
              <TableCell align="center">
                {/* STAGE NOTIFICATION */}
                {(notification.current_value || !notification.stage) && (
                  Number(notification.current_value) > Number(notification.forecast_current)
                    ? <strong>{Number(notification.current_value).toFixed(2)}</strong>
                    : Number(notification.current_value).toFixed(2)
                )}
                {(notification.stage && !notification.current_value) && (
                  Number(notification.stage) > Number(notification.forecast_current)
                    ? <strong>{Number(notification.stage).toFixed(2)}</strong>
                    : Number(notification.stage).toFixed(2)
                )}
              </TableCell>
              <TableCell align="center">
                {/* FORECAST NOTIFICATION */}
                {Number(notification.forecast_current) > Number(notification.current_value)
                  ? <strong>{Number(notification.forecast_current).toFixed(2)}</strong>
                  : (notification.forecast_current
                    ? Number(notification.forecast_current)
                    : '-')}
              </TableCell>
              <TableCell align="center">{moment(new Date(notification.created_at)).format('MM/DD/YY HH:mm')}</TableCell>
              <TableCell align="center">
                <NotificationActionButtons notification={notification} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};


export default NotificationsTable;
