// action type constants
export const SET_SIDEBAR = 'SET_SIDEBAR';

// actionCreators
export const setSidebar = (open) => ({ type: SET_SIDEBAR, open });

const initialState = false;

// reducer
export default (state = initialState, { type, open }) => {
  switch (type) {
    case SET_SIDEBAR:
      return open;
    default:
      return state;
  }
};
