import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Show from './HOC/Show';
import MrmsKey from './MapPage/MrmsKey';
import { createDispatchBindings } from '../utils/redux';
import { setupMapServices } from '../reducers/mapRefs';
import { getMap } from '../utils/map/leaflet';
import { createInundationLayer, layers } from '../utils/map/layers';
import { setActiveMarker } from '../utils/map/gaugeMarkers';
import history from '../history';
import { gaugeStatusColors, rainfallIconColorsKey } from '../config/constants';

const useStyles = makeStyles({
  mapWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  iconKey: {
    position: 'absolute',
    right: 55,
    top: 10,
    zIndex: 999,
    backgroundColor: '#efefef',
    display: 'flex',
    padding: 2,
    borderRadius: 5,
    border: '1px solid #999',
  },
  colorBlock: {
    padding: '5px 10px',
    margin: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stageColorBlock: {
    height: 30,
    width: 100,
    borderRadius: 5,
    padding: '5px 10px',
    margin: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grey: {
    background: gaugeStatusColors.normal,
  },
  orange: {
    background: gaugeStatusColors.nearFlooding,
  },
  red: {
    background: gaugeStatusColors.flooding,
  },
  black: {
    background: gaugeStatusColors.noData,
    color: 'black',
  },
});

const LeafletMap = ({ mapDiv, setupMapServices, mapHeight, gauges, mapLayers, mrmsLayer }) => {
  const classes = useStyles({ mapHeight });
  const { gaugeId } = useParams();
  const currentPage = history.location.pathname.split('/')[1];

  useEffect(() => {
    if (!layers.inundationLayer) createInundationLayer();
  }, []);

  useEffect(() => {
    if (Number(gaugeId) && gauges) {
      setActiveMarker(gaugeId);
    }
  }, [gaugeId, gauges, currentPage]);

  useEffect(() => {
    if (!mapDiv) setupMapServices();
    else {
      mapDiv.style.display = 'block';
      document.getElementById('map-wrapper').appendChild(mapDiv);
      getMap().invalidateSize();
    }
    return () => {
      if (mapDiv?.style?.display) mapDiv.style.display = 'none';
    };
  }, [mapDiv, setupMapServices]);

  return (
    <div id="map-wrapper" className={classes.mapWrapper}>
      <Show test={mapLayers.gaugeIcons.visible}>
        <Show test={mapLayers.gaugeIconType === 'rain'}>
          <div className={classes.iconKey}>
            {Object.entries(rainfallIconColorsKey).map(([key, value]) => (
              <div key={key} style={{ backgroundColor: value, color: value === 'black' ? 'white' : 'black' }} className={classes.colorBlock}>
                {key}
              </div>
            ))}
          </div>
        </Show>
        <Show test={mapLayers.gaugeIconType === 'flow'}>
          <div className={classes.iconKey}>
            <div className={`${classes.stageColorBlock} ${classes.black}`}>
              no data
            </div>
            <div className={`${classes.stageColorBlock} ${classes.grey}`}>
              normal
            </div>
            <div className={`${classes.stageColorBlock} ${classes.orange}`}>
              action
            </div>
            <div className={`${classes.stageColorBlock} ${classes.red}`}>
              channel top
            </div>
          </div>
        </Show>
      </Show>
      <Show test={mrmsLayer.active}>
        <MrmsKey />
      </Show>
    </div>
  );
};

const mapStateToProps = ({ mapRefs: { mapDiv }, gauges, mapLayers, mrmsLayer }) => ({ mapDiv, gauges, mapLayers, mrmsLayer });
const mapDispatchToProps = createDispatchBindings({ setupMapServices });

export default connect(mapStateToProps, mapDispatchToProps)(LeafletMap);
