import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { layerToggleList } from '../../config/constants';
import LayerToggle from './LayerToggle';
import LayerOpacitySlider from './LayerOpacitySlider';
import RadarOGCControls from './RadarOGCControls';
// import RadarControls from './RadarControls';
import MRMSControls from './MRMSControls';
import Show from '../HOC/Show';

const useStyles = makeStyles({
  layerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  layerSectionTitle: {
    margin: '10px 0 5px 10px',
    fontWeight: 'bold',
    textAlign: 'left',
  },
});

const LayerSettings = ({ mapLayers }) => {
  const classes = useStyles();

  return (
    <>
      <p className={classes.layerSectionTitle}>Layers:</p>
      {/* <RadarControls /> */}
      <RadarOGCControls />
      <Divider />
      <MRMSControls />
      <Divider />
      {layerToggleList.map((toggle) => (
        <React.Fragment key={toggle.title}>
          <div className={classes.layerWrapper}>
            <LayerToggle title={toggle.title} layerName={toggle.layerName} mapLayers={mapLayers} />
            <Show test={toggle.layerName !== 'gaugeIcons'}>
              <LayerOpacitySlider layerName={toggle.layerName} mapLayers={mapLayers} />
            </Show>
          </div>
          <Divider />
        </React.Fragment>
      ))}
    </>
  );
};

const mapStateToProps = ({ mapLayers }) => ({ mapLayers });

export default connect(mapStateToProps)(LayerSettings);
