// action type constants
export const FETCH_GAUGE_DATA = 'FETCH_GAUGE_DATA';
export const SET_GAUGE_DATA = 'SET_GAUGE_DATA';

// actionCreators
export const fetchGaugeData = (site_id) => ({
  type: FETCH_GAUGE_DATA,
  site_id,
});

export const setGaugeData = (gaugeData) => ({
  type: SET_GAUGE_DATA,
  gaugeData,
});

const initialState = {
  10: false, // Rain Increment
  11: false, // Rain Accumulation
  20: false, // Stage
  25: false, // Flow Volume
};

// reducer
export default (state = initialState, { type, gaugeData }) => {
  switch (type) {
    case SET_GAUGE_DATA: {
      return gaugeData;
    }
    default:
      return state;
  }
};
