import L from 'leaflet';

import { takeLatest, put } from 'redux-saga/effects';
import { CHANGE_SELECTED, updateSelected, addToMap, rmFromMap, getTimes } from '../../reducers/radar-ogc';

function* updateLayer({ payload: newLayer }) {
  yield put(rmFromMap());
  yield put(updateSelected(newLayer));
  yield put(addToMap());
  yield put(getTimes());
}

export default function* watch() {
  yield takeLatest(CHANGE_SELECTED, updateLayer);
}
