// loading messages stack
const msgs = ['Loading Application...'];

// utility function to display/hide loading overlay with custom messages
export default {
  start: (loadingMsg) => {
    msgs.push(loadingMsg);
    document.querySelector('#loading-text h1').textContent = `${loadingMsg}...`;
    document.querySelector('#loading-text h1').style.zIndex = '99999';
    document.getElementById('loading-overlay').classList.add('loading');
    document.getElementById('loading-overlay').style.zIndex = '99999';
  },
  end: (loadingMsg) => {
    const index = msgs.indexOf(loadingMsg);
    if (index > -1) msgs.splice(index, 1);
    const { length } = msgs;
    if (!length) document.getElementById('loading-overlay').classList.remove('loading');
    else document.querySelector('#loading-text h1').textContent = msgs[length - 1];
  },
};
