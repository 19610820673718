import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { defaultZoomLevel } from '../../config/constants';

require('../inundation');

let map;
let basemapLayer;

export const setBasemap = (basemap) => {
  if (basemapLayer) {
    map.removeLayer(basemapLayer);
  }
  switch (basemap) {
    case 'Imagery': {
      basemap = L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png', // Imagery
        { maxZoom: 19 },
      );
      basemap.addTo(map);
      L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}.png', // Imagery Labels
        { maxZoom: 19 },
      ).addTo(map);
      return;
    }
    case 'Streets': {
      basemap = L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}.png', // Streets
        { maxZoom: 19 },
      );
      break;
    }
    case 'Topographic': {
      basemap = L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.png', // Topographic
        { maxZoom: 19 },
      );
      break;
    }
    case 'National Geographic': {
      basemap = L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}.png', // National Geographic
        { maxZoom: 19 },
      );
      break;
    }
    default: {
      basemap = L.tileLayer(
        'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
        {
          maxZoom: 19,
          attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        },
      );
      break;
    }
  }
  basemap.addTo(map);
};

export const createMap = async () => {
  map = new L.Map('map_canvas', {
    center: [defaultZoomLevel.lat, defaultZoomLevel.lng],
    zoom: 10,
    zoomControl: false,
    timeDimension: true,
  });

  L.control.zoom({
    position: 'topright',
  }).addTo(map);

  setBasemap('Streets');
  window.lmap = map;
  return map;
};

export const getMap = () => map;
