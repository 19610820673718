import { takeLatest, put } from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { FETCH_SUBSCRIPTIONS, setSubscriptions } from '../../reducers/subscriptions';

import loading from '../../utils/loading';

function* fetchSubscriptions() {
  const loadingMsg = 'Fetching Subscription...';
  loading.start(loadingMsg);
  try {
    const response = yield fetch('/api/subscription');
    if (response.status > 299) throw new Error('Error fetching subscription');
    const subscriptions = yield response.json();
    yield put(setSubscriptions(subscriptions));
  } catch (e) {
    console.log('ERROR fetching subscription', e);
    yield put(setError({ errorId: 'fetchSubscriptionsError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(FETCH_SUBSCRIPTIONS, fetchSubscriptions);
}
