// action type constants
export const SET_ERROR = 'SET_ERROR';

// actionCreators
export const setError = ({ errorId, value }) => ({ type: SET_ERROR, errorId, value });

const initialState = {
  fetchGaugesError: '',
};

// reducer
export default (state = initialState, { type, errorId, value }) => {
  switch (type) {
    case SET_ERROR: {
      state[errorId] = value;
      return state;
    }
    default:
      return state;
  }
};
