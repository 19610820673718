import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { makeStyles } from '@material-ui/core/styles';

import { createDispatchBindings } from '../../utils/redux';
import { fetchGaugeForecast, setGaugeForecast } from '../../reducers/gaugeForecast';
import { fetchForecastIconData } from '../../reducers/forecastIconData';
import {setCurrentForecastId,setCurrentForecastDateTime} from '../../reducers/hiddenForecastModels';
import { setDateRange } from '../../reducers/dateRange';



const useStyles = makeStyles({
  select: {
    width: 'calc(100% - 38px)',
    maxWidth: 300,
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 10,
  },
  selectLabel: {
    border: '1px solid #efefef',
    color: '#efefef',
    padding: '8px 10px',
    paddingRight: '20px',
    marginRight: -5,
    borderRadius: 5,
    height: 38,
    overflowX: 'hidden',
  },
});

const optionStyles = {
  option: (provided) => ({
    ...provided,
    color: 'black',
  }),
};

const noSelection = {
  label: 'No Forecast Selected',
  value: '',
};

const ForecastDropdown = ({ forecastList, fetchGaugeForecast, gauge_id, setGaugeForecast, fetchForecastIconData,setCurrentForecastId,setDateRange,setCurrentForecastDateTime }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [selectedForecastId, setSelectedForecastId] = useState(false);
  const [oldForecastList, setOldForecastList] = useState(0);

  useEffect(() => {
    // Fetches forecast graph data for selected gauge
    if (gauge_id && (selectedForecastId.value || selectedForecastId.value === '')) fetchGaugeForecast({ forecast_id: selectedForecastId.value, gauge_id });
  }, [gauge_id, selectedForecastId]);

  useEffect(() => {
    if (forecastList.length) {
      const formattedOptions = [];
      for (const forecast of forecastList) {
        if (forecast.run_time) {
          const forecastTimestampArray = forecast.run_time.split('T');
          formattedOptions.push({
            label: `${forecastTimestampArray[0]} ${forecastTimestampArray[1].substr(0, 5)} UTC (ID ${forecast.forecast_id})`,
            value: forecast.forecast_id,
            run_time: forecast.run_time,
          });
        }
      }
      setOptions([noSelection, ...formattedOptions]);
      if (forecastList.length > oldForecastList.length) {
        setSelectedForecastId(formattedOptions[0]);
        setCurrentForecastId({value: formattedOptions[0].value});            
        setCurrentForecastDateTime({value: formattedOptions[0].run_time});            
      }
      setOldForecastList(forecastList);
    } else {
      setOptions([]);
      setGaugeForecast(false);
    }
  }, [forecastList]);
  useEffect(() => {
    // ! Where forecast icon data is being fetched when forecast selection is changed
    // Might have an issue here with conditional. Might want to find a way to fetch real-time if no forecastId.value is passed instead of blocking the request
    if (selectedForecastId?.value && selectedForecastId.value !== '0') fetchForecastIconData(selectedForecastId.value);
  }, [selectedForecastId.value]);
  useEffect(() => {
    if (options[1] && options[1].value) {
      const mostRecentDate = new Date(options[1].run_time).getTime();
      const date = new Date();
      const oneDayAgo = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()) - (1 * 24 * 60 * 60 * 1000);
      if (mostRecentDate >= oneDayAgo) {
        setSelectedForecastId(options[1]);
        setCurrentForecastId({value: options[1].value});    
        setCurrentForecastDateTime({value: options[1].run_time});         
      } 
    }
  }, [options[1] && options[1].value]);
  const handleForecastSelection = ({ value, label }) => {    
    // store.dispatch(setDateRange({key:"startActive",value: false}));
    // store.dispatch(setDateRange({key:"endActive",value: false}));
    setDateRange({key:"startActive",value: false});
    setDateRange({key:"endActive",value: false});
    fetchForecastIconData(value);
    setSelectedForecastId({ value, label });
    setCurrentForecastId({value: value}); 
    setCurrentForecastDateTime({value: findOptionByForecastId(value)});           
    // Fetches forecast graph data for selected gauge
    if (gauge_id) fetchGaugeForecast({ forecast_id: value, gauge_id });
  };

  const findOptionByForecastId = (value) => 
  {
    if (options != null && options.length > 0)
    { 
       var currentOption= options.find(option => option.value === value);
       if (currentOption && currentOption.run_time)       
            return currentOption.run_time;        
    }   

  };

  if (options.length && (selectedForecastId.value || selectedForecastId.value === '')) {
    return (
      <div className={classes.selectWrapper}>
        <h4 className={classes.selectLabel}>Source</h4>
        <Select
          name="Forecast"
          onChange={handleForecastSelection}
          className={classes.select}
          value={selectedForecastId}
          options={options}
          styles={optionStyles}
        />
      </div>
    );
  }
  return (
    <h3>No Forecasts Available</h3>
  );
};

const mapStateToProps = ({ forecastList }) => ({ forecastList });
const mapDispatchToProps = createDispatchBindings({ fetchGaugeForecast, setGaugeForecast, fetchForecastIconData, setCurrentForecastId,setDateRange ,setCurrentForecastDateTime});

export default connect(mapStateToProps, mapDispatchToProps)(ForecastDropdown);
