// action type constants
export const FETCH_GAUGE_SENSORS = 'FETCH_GAUGE_SENSORS';
export const SET_GAUGE_SENSORS = 'SET_GAUGE_SENSORS';

// actionCreators
export const setGaugeSensors = ({ gaugeSensors }) => ({
  type: SET_GAUGE_SENSORS,
  gaugeSensors,
});

const initialState = [];

// reducer
export default (state = initialState, { type, gaugeSensors }) => {
  switch (type) {
    case SET_GAUGE_SENSORS: {
      return gaugeSensors;
    }
    default:
      return state;
  }
};
