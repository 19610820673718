import React from 'react';
import { connect } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import RoomIcon from '@material-ui/icons/Room';

import history from '../../history';
import { createDispatchBindings } from '../../utils/redux';
import { closeNotificationDrawer } from '../../reducers/notificationDrawer';
import { markNotificationAsRead } from '../../reducers/notifications';

const NotificationActionButtons = ({ notification, markNotificationAsRead, closeNotificationDrawer }) => {
  const handleNavToGauge = (gauge_id) => {
    const currentPage = history.location.pathname.split('/')[1];
    history.push({
      pathname: `/${currentPage}/${gauge_id}`,
      search: history.location.search,
    });
    closeNotificationDrawer();
  };

  return (
    <>
      <Tooltip title="Show on Map">
        <IconButton onClick={() => handleNavToGauge(notification.gauge_id)} size="small" style={{ marginRight: 4 }}>
          <RoomIcon style={{ color: '#007BC1' }} fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Remove Notification">
        <IconButton onClick={() => markNotificationAsRead({ ...notification })} size="small">
          <CloseIcon style={{ color: 'red' }} fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  );
};

const mapDispatchToProps = createDispatchBindings({ closeNotificationDrawer, markNotificationAsRead });

export default connect(null, mapDispatchToProps)(NotificationActionButtons);
