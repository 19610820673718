import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import { createDispatchBindings } from '../../utils/redux';
import { setDateRange } from '../../reducers/dateRange';
import { convertToLocal, convertToUTC } from '../../utils/convertTime';

const useStyles = makeStyles({
  dateInput: {
    padding: 5,
    margin: '0px 5px',
    height: 24,
  },
  dateInputTitle: {
    margin: '0 10px',
    padding: 0,
    fontSize: 12,
  },
  dateInputWrapper: {
    marginTop: -10,
  },
});

const DateInput = ({ dateRange, title, id, setDateRange }) => {
  const classes = useStyles();
  const [minMaxDate, setMinMaxDate] = useState({
    min: moment().subtract(3, 'days').format(),
    max: moment().format(),
  });

  useEffect(() => {
    if (dateRange.start) {
      if (id === 'start') {
        setMinMaxDate({
          min: undefined,
          max: convertToLocal(dateRange.end),
        });
      } else if (id === 'end') {
        setMinMaxDate({
          min: convertToLocal(dateRange.start),
          max: undefined,
        });
      }
    }
  }, [dateRange]);

  return (
    <>
      <Checkbox
        checked={dateRange[`${id}Active`]}
        onChange={(e) => setDateRange({ key: `${id}Active`, value: e.target.checked })}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <div className={classes.dateInputWrapper}>
        <p className={classes.dateInputTitle}>{title} & Time CST/CDT</p>
        <input
          type="datetime-local"
          min={minMaxDate.min}
          max={minMaxDate.max}
          disabled={!dateRange[`${id}Active`]}
          defaultValue={moment(convertToLocal(dateRange[id])).format('YYYY-MM-DDTHH:mm')}
          onChange={(e) => setDateRange({ key: id, value: convertToUTC(e.target.value) })}
          className={classes.dateInput}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ dateRange }) => ({ dateRange });
const mapDispatchToProps = createDispatchBindings({ setDateRange });

export default connect(mapStateToProps, mapDispatchToProps)(DateInput);
