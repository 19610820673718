import fuzzyFilterForecasts from '../utils/fuzzySearch/fuzzySearchForecasts';

// action type constants
export const GET_ADMIN_FORECASTS = 'GET_ADMIN_FORECASTS';
export const SET_ADMIN_FORECASTS = 'SET_ADMIN_FORECASTS';
export const DELETE_FORECAST = 'DELETE_FORECAST';
export const REMOVE_FORECAST_FROM_LIST = 'REMOVE_FORECAST_FROM_LIST';
export const FUZZY_SEARCH_FORECASTS = 'FUZZY_SEARCH_FORECASTS';

// actionCreators
export const getAdminForecasts = () => ({ type: GET_ADMIN_FORECASTS });
export const fuzzySearchForecasts = (payload) => ({ type: FUZZY_SEARCH_FORECASTS, payload });
export const setAdminForecasts = (payload) => ({ type: SET_ADMIN_FORECASTS, payload });
export const deleteForecast = ({ forecast_id, resync }) => ({ type: DELETE_FORECAST, forecast_id, resync });
export const removeForecastFromList = (payload) => ({ type: REMOVE_FORECAST_FROM_LIST, payload });

export const selectAdminForecasts = ({ adminForecasts: forecastList }) => forecastList;

const initialState = {
  forecastList: [],
  forecastFuzzySearchResults: [],
};

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ADMIN_FORECASTS:
      state.forecastList = payload;
      state.forecastFuzzySearchResults = payload;
      return state;
    case REMOVE_FORECAST_FROM_LIST: {
      let indexToRemove;
      state.forecastList.find(({ forecast_id, index }) => {
        if (forecast_id === payload) {
          indexToRemove = index;
          return true;
        }
      });
      state.forecastList.splice(indexToRemove, 1);
      state.forecastFuzzySearchResults.find(({ forecast_id, index }) => {
        if (forecast_id === payload) {
          indexToRemove = index;
          return true;
        }
      });
      state.forecastFuzzySearchResults.splice(indexToRemove, 1);
      return state;
    }
    case FUZZY_SEARCH_FORECASTS: {
      if (!payload?.length) {
        state.forecastFuzzySearchResults = state.forecastList;
        return state;
      }
      const forecasts = JSON.parse(JSON.stringify(state.forecastList));
      const results = fuzzyFilterForecasts({ forecasts, searchValue: payload });
      state.forecastFuzzySearchResults = results;
      return state;
    }
    default:
      return state;
  }
};
