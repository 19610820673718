import { DateTime } from 'luxon';

const checkTime = async (code) => {
  const resp = await fetch(`https://inundation.hcfcd.org/api/Layer/Exists/${code}`);
  if (resp.status > 299) {
    throw new Error('invalid response');
  }
  const value = await resp.json();
  // console.log(code, value);
  return value;
};
const getCurTime = (time, local) => {
  let dt;
  if (time) {
    dt = DateTime.fromObject(time);
    if (local) {
      dt = dt.setZone('America/Chicago');
    }
  } else {
    dt = DateTime.local().setZone('America/Chicago');
  }
  if (dt.minute < 30) {
    dt = dt.set({
      minute: 0,
    });
  } else if (dt.minute > 30) {
    dt = dt.set({
      minute: 30,
    });
  }
  return dt;
};

export default async (time) => {
  let curTime = getCurTime(time);
  let timeCode;
  let i = 0;
  while (i < 3) {
    i += 1;
    const code = curTime.toFormat('yyyyLLddHHmm');
    if (await checkTime(code)) {
      timeCode = code;
      break;
    }
    curTime = curTime.minus({
      minutes: 30,
    });
  }
  return timeCode;
};
