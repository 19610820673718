import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import rootSaga from './sagas';

const isDev = true; //process.env.REACT_APP_DEV;

// use Redux DevTools compose if in a development environment
const composeEnhancers = (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) : compose;

// create Redux Saga middleware
const sagaMiddleware = createSagaMiddleware();

// create Redux store and apply middleware
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

// make sagas available to Redux Saga middleware
sagaMiddleware.run(rootSaga);

export default store;
