import { take, put, fork, cancel, cancelled, delay, select } from 'redux-saga/effects';
import { START_ADVANCING, STOP_ADVANCING, advTime, getDelay } from '../../reducers/radar-ogc';

function* bgSync() {
  try {
    while (true) {
      yield put(advTime());
      const waitTime = yield select(getDelay);
      yield delay(waitTime * 1000);
    }
  } finally {
    if (yield cancelled()) {
      console.log('map animation canceled');
    }
  }
}

export default function* main() {
  while (yield take(START_ADVANCING)) {
    // starts the task in the background
    const bgSyncTask = yield fork(bgSync);

    // wait for the user stop action
    yield take(STOP_ADVANCING);
    // user clicked stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
    yield cancel(bgSyncTask);
  }
}
