import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

import history from '../../../history';
import { createDispatchBindings } from '../../../utils/redux';
import { setSelectedRainfallSource, fetchRainIncrement } from '../../../reducers/rainIncrement';

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 215,
  },
  select: {
    height: 20,
    marginTop: 2,
    fontSize: 12,
    padding: 0,
  },
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const RainfallSourceDropdown = ({ rainIncrement, dateRange, fetchRainIncrement, setSelectedRainfallSource }) => {
  const classes = useStyles();
  const gauge_id = history.location.pathname.split('/')[2];

  useEffect(() => {
    const { start, end } = dateRange;
    fetchRainIncrement({ gauge_id, start, end, selected: rainIncrement.selected });
  }, [rainIncrement.selected]);

  return (
    <div className={classes.wrapper}>
      <Tooltip placement="left" title="Rainfall Source">
        <Select
          value={rainIncrement.selected}
          onChange={(e) => setSelectedRainfallSource(e.target.value)}
          variant="outlined"
          className={classes.select}
          classes={{
            root: classes.root,
          }}
        >
          <MenuItem value="forecast">Forecast</MenuItem>
          <MenuItem value="contrail">Contrail</MenuItem>
          <MenuItem value="mrms">MRMS</MenuItem>
        </Select>
      </Tooltip>
    </div>
  );
};

const mapStateToProps = ({ rainIncrement, dateRange }) => ({ rainIncrement, dateRange });
const mapDispatchToProps = createDispatchBindings({ fetchRainIncrement, setSelectedRainfallSource });

export default connect(mapStateToProps, mapDispatchToProps)(RainfallSourceDropdown);
