/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import SortIcon from '@material-ui/icons/Sort';
import Tooltip from '@material-ui/core/Tooltip';

import { createDispatchBindings } from '../../utils/redux';
import { getAdminGauges, fuzzySearchGauges, toggleGaugeDisable } from '../../reducers/adminGauges';
import DescriptionControls from './DescriptionControls';

const useStyles = makeStyles({
  gaugeControlsWrapper: {
    width: '100%',
    height: '100%',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  roleDropdown: {
    minWidth: 200,
  },
  deleteIcon: {
    color: 'red',
  },
});

const StyledTableRow = withStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#efefef',
    },
  },
})(TableRow);

const HeaderCell = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(TableCell);

const TableWrapper = withStyles({
  root: {
    marginTop: 20,
  },
})(Paper);

const GaugeControls = ({ adminGauges, getAdminGauges, fuzzySearchGauges, toggleGaugeDisable }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [tableFilterColumn, setTableFilterColumn] = useState();
  const [gaugeList, setGaugeList] = useState([]);
  const rowsPerPage = 10;

  useEffect(() => {
    const { gaugeFuzzySearchResults } = adminGauges;
    const sortedGauges = [...gaugeFuzzySearchResults];
    if (tableFilterColumn === 'disable_calc') {
      sortedGauges.sort((a, b) => ((a.disable_calc === b.disable_calc) ? 0 : a.disable_calc ? -1 : 1));
    } else if (tableFilterColumn === 'disable_all') {
      sortedGauges.sort((a, b) => ((a.disable_all === b.disable_all) ? 0 : a.disable_all ? -1 : 1));
    }
    setGaugeList(sortedGauges);
  }, [adminGauges, tableFilterColumn]);


  useEffect(() => {
    getAdminGauges();
  }, []);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const emptyRows = gaugeList?.length ? rowsPerPage - Math.min(rowsPerPage, gaugeList.length - page * rowsPerPage) : 0;

  return (
    <div className={classes.gaugeControlsWrapper}>
      <TextField label="Search Gauges" variant="outlined" onChange={(e) => fuzzySearchGauges(e.target.value)} />
      {gaugeList?.length ? (
        <TableContainer component={TableWrapper}>
          <Table size="small" className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <HeaderCell>Gauge ID</HeaderCell>
                <HeaderCell align="left">Location</HeaderCell>
                <HeaderCell align="left">Description</HeaderCell>
                <HeaderCell align="left">
                  Processing
                  <Tooltip title="sort results">
                    <IconButton onClick={() => setTableFilterColumn('disable_calc')} style={{ marginLeft: '5px' }} aria-label="sort" size="small">
                      <SortIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </HeaderCell>
                <HeaderCell align="left">
                  Master
                  <Tooltip title="sort results">
                    <IconButton onClick={() => setTableFilterColumn('disable_all')} style={{ marginLeft: '5px' }} aria-label="sort" size="small">
                      <SortIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gaugeList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((gauge) => (
                  <StyledTableRow key={gauge.gauge_id + gauge.location}>
                    <TableCell component="th" scope="row">
                      {gauge.gauge_id}
                    </TableCell>
                    <TableCell align="left">
                      {gauge.location}
                    </TableCell>
                    <TableCell align="left">
                      <DescriptionControls gauge={gauge} />
                    </TableCell>
                    <TableCell align="left">
                      <Checkbox
                        checked={gauge.disable_calc}
                        disabled={gauge.disable_calc === null}
                        onChange={(e) => toggleGaugeDisable({ column: 'disable_calc', gauge_id: gauge.gauge_id, bool: e.target.checked })}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Checkbox
                        checked={gauge.disable_all}
                        disabled={gauge.disable_all === null}
                        onChange={(e) => toggleGaugeDisable({ column: 'disable_all', gauge_id: gauge.gauge_id, bool: e.target.checked })}
                      />
                    </TableCell>
                  </StyledTableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 57 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={gaugeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
          />
        </TableContainer>
      ) : <p className={classes.noResultsMessage}>No Search Results Found.</p> }
    </div>
  );
};

const mapStateToProps = ({ adminGauges }) => ({ adminGauges });
const mapDispatchToProps = createDispatchBindings({ getAdminGauges, fuzzySearchGauges, toggleGaugeDisable });

export default connect(mapStateToProps, mapDispatchToProps)(GaugeControls);
