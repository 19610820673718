// action type constants
export const FETCH_FORECAST_LIST = 'FETCH_FORECAST_LIST';
export const SET_FORECAST_LIST = 'SET_FORECAST_LIST';
export const APPEND_FORECAST_LIST = 'APPEND_FORECAST_LIST';
export const PREPEND_FORECAST_LIST = 'PREPEND_FORECAST_LIST';
export const REFRESH_FORECAST_LIST = 'REFRESH_FORECAST_LIST';
// actionCreators
export const fetchForecastList = () => ({ type: FETCH_FORECAST_LIST });
export const setForecastList = (data) => ({ type: SET_FORECAST_LIST, data });
export const appendForecastList = (data) => ({ type: APPEND_FORECAST_LIST, data });
export const refreshForecastList = () => ({ type: REFRESH_FORECAST_LIST });
export const prependForecastList = (data) => ({ type: PREPEND_FORECAST_LIST, data });
export const getLatestForecast = (state) => {
  if (!state.forecastList || !state.forecastList.length) {
    return 0;
  }
  return state.forecastList[0].forecast_id;
};
const initialState = false;

// reducer
export default (state = initialState, { type, data }) => {
  switch (type) {
    case SET_FORECAST_LIST:
      return data;
    case APPEND_FORECAST_LIST:
      if (!state) {
        return data;
      }
      return state.concat(data);
    case PREPEND_FORECAST_LIST:
      if (!state) {
        return data;
      }
      return data.concat(state);
    default:
      return state;
  }
};
