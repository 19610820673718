import L from 'leaflet';
import reduxStore from '../../store';
import { rainfallIconColorsKey } from '../../config/constants';
import { layers } from './layers';

export const unselectedRainMarker = ({ iconColor }) => L.divIcon({
  className: 'gauge-icon',
  iconSize: null,
  html: `
    <div
      class="icon"
      style="
        text-align: center;
        font-weight: bold;
        background: #444;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: ${iconColor};
        border: 1px solid black;"
    />`,
});

export const selectedRainMarker = ({ iconColor }) => L.divIcon({
  className: 'gauge-icon',
  iconSize: null,
  html: `
    <div
      class="icon"
      style="
        text-align: center;
        font-weight: bold;
        background: #444;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: ${iconColor};
        box-shadow: 0px 0px 0px 3px #00a2ff;
        border: 1px solid black;"
    />`,
});

export const getRainMarkerStyling = (site_id) => {
  const { rainfallStatus } = reduxStore.getState();
  if (rainfallStatus?.disable_all) return;
  let currentGaugeRainfallStatus;
  if (rainfallStatus.length) {
    currentGaugeRainfallStatus = rainfallStatus.find((site) => Number(site.site_id) === Number(site_id));
  }
  let iconColor;
  if (currentGaugeRainfallStatus) {
    const currentAccumulation = currentGaugeRainfallStatus.accumulation;
    if (currentAccumulation <= 0) iconColor = rainfallIconColorsKey['0.00”'];
    else if (currentAccumulation < 1) iconColor = rainfallIconColorsKey['< 1"'];
    else if (currentAccumulation < 2) iconColor = rainfallIconColorsKey['1" – 2"'];
    else if (currentAccumulation < 4) iconColor = rainfallIconColorsKey['2” – 4”'];
    else if (currentAccumulation < 6) iconColor = rainfallIconColorsKey['4” – 6”'];
    else if (currentAccumulation < 8) iconColor = rainfallIconColorsKey['6” – 8”'];
    else if (currentAccumulation >= 8) iconColor = rainfallIconColorsKey['8” +'];
    else iconColor = rainfallIconColorsKey['Out of Service'];
  } else {
    const gauge = layers.gauges.features.find((site) => Number(site_id) === Number(site.site_id));
    if (!gauge || gauge.ff_rain !== 'Yes') iconColor = rainfallIconColorsKey['Out of Service'];
    else iconColor = rainfallIconColorsKey['0.00"'];
  }
  return { iconColor };
};
