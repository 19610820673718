import { takeLatest, put } from 'redux-saga/effects';
import { UPDATE_GAUGE_DESCRIPTION, setGaugeDescription } from '../../reducers/adminGauges';

import loading from '../../utils/loading';

function* UpdateGaugeDescription({ gauge_id, description }) {
  const loadingMsg = 'Updating Gauge Description...';
  loading.start(loadingMsg);
  try {
    const resp = yield fetch(`/api/admin/gauges/${gauge_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ description }),
    });
    if (resp.status > 299) throw new Error('Invalid Response Code');
    yield put(setGaugeDescription({ gauge_id, description }));
  } catch (e) {
    console.log('ERROR Updating Gauge Description', e);
    // handle error
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(UPDATE_GAUGE_DESCRIPTION, UpdateGaugeDescription);
}
