import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import { createDispatchBindings } from '../../utils/redux';
import { fetchForecastList } from './../../reducers/forecastList';
import {setDateForForecastDropdown } from './../../reducers/dateForForecastDropdown';
import { setDateRange } from '../../reducers/dateRange';
 


const useStyles = makeStyles({  
  select: {
    width: 'calc(100% - 38px)',
    maxWidth: 115,
    height: 38,
    border:'none',
    borderRadius:4,
    padding:'0 6px'

  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    paddingLeft: 0,
  },  
  selectLabel: {
    border: '1px solid #efefef',
    color: '#efefef',
    padding: '8px 10px',
    paddingRight: '20px',
    marginRight: -5,
    borderRadius: 5,
    height: 38,
    overflowX: 'hidden',
  }, 
});

const DateInputForForecastDropdown = ({dateForForecastDropdown, setDateForForecastDropdown,fetchForecastList,setDateRange }) => {
  const classes = useStyles();

   useEffect(() => {
    fetchForecastList();
  }, [dateForForecastDropdown]);

  const handleDateChange = (dateValue) => {
    // store.dispatch(setDateRange({key:"startActive",value: false}));
    // store.dispatch(setDateRange({key:"endActive",value: false}));
    setDateRange({key:"startActive",value: false});
    setDateRange({key:"endActive",value: false});
    setDateForForecastDropdown({ value: dateValue, isResetToCurrentDate: false });
  };  

  return (
    <>
      <div className={classes.selectWrapper}>
        <h4 className={classes.selectLabel}>Date (UTC)</h4>        
        <input
          type="date"          
          className={classes.select}          
          value={ moment.utc(dateForForecastDropdown).format('YYYY-MM-DD')}
          onChange={(e) =>  handleDateChange(e.target.value)}          
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ dateForForecastDropdown }) => ({ dateForForecastDropdown});
const mapDispatchToProps = createDispatchBindings({ setDateForForecastDropdown, fetchForecastList,setDateRange });

export default connect(mapStateToProps, mapDispatchToProps)(DateInputForForecastDropdown);
