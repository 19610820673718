import { takeLatest, put, select } from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { REFRESH_FORECAST_LIST, prependForecastList, getLatestForecast } from '../../reducers/forecastList';
import { setDateForForecastDropdown } from '../../reducers/dateForForecastDropdown';
import loading from '../../utils/loading';

function* refreshForecastList() {
  const loadingMsg = 'Refreshing Forecasts...';
  loading.start(loadingMsg);
  try {
    const forecast_id = yield select(getLatestForecast);
    const response = yield fetch(`/api/gauges/forecast-list?forecast_id=${forecast_id}`);
    if (response.status > 299) throw new Error('Error fetching forecast list');
    const forecastList = yield response.json();
    forecastList.sort((a, b) => new Date(b.run_time).getTime() - new Date(a.run_time).getTime());
    yield put(prependForecastList(forecastList));
    yield put(setDateForForecastDropdown({ value: null, isResetToCurrentDate: true }))
  } catch (e) {
    console.log('ERROR fetching forecast list', e);
    yield put(setError({ errorId: 'fetchForecastListError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(REFRESH_FORECAST_LIST, refreshForecastList);
}
