import { takeLatest, put,select } from 'redux-saga/effects';
// import { takeLatest, put, select } from 'redux-saga/effects';

import { setError } from '../../reducers/errors';
import { FETCH_FORECAST_ICON_DATA, setForecastIconData } from '../../reducers/forecastIconData';
// import { getGauges, setGauges, maybeRunIconStuff } from '../../reducers/gauges';
import { maybeRunIconStuff } from '../../reducers/gauges';
import loading from '../../utils/loading';
import { getInvisibleForecastModels, getSelectedForecastDateTime } from '../../reducers/hiddenForecastModels';
import { getDateRange} from '../../reducers/dateRange';


function* fetchForecastIconData({ forecast_id }) {
  const loadingMsg = 'Fetching Forecast IconData...';
  loading.start(loadingMsg);
  let hiddenForecastModels = yield select(getInvisibleForecastModels);
  let forecast_datetime = yield select(getSelectedForecastDateTime);
  let { start, end, endActive } = yield select(getDateRange);
  let hiddenModels;
  try {
    let url = '/api/gauges/icons';
    if (forecast_id) {

      if (hiddenForecastModels && hiddenForecastModels.hiddenModels && hiddenForecastModels.hiddenModels.length > 0)          
            hiddenModels=  hiddenForecastModels.hiddenModels.toString();

      if (!hiddenModels || hiddenModels === undefined || hiddenModels === '' )
          url = `/api/gauges/forecast/${forecast_id}/${forecast_datetime}`;
      else
          url = `/api/gauges/forecast/${forecast_id}/${forecast_datetime}?hiddenModels=${hiddenModels}`; 
        
      if (endActive) 
      {       
        
        url = `/api/gauges/forecast/${forecast_id}/${forecast_datetime}/${start}/${end}`;
        if (hiddenModels && hiddenModels !== undefined && hiddenModels !== '' )
          url= url + `?hiddenModels=${hiddenModels}`;    
      }

    }
    

    const response = yield fetch(url);
    if (response.status > 299) throw new Error('Error fetching forecast icon data');
    const forecast = yield response.json();
    yield put(setForecastIconData(forecast));
    // const gauges = yield select(getGauges);
    // const inactiveGaugeIds = forecast.reduce((acc, { gauge_id, disable_all }) => {
    //   if (disable_all) acc = [...acc, gauge_id];
    //   return acc;
    // }, []);
    // const activeGauges = gauges.features.filter(({ properties }) => !inactiveGaugeIds.includes(properties.site_id));
    // yield put(setGauges({ ...gauges, features: activeGauges }));
    yield put(maybeRunIconStuff());
  } catch (e) {
    console.log('ERROR fetching forecast', e);
    yield put(setError({ errorId: 'fetchForecastIconDataError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(FETCH_FORECAST_ICON_DATA, fetchForecastIconData);
}
