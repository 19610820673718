import L from 'leaflet';

L.TimeDimension = require('leaflet-timedimension');
// action types
export const SET_TIMES = 'OGC/SET_TIMES';
export const UPDATE_CURRENT_TIME = 'OGC/UPDATE_CURRENT_TIME';
export const ADV_CURRENT_TIME = 'OGC/ADV_CURRENT_TIME';
export const DEC_CURRENT_TIME = 'OGC/DEC_CURRENT_TIME';
export const START_ADVANCING = 'OGC/START_ADVANCING';
export const STOP_ADVANCING = 'OGC/STOP_ADVANCING';
export const GET_TIMES = 'OGC/GET_TIMES';
export const UPDATE_FRAMERATE = 'OGC/UPDATE_FRAMERATE';
export const ADD_TO_MAP = 'OGC/ADD_TO_MAP';
export const RM_FROM_MAP = 'OGC/RM_FROM_MAP';
export const SET_REFRESH = 'OGC/SET_REFRESH';
export const SET_RADAR_OPACITY = 'OGC/SET_RADAR_OPACITY';
export const UPDATE_SELECTED = 'OGC/UPDATE_SELECTED';
export const CHANGE_SELECTED = 'OGC/CHANGE_SELECTED';
export const SET_ANIMATION_TICKS = 'OGC/SET_OGC_ANIMATION_TICKS';
// reducer with initial state
const initialState = {
  times: [],
  clampedTimes: [], // temp times array controled by user-selected animation ticks
  animationTicks: 1, // user-selected number of steps in animation loop
  current: 0,
  advancing: false,
  delay: 0.5,
  layer: null,
  onMap: false,
  timesLastFetched: 0,
  autoRefresh: true,
  ogcOpacity: 0.5,
  currentLayer: 'local khgx',
};
const layers = new Map([
  ['local khgx', {
    url: 'https://opengeo.ncep.noaa.gov/geoserver/khgx/ows?',
    opts: { layers: 'khgx_bref_raw', format: 'image/png', transparent: true, version: '1.3.0' },
  }],
  ['national mosaic', {
    url: 'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/ows?',
    opts: { layers: 'conus_bref_qcd', format: 'image/png', transparent: true, version: '1.3.0' },
  }],
]);
const makeLayer = (name) => {
  const { url, opts } = layers.get(name);
  const layer = L.timeDimension.layer.wms(L.tileLayer.wms(url, opts), { cache: 2, updateTimeDimension: true, updateTimeDimensionMode: 'replace' });
  layer.toJSON = () => '<layer>';
  return layer;
};
// actionCreators
export const setTimes = (times) => ({ type: SET_TIMES, payload: times });
export const setAnimationTicks = (tickNumber) => ({ type: SET_ANIMATION_TICKS, payload: tickNumber });
export const getTimes = (payload) => ({ type: GET_TIMES, payload });
export const advTime = () => ({ type: ADV_CURRENT_TIME });
export const decTime = () => ({ type: DEC_CURRENT_TIME });
export const startAdvancing = () => ({ type: START_ADVANCING });
export const stopAdvancing = () => ({ type: STOP_ADVANCING });
export const updateTime = (newIndex) => ({ type: UPDATE_CURRENT_TIME, payload: newIndex });
export const setDelay = (payload) => ({ type: UPDATE_FRAMERATE, payload });
export const addToMap = () => ({ type: ADD_TO_MAP });
export const rmFromMap = () => ({ type: RM_FROM_MAP });
export const setRefresh = (payload) => ({ type: SET_REFRESH, payload });
export const setOGCRadarOpacity = (ogcOpacity) => ({ type: SET_RADAR_OPACITY, payload: ogcOpacity });
export const changeSelected = (layer) => ({ type: CHANGE_SELECTED, payload: layer });
export const updateSelected = (layer) => ({ type: UPDATE_SELECTED, payload: layer });
// selectors
export const getDelay = (state) => state.radarOGC.delay;
export const getTimeAge = (state) => state.radarOGC.timesLastFetched;
export const getLayer = (state) => state.radarOGC.layer;
// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TIMES:
      state.times = payload;
      state.clampedTimes = payload;
      state.animationTicks = payload.length;
      if (state.current >= payload.length || state.current === 0) {
        state.current = payload.length - 1;
      }
      return;
    case SET_ANIMATION_TICKS:
      state.animationTicks = payload;
      state.clampedTimes = state.times.filter((value, i) => i < payload);
      if (state.current > state.clampedTimes.length - 1) state.current = state.clampedTimes.length - 1;
      return;
    case UPDATE_CURRENT_TIME:
      state.current = payload;
      state.current %= state.clampedTimes.length;
      return;
    case ADV_CURRENT_TIME:
      state.current += 1;
      state.current %= state.clampedTimes.length;
      return;
    case DEC_CURRENT_TIME:
      state.current -= 1;
      if (state.current < 0) {
        state.current = state.clampedTimes.length - 1;
      }
      return;
    case START_ADVANCING:
      state.advancing = true;
      return;
    case STOP_ADVANCING:
      state.advancing = false;
      return;
    case UPDATE_FRAMERATE:
      if (!payload) {
        state.delay = initialState.delay;
        return;
      }
      state.delay = payload;
      return state;
    case ADD_TO_MAP:
      if (state.onMap) {
        return;
      }
      state.layer = makeLayer(state.currentLayer);
      state.layer.addTo(window.lmap);
      state.onMap = true;
      state.layer.setOpacity(state.ogcOpacity);
      return;
    case RM_FROM_MAP:
      if (!state.onMap || !state.layer) {
        return;
      }
      state.layer.removeFrom(window.lmap);
      state.layer = null;
      state.onMap = false;
      return;
    case SET_REFRESH:
      state.autoRefresh = payload;
      return;
    case SET_RADAR_OPACITY:
      if (!state.layer) {
        return;
      }
      state.layer.setOpacity(payload);
      state.ogcOpacity = payload;
      return;
    case UPDATE_SELECTED:
      state.currentLayer = payload;
      return;
    default:
      return state;
  }
};
