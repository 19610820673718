import Fuse from 'fuse.js';

const gaugeFuseOptions = {
  keys: ['gauge_id', 'location'],
  findAllMatches: true,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 1000,
  maxPatternLength: 32,
  minMatchCharLength: 1,
};

let gaugeFuse;

const fuzzySearchGauges = ({ gauges, searchValue }) => {
  if (!gaugeFuse) {
    gaugeFuse = new Fuse(gauges, gaugeFuseOptions);
  }
  const searchResults = gaugeFuse.search(searchValue);
  const formattedResults = searchResults.map(({ item }) => item && item);
  if (!formattedResults?.length) return [];
  return formattedResults;
};

export default fuzzySearchGauges;
