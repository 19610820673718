import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { createDispatchBindings } from '../../utils/redux';
import { mrmsColorRamp, mrmsNumberScalingRamp } from '../../config/constants';
import { getNumberScaleKey, getDateHourDifference } from '../../utils/map/mrms/determineMrmsPixelColor';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 80,
    right: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#efefef',
    zIndex: 999,
    padding: 5,
    border: '1px solid #999',
    borderRadius: 5,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 45,
    fontSize: 13,
  },
  colorSquare: {
    height: 15,
    width: 15,
  },
});

const dateRangeOptions = Object.keys(mrmsNumberScalingRamp);

const MrmsKey = ({ dateRange }) => {
  const classes = useStyles();
  console.log('Re Rendering MrmsKey! ====')

  // TODO: After optimizations to allow date range to be passed to MRMS query, uncomment so date range determines color scale numbers
  // const dateRangeDiff = getDateHourDifference(dateRange.start, dateRange.end);
  // const numberScaleKey = getNumberScaleKey(dateRangeDiff, dateRangeOptions);
  const numberScaleKey = 3;
  const reverseNumberScale = [...mrmsNumberScalingRamp[numberScaleKey]].reverse();
  const reversedColorRamp = [...mrmsColorRamp].reverse();

  return (
    <div className={classes.root}>
      {reversedColorRamp.map((color, i) => (
        <div key={color} className={classes.row}>
          {reverseNumberScale[i]}
          <div style={{ backgroundColor: color }} className={classes.colorSquare} />
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = ({ dateRange }) => ({ dateRange });
const mapDispatchToProps = createDispatchBindings({  });

export default connect(mapStateToProps, mapDispatchToProps)(MrmsKey);
