import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

import AppHeader from './components/AppHeader/AppHeader';
import SideBar from './components/SideBar/SideBar';
import MainContentRouter from './components/MainContentRouter';
import Modal from './components/Modal/Modal';
import MessageToUser from './components/MessageToUser';
import NotificationDrawer from './components/NotificationDrawer/NotificationDrawer';

import { setupMapServices } from './reducers/mapRefs';
import { getCurrentUser } from './reducers/currentUser';
import { syncSettingsToUrl } from './reducers/mapLayers';
import { fetchForecastList } from './reducers/forecastList';

import NoAccess from './components/NoAccess';
import Show from './components/HOC/Show';
import loading from './utils/loading';
import { createDispatchBindings } from './utils/redux';
import mainTheme from './config/theme';
import { allowedUserRoles } from './config/constants';
import config from './config';
import { setDateRange } from './reducers/dateRange';
// import  store  from './store';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  box: {
    padding: 0,
    position: 'relative',
    height: `calc(100vh - ${config.headerHeight}px)`,
  },
  root: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    position: 'fixed',
    overflow: 'hidden',
  },
  main: {
    flexGrow: 1,
    width: `calc(100% - ${theme.spacing(7)}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(9)}px)`,
    },
  },
}));

const App = ({ setupMapServices, getCurrentUser, syncSettingsToUrl, currentUser, fetchForecastList ,setDateRange}) => {
  const classes = useStyles();

  useEffect(() => {
    setDateRange({key:"startActive",value: false});
    setDateRange({key:"endActive",value: false});  
    getCurrentUser();    
    loading.end('Loading Application...');
    setupMapServices();
    syncSettingsToUrl();
    fetchForecastList();    
    
  }, [getCurrentUser, setupMapServices, syncSettingsToUrl, fetchForecastList]);

  return (
    <ThemeProvider theme={mainTheme}>
      <Show test={allowedUserRoles.includes(currentUser.role)}>
        <NotificationDrawer />
        <MessageToUser />
        <div className={classes.root}>
          <AppHeader />
          <SideBar />
          <Modal />
          <main className={classes.main}>
            <div className={classes.appBarSpacer} />
            <Box className={classes.box}>
              <MainContentRouter />
            </Box>
            {/* <Footer /> */}
          </main>
        </div>
      </Show>
      <Show test={currentUser.role === 'NO_ACCESS'}>
        <NoAccess message="Please contact an Admin to give you permissions." />
      </Show>
      <Show test={!currentUser.email}>
        <NoAccess message="Please sign in to access this site." login />
      </Show>
    </ThemeProvider>
  );
};

const mapStateToProps = ({ currentUser }) => ({ currentUser });
const mapDispatchToProps = createDispatchBindings({ setupMapServices, getCurrentUser, syncSettingsToUrl, fetchForecastList ,setDateRange});

export default connect(mapStateToProps, mapDispatchToProps)(App);
