import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { createDispatchBindings } from '../../utils/redux';
import { setWatershedValue } from '../../reducers/watershed';
import { displayWatershed } from '../../utils/map/gaugeMarkers';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    paddingTop: 5,
  },
  title: {
    margin: '0px 0px 5px -5px',
  },
  select: {
    height: 30,
    fontSize: 14,
  },
});

const WatershedDropdown = ({ watershed, setWatershedValue }) => {
  const classes = useStyles(); 
  const handleSelectingWatershed = (e) => {
    setWatershedValue(e.target.value);
    displayWatershed();    
  };

  return (
    <FormControl variant="outlined" size="small" className={classes.formControl}>
      <p className={classes.title}><b>Watershed:</b></p>
      <Select
        size="small"
        native
        value={watershed.value}
        onChange={handleSelectingWatershed}
        className={classes.select}
      >
        {watershed.options.map(({ label, value }) => (
          <option key={value} value={value}>{label}</option>
        ))}
      </Select>
    </FormControl>
  );
};

const mapStateToProps = ({ watershed }) => ({ watershed });
const mapDispatchToProps = createDispatchBindings({ setWatershedValue });

export default connect(mapStateToProps, mapDispatchToProps)(WatershedDropdown);
