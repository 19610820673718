import { takeLatest, put } from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { UPDATE_USER, getUsers } from '../../reducers/users';

import loading from '../../utils/loading';

function* updateUser({ user }) {
  const loadingMsg = 'Updating User...';
  loading.start(loadingMsg);
  try {
    const response = yield fetch('/api/users', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    if (response.status > 299) throw new Error('Error updating user');
    yield put(getUsers());
  } catch (e) {
    console.log('ERROR updating user', e);
    yield put(setError({ errorId: 'updateUserError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(UPDATE_USER, updateUser);
}
