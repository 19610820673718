import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { createDispatchBindings } from '../../utils/redux';
import { deleteSubscription } from '../../reducers/subscriptions';

const useStyles = makeStyles({
  subText: {
    margin: 0,
  },
  deleteButton: {
    padding: 5,
  },
  deleteIcon: {
    color: 'red',
  },
  tableCell: {
    padding: 10,
  },
});

const SubscriptionRow = ({ subscription, deleteSubscription }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.tableCell} align="left">{subscription.gauge_id}</TableCell>
      <TableCell className={classes.tableCell} align="center">{subscription.name}</TableCell>
      <TableCell className={classes.tableCell} align="center">{subscription.threshold}</TableCell>
      <TableCell className={classes.tableCell} align="center">
        <IconButton onClick={() => deleteSubscription(subscription.subscription_id)} className={classes.deleteButton}>
          <DeleteIcon className={classes.deleteIcon} fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const mapDispatchToProps = createDispatchBindings({ deleteSubscription });

export default connect(null, mapDispatchToProps)(SubscriptionRow);
