import React, { useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import UserControls from './UserControls';
import GaugeControls from './GaugeControls';
import ForecastControls from './ForecastControls';
import history from '../../history';
import { createDispatchBindings } from '../../utils/redux';

const useStyles = makeStyles({
  adminPageWrapper: {
    width: '100%',
    height: '100%',
    padding: 20,
  },
  tabsWrapper: {
    paddingTop: 10,
  },
  tabPanel: {
    backgroundColor: '#dedede',
  },
});

function TabPanel(props) {
  const { children, value, index, className } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className={className}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AdminPage = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <div className={classes.adminPageWrapper}>
      <Button
        onClick={() => history.push({
          pathname: '/map',
          search: history.location.search,
        })}
        color="primary"
        variant="contained"
      >
        <ArrowBackIosIcon />
        Back To Map
      </Button>
      <div className={classes.tabsWrapper}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={(e, newValue) => setCurrentTab(newValue)}>
              <Tab
                label="Users"
                style={{
                  borderRadius: '5px 5px 0 0',
                  backgroundColor: currentTab === 0 ? '#dedede' : '',
                }}
              />
              <Tab
                label="Gauges"
                style={{
                  borderRadius: '5px 5px 0 0',
                  backgroundColor: currentTab === 1 ? '#dedede' : '',
                }}
              />
              <Tab
                label="Forecasts"
                style={{
                  borderRadius: '5px 5px 0 0',
                  backgroundColor: currentTab === 2 ? '#dedede' : '',
                }}
              />
            </Tabs>
          </Box>
          <TabPanel className={classes.tabPanel} value={currentTab} index={0}>
            <UserControls />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={currentTab} index={1}>
            <GaugeControls />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={currentTab} index={2}>
            <ForecastControls />
          </TabPanel>
        </Box>
      </div>

    </div>
  );
};

const mapStateToProps = ({ test }) => ({ test });
const mapDispatchToProps = createDispatchBindings({ });

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
