import React from 'react';
import { Parser } from 'json2csv';
import JsZip from 'jszip';
import FileSaver from 'file-saver';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import history from '../../history';
import { getRainfallChartData } from './RainfallPlot/RainfallPlot';
import { getStageFlowChartData } from './StageFlowPlot/StageFlowPlot';


const useStyles = makeStyles({
  button: {
    marginRight: 10,
    fontSize: ({ location }) => location === 'dashboard' ? 10 : 12,
    padding: ({ location }) => location === 'dashboard' ? '0 4px' : '2px 4px',
    boxShadow: ({ location }) => location === 'dashboard' ? 'none' : '',
  },
});

const ExportCSVButton = ({ graphType, location }) => {
  const classes = useStyles({ location });
  const gaugeId = history.location.pathname.split('/')[2];

  const handleDownloadingCSV = () => {
    let data = [];
    if (graphType === 'rainfall') {
      data = getRainfallChartData();
    } else if (graphType === 'stageFlow') {
      data = getStageFlowChartData();
    }
    if (data.length) {
      const json2csvParser = new Parser();
      if (data.length) {
        const blobs = [];
        data.forEach((sheet) => {
          if (sheet.label === 'remove' || !sheet.data?.length) return;
          const csv = json2csvParser.parse(sheet.data);
          const blob = new Blob(['\ufeff', csv]);
          blobs.push({ label: sheet.label, blob });
        });
        const zip = JsZip();
        blobs.forEach(({ label, blob }) => zip.file(`${gaugeId}_${label}.csv`, blob));
        zip.generateAsync({ type: 'blob' }).then((zipFile) => {
          const date = new Date();
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          const fileName = `${gaugeId}-${graphType}-${month}_${day}_${year}.zip`;
          return FileSaver.saveAs(zipFile, fileName);
        });
      }
    }
  };

  return (
    <Button
      onClick={handleDownloadingCSV}
      className={classes.button}
      size="small"
      variant="contained"
    >
      Export CSV
    </Button>
  );
};


export default ExportCSVButton;
