// action type constants
export const FETCH_FORECAST_ICON_DATA = 'FETCH_FORECAST_ICON_DATA';
export const SET_FORECAST_ICON_DATA = 'SET_FORECAST_ICON_DATA';

// actionCreators
export const fetchForecastIconData = (forecast_id, active) => ({ type: FETCH_FORECAST_ICON_DATA, forecast_id, active });
export const setForecastIconData = (payload) => ({ type: SET_FORECAST_ICON_DATA, payload });

export const getForecastIconData = (state) => state.forecastIconData;
const initialState = [];

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FORECAST_ICON_DATA:
      state = payload;
      return state;
    default:
      return state;
  }
};
