// action type constants
export const FETCH_GAUGE_THRESHOLDS = 'FETCH_GAUGE_THRESHOLDS';
export const SET_GAUGE_THRESHOLDS = 'SET_GAUGE_THRESHOLDS';
export const SET_TOGGLE_STATE = 'SET_TOGGLE_STATE';
export const UPDATE_TOGGLE_STATE = 'UPDATE_TOGGLE_STATE';

export const setGaugeThresholds = (thresholdData) => ({
  type: SET_GAUGE_THRESHOLDS,
  thresholdData,
});

export const setToggleState = (toggleState) => ({ type: SET_TOGGLE_STATE, toggleState });
export const updateToggleState = ({ key, value }) => ({ type: UPDATE_TOGGLE_STATE, key, value });

// selectors
export const getGaugeThresholds = (state) => state.gaugeThresholds.data;

const initialState = {
  data: false,
  toggleState: false,
};

// reducer
export default (state = initialState, { type, thresholdData, toggleState, key, value }) => {
  switch (type) {
    case SET_GAUGE_THRESHOLDS: {
      state.data = thresholdData;
      state.toggleState = false;
      return;
    }
    case SET_TOGGLE_STATE: {
      state.toggleState = toggleState;
      return;
    }
    case UPDATE_TOGGLE_STATE: {
      state.toggleState[key] = value;
      return;
    }
    default:
      return state;
  }
};
