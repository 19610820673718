import { initialLayerSettings } from '../config/constants';

// action type constants
export const FETCH_AGO_LAYERS = 'FETCH_AGO_LAYERS';
export const SET_MAP_LAYERS = 'SET_MAP_LAYERS';
export const UPDATE_LAYER_VALUE = 'UPDATE_LAYER_VALUE';
export const SYNC_SETTINGS_TO_URL = 'SYNC_SETTINGS_TO_URL';

// actionCreators
export const fetchAGOLayers = () => ({ type: FETCH_AGO_LAYERS });
export const setMapLayers = (mapLayers) => ({ type: SET_MAP_LAYERS, mapLayers });
export const updateLayerValue = ({ layerName, key, value }) => ({ type: UPDATE_LAYER_VALUE, layerName, key, value });
export const syncSettingsToUrl = () => ({ type: SYNC_SETTINGS_TO_URL });

// selectors
export const selectMapLayers = ({ mapLayers }) => ({ mapLayers });

const initialState = initialLayerSettings;

// reducer
export default (state = initialState, { type, mapLayers, layerName, key, value }) => {
  switch (type) {
    case SET_MAP_LAYERS: {
      return mapLayers;
    }
    case UPDATE_LAYER_VALUE: {
      if (key) state[layerName][key] = value;
      else state[layerName] = value;
      return state;
    }
    default:
      return state;
  }
};
