import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import ShareIcon from '@material-ui/icons/Share';
import RefreshIcon from '@material-ui/icons/Refresh';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { createDispatchBindings } from '../../utils/redux';
import history from '../../history';
import Show from '../HOC/Show';
import GaugeSearchBar from './GaugeSearchBar';
import DatePickerPopoverButton from './DatePickerPopoverButton';
import ForecastDropdown from './ForecastDropdown';
import DateInputForForecastDropdown from './DateInputForForecastDropdown';
import { appHeaderStyles } from './appHeaderStyles';
import { setRefresh } from '../../reducers/radar';
import { useWindowSize } from '../../utils/customHooks';
import { openNotificationDrawer, closeNotificationDrawer } from '../../reducers/notificationDrawer';
import { refreshForecastList } from '../../reducers/forecastList';
import { fetchRainfallStatus } from '../../reducers/rainfallStatus';
import { fetchAGOLayers } from '../../reducers/mapLayers';
import { setDateRange } from '../../reducers/dateRange';
import  store  from '../../store'; 

import logo from '../../assets/hcfcd-logo.png';
import logoSmall from '../../assets/hcfcd-logoSmall.png';
import { fetchGaugeForecast } from '../../reducers/gaugeForecast';
import { fetchForecastIconData } from '../../reducers/forecastIconData';

const useStyles = makeStyles((theme) => (appHeaderStyles(theme)));

const ColoredSwitch = withStyles((theme) => ({
  switchBase: {
    '&$checked': {
      color: theme.palette.active,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.active,
    },
  },
}))(Switch);

const AppHeader = ({ notificationDrawer, closeNotificationDrawer, openNotificationDrawer, currentUser,  notifications, setRefresh, autoRefresh, selectedGaugeData, refreshForecastList, mapLayers, dateRange,fetchRainfallStatus, fetchAGOLayers,fetchForecastIconData,fetchGaugeForecast }) => {
  const classes = useStyles();
  const windowSize = useWindowSize(useState, useEffect);
  const { site_id } = selectedGaugeData;

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [refreshDataLoop, setRefreshDataLoop] = useState('false');
  const [unreadNotifications, setUnreadNotifications] = useState();
  const [updateTime, setUpdateTime] = useState(Date.now());

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleCopyingUrl = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const refreshData = () => {
    store.dispatch(setDateRange({key:"startActive",value: false}));
    store.dispatch(setDateRange({key:"endActive",value: false}));  

    refreshForecastList();

    const { hiddenForecastModels } = store.getState();
    const { selectedGaugeData } = store.getState()
    fetchForecastIconData(hiddenForecastModels.selectedForecastId);
    if (selectedGaugeData && selectedGaugeData.site_id) 
    { 
      //fetchGaugeData(site_id);
      fetchGaugeForecast({ forecast_id: hiddenForecastModels.selectedForecastId, gauge_id: selectedGaugeData.site_id });
    }

    if (mapLayers.gaugeIconType === 'rain') {
      fetchRainfallStatus({ data_start: dateRange.start });
    } else if (mapLayers.gaugeIconType === 'flow') {
      fetchAGOLayers();
    }  
    
    
    setUpdateTime(Date.now());
  };

  const handleNavToAdminPage = () => {
    history.push({
      pathname: '/admin',
      search: history.location.search,
    });
    handleMenuClose();
  };

  useEffect(() => {
    const keys = Object.keys(notifications);
    if (keys?.length) {
      const values = Object.values(notifications);
      const unreadCount = values.reduce((acc, currentVal) => acc + currentVal.length, 0);
      setUnreadNotifications(unreadCount);
    }
  }, [notifications]);

  useEffect(() => {    

    if (autoRefresh && refreshDataLoop === 'false') {
      setRefreshDataLoop(setInterval(() => {
        refreshData();
      }, (1000 * 60 * 5))); // refreshes data every 5 minutes
    } else {
      clearInterval(refreshDataLoop);
      setRefreshDataLoop('false');
    }
  }, [autoRefresh]);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {(currentUser.role === 'ADMIN' || currentUser.role === 'SUPERUSER') && (
        <MenuItem
          onClick={handleNavToAdminPage}
        >
          Admin
        </MenuItem>
      )}
      <MenuItem component="a" href="/logout">
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton onClick={refreshData} color="inherit">
          <RefreshIcon />
        </IconButton>
        <p>Refresh</p>
      </MenuItem>
      <MenuItem>
        <IconButton onClick={handleCopyingUrl} color="inherit">
          <ShareIcon />
        </IconButton>
        <p>Share</p>
      </MenuItem>
      <MenuItem>
        <DatePickerPopoverButton />
        <p>Date Picker</p>
      </MenuItem>
      <MenuItem>
        <IconButton onClick={notificationDrawer.open ? closeNotificationDrawer : openNotificationDrawer} color="inherit">
          <Badge badgeContent={unreadNotifications} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <AppBar
          position="absolute"
          className={clsx(classes.appBar)}
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.headerLeft}>
              <div className={classes.logoWrapper}>
                <Link to="/map" className={classes.logoLink}>
                  <img className={classes.logo} src={windowSize.width > 500 ? logo : logoSmall} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className={classes.grow} />
            <div className={classes.selectInputsWrapperDrpDown}>              
              <ForecastDropdown gauge_id={site_id} />              
            </div>            
            <div className={classes.selectInputsWrapper}>              
              <DateInputForForecastDropdown/>
            </div>
            <Show test={windowSize.width >= 960}>
              <div className={classes.selectInputsWrapper}>
                <GaugeSearchBar />
              </div>
            </Show>
            <div className={classes.sectionDesktop}>
              <div className={classes.dataRefreshWrapper}>
                <div className={classes.dataRefreshTop}>
                  <FormControlLabel
                    className={classes.autoRefresh}
                    control={(
                      <ColoredSwitch
                        checked={autoRefresh}
                        onChange={(e) => setRefresh(e.target.checked)}
                        name="autoRefresh"
                      />
                    )}
                    label="Auto Refresh"
                    labelPlacement="start"
                  />
                </div>
                <p className={classes.updateTime}>Updated: {moment(updateTime).format('MM/DD HH:mm:SS')}</p>
              </div>
              <IconButton onClick={refreshData} color="inherit">
                <RefreshIcon />
              </IconButton>
              <IconButton onClick={handleCopyingUrl} color="inherit">
                <ShareIcon />
              </IconButton>
              <DatePickerPopoverButton />
              <IconButton onClick={notificationDrawer.open ? closeNotificationDrawer : openNotificationDrawer} color="inherit">
                <Badge badgeContent={unreadNotifications} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </div>
    </div>
  );
};

const mapStateToProps = ({ currentUser, radar: { autoRefresh }, notificationDrawer, notifications, selectedGaugeData, mapLayers, dateRange }) => ({ currentUser, notificationDrawer, notifications, autoRefresh, selectedGaugeData, mapLayers, dateRange });
const mapDispatchToProps = createDispatchBindings({ fetchRainfallStatus, openNotificationDrawer, closeNotificationDrawer, setRefresh, refreshForecastList, fetchAGOLayers,fetchForecastIconData ,fetchGaugeForecast});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
