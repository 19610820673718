// action type constants
export const SET_SUMMARY_TABLE_ROW_VALUES = 'SET_SUMMARY_TABLE_ROW_VALUES';
export const CLEAR_SUMMARY_TABLE_FORECASTS = 'CLEAR_SUMMARY_TABLE_FORECASTS';

// actionCreators
export const setSummaryTableRowValues = ({ rowKey, keyValueArray }) => ({ type: SET_SUMMARY_TABLE_ROW_VALUES, keyValueArray, rowKey });
export const clearSummaryTableForecasts = () => ({ type: CLEAR_SUMMARY_TABLE_FORECASTS });

const initialState = [{
  scenario: 'Observed',
  floodStatus: '',
  stagePeak: '',
  timeOfPeak: '',
  flow: '',
  rainfall: '',
}];

// reducer
export default (state = initialState, { type, keyValueArray, rowKey }) => {
  switch (type) {
    case SET_SUMMARY_TABLE_ROW_VALUES: {
      let indexOfRow = -1;
      state.find((row, i) => {
        if (row.scenario === rowKey) indexOfRow = i;
      });
      if (indexOfRow < 0) {
        indexOfRow = state.push({ scenario: rowKey }) - 1;
      }
      keyValueArray.forEach(({ key, value }) => {
        state[indexOfRow][key] = value;
      });
      return;
    }
    case CLEAR_SUMMARY_TABLE_FORECASTS: {
      const observed = state.find((row) => row.scenario === 'Observed');
      state = observed ? [observed] : [];
      return state;
    }
    default:
      return state;
  }
};
