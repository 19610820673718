import qs from 'querystring';
import { takeLatest, put } from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { DELETE_SUBSCRIPTION, fetchSubscriptions } from '../../reducers/subscriptions';
import { fetchNotifications } from '../../reducers/notifications';
import loading from '../../utils/loading';

function* deleteSubscription({ subscription_id }) {
  const loadingMsg = 'Deleting Subscription...';
  loading.start(loadingMsg);
  try {
    const response = yield fetch(`/api/subscription?${qs.stringify({ subscription_id })}`, {
      method: 'DELETE',
    });
    if (response.status > 299) throw new Error('Error deleting subscription');
    yield put(fetchSubscriptions());
    yield put(fetchNotifications());
  } catch (e) {
    console.log('ERROR deleting subscription', e);
    yield put(setError({ errorId: 'deleteSubscriptionError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(DELETE_SUBSCRIPTION, deleteSubscription);
}
