import { takeLatest, put,select} from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { FETCH_FORECAST_LIST, setForecastList, appendForecastList } from '../../reducers/forecastList';
import loading from '../../utils/loading';
import { getDateForForecastDropdown } from '../../reducers/dateForForecastDropdown';
import moment from 'moment';

function* fetchForecastList() {
  const loadingMsg = 'Fetching Forecasts...';
  loading.start(loadingMsg);
  let end_date = yield select(getDateForForecastDropdown);      
  const start_date = moment.utc(end_date).subtract(30, "days").format('YYYY-MM-DD');
  end_date = moment.utc(end_date).add(1,"days").format('YYYY-MM-DD');
  try {
    yield put(setForecastList(false));    
     const response = yield fetch(`/api/gauges/forecast-list/${start_date}/${end_date}?top=5`);
    if (response.status > 299) throw new Error('Error fetching forecast list');
    const forecastList = yield response.json();
    forecastList.sort((a, b) => new Date(b.run_time).getTime() - new Date(a.run_time).getTime());
    yield put(setForecastList(forecastList));
  } catch (e) {
    console.log('ERROR fetching forecast list', e);
    yield put(setError({ errorId: 'fetchForecastListError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
  try {    
    const response = yield fetch(`/api/gauges/forecast-list/${start_date}/${end_date}`);
    if (response.status > 299) throw new Error('Error fetching forecast list');
    const forecastList = yield response.json();
    forecastList.sort((a, b) => new Date(b.run_time).getTime() - new Date(a.run_time).getTime());    
    yield put(appendForecastList(forecastList.slice(5)));
  } catch (e) {
    console.log('ERROR fetching forecast list', e);
    yield put(setError({ errorId: 'fetchForecastListError', value: e.message || true }));
  }
}

export default function* watch() {
  yield takeLatest(FETCH_FORECAST_LIST, fetchForecastList);
}
