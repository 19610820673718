import fuzzyFilterAdminGauges from '../utils/fuzzySearch/fuzzySearchAdminGauges';

// action type constants
export const GET_ADMIN_GAUGES = 'GET_ADMIN_GAUGES';
export const SET_ADMIN_GAUGES = 'SET_ADMIN_GAUGES';
export const UPDATE_GAUGE_DESCRIPTION = 'UPDATE_GAUGE_DESCRIPTION';
export const SET_GAUGE_DESCRIPTION = 'SET_GAUGE_DESCRIPTION';
export const FUZZY_SEARCH_GAUGES = 'FUZZY_SEARCH_GAUGES';
export const TOGGLE_GAUGE_DISABLE = 'TOGGLE_GAUGE_DISABLE';

// actionCreators
export const getAdminGauges = () => ({ type: GET_ADMIN_GAUGES });
export const setAdminGauges = (payload) => ({ type: SET_ADMIN_GAUGES, payload });
export const updateGaugeDescription = (payload) => ({ type: UPDATE_GAUGE_DESCRIPTION, ...payload });
export const fuzzySearchGauges = (payload) => ({ type: FUZZY_SEARCH_GAUGES, payload });
export const setGaugeDescription = (description) => ({ type: SET_GAUGE_DESCRIPTION, payload: { description } });
export const toggleGaugeDisable = (payload) => ({ type: TOGGLE_GAUGE_DISABLE, payload });

const initialState = {
  gaugeList: [],
  gaugeFuzzySearchResults: [],
};

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ADMIN_GAUGES:
      state.gaugeList = payload;
      state.gaugeFuzzySearchResults = payload;
      return state;
    case SET_GAUGE_DESCRIPTION: {
      const { description } = payload;
      const indexToUpdate = state.gaugeList.find(({ gauge_id }, index) => {
        if (gauge_id === payload.gauge_id) return index;
      });
      if (indexToUpdate) state.gaugeList[indexToUpdate] = { ...state.gaugeList[indexToUpdate], description };
      return state;
    }
    case FUZZY_SEARCH_GAUGES: {
      if (!payload?.length) {
        state.gaugeFuzzySearchResults = state.gaugeList;
        return state;
      }
      const gauges = JSON.parse(JSON.stringify(state.gaugeList));
      const results = fuzzyFilterAdminGauges({ gauges, searchValue: payload });
      state.gaugeFuzzySearchResults = results;
      return state;
    }
    case TOGGLE_GAUGE_DISABLE: {
      const { column, bool, gauge_id } = payload;
      console.log({ column, bool, gauge_id });
      const gauges = JSON.parse(JSON.stringify(state.gaugeList));
      let indexToChange;
      gauges.find((gauge, index) => {
        if (Number(gauge.gauge_id) === Number(gauge_id)) {
          indexToChange = index;
          return true;
        }
      });
      state.gaugeList[indexToChange] = { ...state.gaugeList[indexToChange], [column]: bool };
      const filteredGauges = JSON.parse(JSON.stringify(state.gaugeFuzzySearchResults));
      filteredGauges.find((gauge, index) => {
        if (Number(gauge.gauge_id) === Number(gauge_id)) {
          indexToChange = index;
          return true;
        }
      });
      state.gaugeFuzzySearchResults[indexToChange] = { ...state.gaugeFuzzySearchResults[indexToChange], [column]: bool };
      return state;
    }
    default:
      return state;
  }
};
