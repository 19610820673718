import { takeLatest, put } from 'redux-saga/effects';
import { DELETE_FORECAST, removeForecastFromList } from '../../reducers/adminForecasts';
import { closeModal } from '../../reducers/modal';

import loading from '../../utils/loading';

function* DeleteForecast({ forecast_id, resync }) {
  const loadingMsg = 'Deleting forecast...';
  loading.start(loadingMsg);
  try {
    const resp = yield fetch(`/api/admin/forecast/${forecast_id}`, {
      method: 'DELETE',
    });
    if (resp.status > 299) throw new Error('Invalid Response Code');
    yield put(removeForecastFromList(forecast_id));
    yield put(closeModal());
    loading.end(loadingMsg);
    if (resync) {
      const resp = yield fetch(`/api/admin/forecast/resync/${forecast_id}`, {
        method: 'PUT',
      });
      if (resp.status > 299) throw new Error('Invalid Response Code');
    }
  } catch (e) {
    console.log('ERROR Deleting forecast', e);
    // handle error
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(DELETE_FORECAST, DeleteForecast);
}
