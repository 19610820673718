import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import { getAdminForecasts, fuzzySearchForecasts } from '../../reducers/adminForecasts';
import { getAdminForecastEnsembles, setEnsembleDisable } from '../../reducers/adminForecastEnsembles';
import { openModal } from '../../reducers/modal';
import { createDispatchBindings } from '../../utils/redux';
import { DELETE_FORECAST_MODAL } from '../../config/constants';

const useStyles = makeStyles({
  forecastControlsWrapper: {
    width: '100%',
    height: '100%',
    padding: 20,
    display: 'flex',
  },
  forecastWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    marginLeft: 20,
  },
  roleDropdown: {
    minWidth: 200,
  },
  deleteIcon: {
    color: 'red',
  },
  refreshIcon: {
    color: 'blue',
  },
});

const StyledTableRow = withStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#efefef',
    },
  },
})(TableRow);

const HeaderCell = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(TableCell);

const ForecastTableWrapper = withStyles({
  root: {
    marginTop: 10,
    marginBottom: 20,
    width: '100%',
  },
})(Paper);

const EnsembleTableWrapper = withStyles({
  root: {
    marginTop: 10,
    marginBottom: 20,
    width: '20%',
  },
})(Paper);

const ForecastControls = ({ adminForecasts, getAdminForecasts, adminForecastEnsembles, getAdminForecastEnsembles, setEnsembleDisable, fuzzySearchForecasts, openModal }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const rowsPerPage = 8;
  const { forecastFuzzySearchResults } = adminForecasts;

  useEffect(() => {
    getAdminForecasts();
    getAdminForecastEnsembles();
  }, []);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const emptyRows = forecastFuzzySearchResults?.length ? rowsPerPage - Math.min(rowsPerPage, forecastFuzzySearchResults.length - page * rowsPerPage) : 0;

  return (
    <div className={classes.forecastControlsWrapper}>
      <TableContainer component={EnsembleTableWrapper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <HeaderCell align="center">Name</HeaderCell>
              <HeaderCell align="center">Disable</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminForecastEnsembles.map(({ name, disable }) => (
              <StyledTableRow key={name}>
                <TableCell align="center" component="th" scope="row">
                  {`${name}`}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    disabled={name === 'H0'}
                    checked={disable}
                    onChange={(e) => setEnsembleDisable({ name, disable: e.target.checked })}
                  />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.forecastWrapper}>
        <TextField label="Search Forecasts" variant="outlined" onChange={(e) => fuzzySearchForecasts(e.target.value)} />
        {forecastFuzzySearchResults?.length ? (
          <TableContainer component={ForecastTableWrapper}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <HeaderCell>Name</HeaderCell>
                  <HeaderCell align="left">Start Time</HeaderCell>
                  <HeaderCell align="left">End Time</HeaderCell>
                  <HeaderCell align="left">Controls</HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {forecastFuzzySearchResults?.length && forecastFuzzySearchResults
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((forecast) => (
                    <StyledTableRow key={forecast.id}>
                      <TableCell component="th" scope="row">
                        {`${forecast.forecast_id} - ${forecast.name}`}
                      </TableCell>
                      <TableCell align="left">
                        {forecast.start_time}
                      </TableCell>
                      <TableCell align="left">
                        {forecast.end_time}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title="Delete and Resync">
                          <IconButton
                            onClick={() => openModal({
                              type: DELETE_FORECAST_MODAL,
                              data: { forecast, resync: true },
                            })}
                            sx={{
                              minHeight: 0,
                              minWidth: 0,
                              padding: 0,
                            }}
                          >
                            <DeleteIcon className={classes.deleteIcon} fontSize="small" />
                            <AutorenewIcon className={classes.refreshIcon} fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Permanently">
                          <IconButton
                            onClick={() => openModal({
                              type: DELETE_FORECAST_MODAL,
                              data: { forecast },
                            })}
                            sx={{
                              minHeight: 0,
                              minWidth: 0,
                              padding: 0,
                            }}
                          >
                            <DeleteIcon className={classes.deleteIcon} fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 57 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={forecastFuzzySearchResults.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
            />
          </TableContainer>
        ) : <p className={classes.noResultsMessage}>No Search Results Found.</p> }
      </div>
    </div>
  );
};

const mapStateToProps = ({ adminForecasts, adminForecastEnsembles }) => ({ adminForecasts, adminForecastEnsembles });
const mapDispatchToProps = createDispatchBindings({ getAdminForecasts, getAdminForecastEnsembles, fuzzySearchForecasts, setEnsembleDisable, openModal });

export default connect(mapStateToProps, mapDispatchToProps)(ForecastControls);
