import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  button: {
    position: 'absolute',
    top: 30,
    right: 60,
  },
});

const ResetZoomButton = ({ chartRef }) => {
  const classes = useStyles();
  const handleResettingZoom = () => {
    chartRef.resetZoom();
  };
  return (
    <Tooltip title="Zoom Out">
      <IconButton
        onClick={handleResettingZoom}
        className={classes.button}
        variant="contained"
        color="primary"
        size="small"
      >
        <ZoomOutIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

const mapStateToProps = ({ chartRefs }) => ({ chartRefs });

export default connect(mapStateToProps)(ResetZoomButton);
