/* eslint-disable import/no-cycle */
import { fork, all } from 'redux-saga/effects';

import mapSetup from './mapSetup';
import getCurrentUser from './getCurrentUser';
import fetchGaugeData from './fetchGaugeData';
import fetchAGOLayers from './mapLayers/fetchAGOLayers';
import syncUrlToRedux from './syncUrlToRedux';
import syncReduxToUrl from './syncReduxToUrl';
import fetchUsers from './users/fetchUsers';
import updateUser from './users/updateUser';
import createSubscription from './subscriptions/createSubscription';
import fetchNotifications from './notifications/fetchNotifications';
import advanceFrame from './radar/advance-frame';
import loadTimes from './radar/load-times';
import advanceFrameOGC from './radar-ogc/advance-frame';
import loadTimesOGC from './radar-ogc/load-times';
import swapLayers from './radar-ogc/swap-layers';
import markNotificationAsRead from './notifications/markNotificationAsRead';
import fetchGaugeForecast from './forecast/fetchGaugeForecast';
import fetchForecastList from './forecastList/fetchForecastList';
import refreshForecastList from './forecastList/refreshForecastList';
import fetchRainfallStatus from './rainfallStatus/fetchRainfallStatus';
import updateGaugeFilters from './updateGaugeFilters';
import fetchSubscriptions from './subscriptions/fetchSubscriptions';
import deleteSubscription from './subscriptions/deleteSubscription';
import fetchForecastIconData from './forecast/fetchForecastIconData';
import fetchMRMSRainfall from './mrms/fetchMRMSRainfall';
import fetchRainIncrement from './rainIncrement/fetchRainIncrement';
import maybeRunIconStuff from './maybeRunIconStuff';
import getAdminForecasts from './admin/getAdminForecasts';
import getAdminForecastEnsembles from './admin/getAdminForecastEnsembles';
import setEnsembleDisable from './admin/setEnsembleDisable';
import getAdminGauges from './admin/getAdminGauges';
import updateGaugeDescription from './admin/updateGaugeDescription';
import toggleGaugeDisable from './admin/toggleGaugeDisable';
import deleteForecast from './forecast/deleteForecast';
import getGaugeDescription from './getGaugeDescription';

const sagas = [
  mapSetup,
  getCurrentUser,
  fetchGaugeData,
  fetchAGOLayers,
  syncUrlToRedux,
  syncReduxToUrl,
  fetchUsers,
  updateUser,
  createSubscription,
  fetchNotifications,
  advanceFrame,
  loadTimes,
  markNotificationAsRead,
  fetchGaugeForecast,
  fetchForecastList,
  refreshForecastList,
  advanceFrameOGC,
  loadTimesOGC,
  swapLayers,
  fetchRainfallStatus,
  updateGaugeFilters,
  fetchSubscriptions,
  deleteSubscription,
  fetchForecastIconData,
  fetchMRMSRainfall,
  fetchRainIncrement,
  maybeRunIconStuff,
  getAdminForecasts,
  getAdminForecastEnsembles,
  setEnsembleDisable,
  getAdminGauges,
  deleteForecast,
  toggleGaugeDisable,
  updateGaugeDescription,
  getGaugeDescription,
];

function* globalSagas() {
  const globalSagasForks = sagas.map((saga) => fork(saga));

  yield all([...globalSagasForks]);
}

export default globalSagas;
