import { takeLatest, put } from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { FETCH_NOTIFICATIONS, setNotifications } from '../../reducers/notifications';
// import loading from '../../utils/loading';

function* fetchNotifications() {
  // const loadingMsg = 'Fetching Notifications...';
  // loading.start(loadingMsg);
  try {
    const response = yield fetch('/api/notifications');
    if (response.status > 299) throw new Error('Error fetching notifications');
    const notifications = yield response.json();
    // ! notifications is object of personal and global notifications
    // const enabledNotifications = notifications.filter(({ disable_calc, disable_all }) => (disable_calc === false && disable_all === false));
    yield put(setNotifications(notifications));
  } catch (e) {
    console.log('ERROR fetching notifications', e);
    yield put(setError({ errorId: 'fetchNotificationsError', value: e.message || true }));
  } finally {
    // loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(FETCH_NOTIFICATIONS, fetchNotifications);
}
