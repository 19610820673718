// action type constants
export const SET_GRAPH_TIME_SERIES = 'SET_GRAPH_TIME_SERIES';

// actionCreators
export const setGraphTimeSeries = (timeSeries) => ({ type: SET_GRAPH_TIME_SERIES, timeSeries });

const initialState = [];

// reducer
export default (state = initialState, { type, timeSeries }) => {
  switch (type) {
    case SET_GRAPH_TIME_SERIES:
      return timeSeries;
    default:
      return state;
  }
};
