// action type constants
export const SET_GAUGE_FILTER = 'SET_GAUGE_FILTER';
export const SET_GAUGE_FILTER_STATE = 'SET_GAUGE_FILTER_STATE';

// actionCreators
export const setGaugeFilters = ({ key, value }) => ({ type: SET_GAUGE_FILTER, key, value });
export const setGaugeFilterState = ({ key, value }) => ({ type: SET_GAUGE_FILTER_STATE, key, value });

const initialState = {
  normalGauges: true,
  nearFlooding: true,
  flooding: true,
  forecastedNearFlooding: true,
  forecastedFlooding: true,
};


// reducer
export default (state = initialState, { type, key, value }) => {
  switch (type) {
    case SET_GAUGE_FILTER_STATE:
      state[key] = value;
      return state;
    default:
      return state;
  }
};
