// action type constants
export const FETCH_RAINFALL_STATUS = 'FETCH_RAINFALL_STATUS';
export const SET_RAINFALL_STATUS = 'SET_RAINFALL_STATUS';

// actionCreators
export const fetchRainfallStatus = ({ data_start }) => ({ type: FETCH_RAINFALL_STATUS, data_start });
export const setRainfallStatus = (data) => ({ type: SET_RAINFALL_STATUS, data });

const initialState = false;

// reducer
export default (state = initialState, { type, data }) => {
  switch (type) {
    case SET_RAINFALL_STATUS:
      return data;
    default:
      return state;
  }
};
