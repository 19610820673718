import { takeLatest } from 'redux-saga/effects';
import { SET_ENSEMBLE_DISABLE } from '../../reducers/adminForecastEnsembles';

function* SetEnsembleDisable({ payload }) {
  try {
    const { name, disable } = payload;
    const resp = yield fetch(`/api/admin/ensemble/${name}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ disable }),
    });
    if (resp.status > 299) throw new Error('Invalid Response Code');
  } catch (e) {
    console.log('ERROR toggling ensemble disable', e);
    // handle error
  }
}

export default function* watch() {
  yield takeLatest(SET_ENSEMBLE_DISABLE, SetEnsembleDisable);
}
