import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MapPage from './MapPage/MapPage';
import DashboardPage from './DashboardPage/DashboardPage';
import AdminPage from './AdminPage/AdminPage';

const MainContentRouter = () => (
  <Switch>
    <Route path="/admin"><AdminPage /></Route>
    <Route path="/map"><MapPage /></Route>
    <Route path="/dashboard/:gaugeId"><DashboardPage /></Route>
    <Redirect to="/map" />
  </Switch>
);

export default MainContentRouter;
