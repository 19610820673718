import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  contentWrapper: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  helperText: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: '0 5px',
  },
});

const NoAccess = ({ message, login }) => {
  const classes = useStyles();
  return (
    <div className={classes.contentWrapper}>
      <p className={classes.helperText}>{message}</p>
      {login && <a href="/login" className={classes.helperText}>Login</a>}
    </div>
  );
};

export default NoAccess;
