// action type constants
export const FETCH_RAIN_INCREMENT = 'FETCH_RAIN_INCREMENT';
export const SET_RAIN_INCREMENT = 'SET_RAIN_INCREMENT';
export const SET_SELECTED = 'SET_SELECTED';

// actionCreators
export const fetchRainIncrement = ({ gauge_id, start, end, selected }) => ({ type: FETCH_RAIN_INCREMENT, gauge_id, start, end, selected });
export const setRainIncrement = (payload) => ({ type: SET_RAIN_INCREMENT, payload });
export const setSelectedRainfallSource = (payload) => ({ type: SET_SELECTED, payload });

const initialState = {
  selected: 'forecast',
  data: [],
};

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_RAIN_INCREMENT:
      state.data = payload;
      return state;
    case SET_SELECTED:
      state.selected = payload;
      return state;
    default:
      return state;
  }
};
