import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import { makeStyles } from '@material-ui/core/styles';

import { plotLineColorsArray } from '../../../config/constants';
import ResetZoomButton from '../ResetZoomButton';
import generateRainAccumulationYValues from './generateRainAccumulationYValues';
import RainfallLookbackDropdown from './RainfallLookbackDropdown';
import { createDispatchBindings } from '../../../utils/redux';
import clampTimeline from '../../../utils/clampTimeline';
import RainfallSourceDropdown from './RainfallSourceDropdown';
import { convertToLocal } from '../../../utils/convertTime';

Chart.register(zoomPlugin);

const useStyles = makeStyles({
  chartWrapper: {
    width: '100%',
    height: '100%',
    paddingTop: 15,
  },
});

const chartConfig = {
  type: 'line',
  data: {},
  options: {
    // prevents animations
    animation: {
      duration: 0,
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    },
    // prevents canvas height changes with width changes
    maintainAspectRatio: false,
    // remove points on lines
    elements: {
      point: {
        radius: 0, // looks good with 1
      },
    },
    // axis options
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            millisecond: 'MM/DD/YYYY HH:mm',
            second: 'MM/DD/YYYY HH:mm',
            minute: 'MM/DD/YYYY HH:mm',
            hour: 'MM/DD/YYYY HH:mm',
            day: 'MM/DD/YYYY HH:mm',
            week: 'MM/DD/YYYY HH:mm',
            month: 'MM/DD/YYYY HH:mm',
            quarter: 'MM/DD/YYYY HH:mm',
            year: 'MM/DD/YYYY HH:mm',
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Incremenet (in/hr)',
        },
        reverse: true,
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          callback: (value) => Number(value).toFixed(2),
          beginAtZero: true,
          stepSize: 0.01,
          maxTicksLimit: 5,
        },
      },
      y1: {
        title: {
          display: true,
          text: 'Accumulation (in)',
        },
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false, // hide gridlines for secondary y-axis
        },
        ticks: {
          callback: (value) => Number(value).toFixed(2),
          maxTicksLimit: 5,
        },
      },
    },
    plugins: {
      autocolors: false,
      legend: {
        labels: {
          filter: (item) => !item.text.includes('remove'),
        },
      },
      zoom: {
        zoom: {
          drag: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  },
};

let chartData;
export const getRainfallChartData = () => chartData;
const dataKeys = {
  forecast: ['ReadingDate', 'Value'],
  contrail: ['data_time', 'data_value'],
  mrms: ['time', 'value'],
};

const RainfallPlot = ({ rainIncrement, modal, dateRange, rainfallLookbackPeriod, gaugeForecast, selectedGaugeData }) => {
  const classes = useStyles();
  const chartContainer = useRef(null);
  const [ready, setReady] = useState(false);
  const [rainfallChart, setRainfallChart] = useState();

  useEffect(() => {
    if ((!rainIncrement.data?.length && rainIncrement.selected !== 'contrail') || !rainfallChart) return;
    const { start, end } = dateRange;
    const blankTimeline = [{ x: moment(start).format(), y: null }, { x: moment(end).format(), y: null }];
    const rainfallData = rainIncrement.selected === 'contrail' ? selectedGaugeData[10] : rainIncrement.data;


    let rainSeries = [];
    const clampedRainfallData = clampTimeline({ data: rainfallData, dateKey: dataKeys[rainIncrement.selected][0], start, end });

    for (let i = 0; i < clampedRainfallData.length; i += 1) {
      const [dateKey, valueKey] = dataKeys[rainIncrement.selected];
      rainSeries.push({ x: moment(clampedRainfallData[i][dateKey]).format(), y: clampedRainfallData[i][valueKey] });
    }

    rainSeries.sort((a, b) => moment(a.x).diff(moment(b.x)));
    if (typeof rainfallLookbackPeriod === 'number') {
      rainSeries = rainSeries.filter((point) => point.x > moment(rainSeries[rainSeries.length - 1].x).subtract(rainfallLookbackPeriod, 'hours').format());
    }


    // Create chart series
    const chartSeries = [];
    chartSeries.push({
      data: blankTimeline,
      label: 'remove',
    });
    if (rainSeries.length) {
      chartSeries.push({
        type: 'bar',
        barPercentage: 1,
        categoryPercentage: 1.0,
        label: 'Increment',
        data: rainSeries,
        borderColor: plotLineColorsArray.rainfall[0],
        backgroundColor: plotLineColorsArray.rainfall[0],
        borderWidth: 1,
        yAxisID: 'y',
      });
      chartSeries.push({
        type: 'line',
        label: 'Accumulation',
        data: generateRainAccumulationYValues(rainSeries),
        borderColor: plotLineColorsArray.rainfall[1],
        backgroundColor: plotLineColorsArray.rainfall[1],
        borderWidth: 1,
        yAxisID: 'y1',
      });
    }

    // CHART OPTIONS
    // Add forecast start x-axis line annotation
    if (gaugeForecast.length) {
      const forecastStartDate = moment(convertToLocal(gaugeForecast[0].run_time)).format();
      rainfallChart.options.plugins.annotation.annotations.line3 = {
        type: 'line',
        xMin: forecastStartDate,
        xMax: forecastStartDate,
        borderColor: '#000',
        borderWidth: 1,
        borderDash: [4, 4],
      };
      rainfallChart.update();
    }

    // SET PLOT DATA
    if (chartSeries?.length && ready && rainSeries.length) {
      rainfallChart.data.datasets = chartSeries;
      rainfallChart.update();
      chartData = chartSeries;
    }
  }, [rainIncrement.data, ready, modal.open, rainfallLookbackPeriod, gaugeForecast, dateRange]);

  useEffect(() => {
    if (chartContainer && chartContainer.current && !rainfallChart) {
      const chartInstance = new Chart(chartContainer.current, chartConfig);
      setRainfallChart(chartInstance);
      setReady(true);
    }
  }, [chartContainer]);

  return (
    <div className={classes.chartWrapper}>
      <RainfallSourceDropdown />
      <RainfallLookbackDropdown />
      <ResetZoomButton chartRef={rainfallChart} />
      <canvas id="stage-chart" ref={chartContainer} width="100%" />
    </div>
  );
};

const mapStateToProps = ({ modal, dateRange, rainfallLookbackPeriod, gaugeForecast, rainIncrement, selectedGaugeData }) => ({ modal, dateRange, rainfallLookbackPeriod, gaugeForecast, rainIncrement, selectedGaugeData });
const mapDispatchToProps = createDispatchBindings({ });

export default connect(mapStateToProps, mapDispatchToProps)(RainfallPlot);
