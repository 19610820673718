const generateRainAccumulationYValues = (rainIncrementYValues) => {
  let total = 0;
  const rainAccumulationYValues = [];
  rainIncrementYValues.forEach(({ x, y }) => {
    total += Number(y);
    rainAccumulationYValues.push({ x, y: total });
  });
  return rainAccumulationYValues;
};

export default generateRainAccumulationYValues;
