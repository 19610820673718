/* eslint-disable import/no-cycle */
import L from 'leaflet';
import moment from 'moment';
import reduxStore from '../../store';

import { gaugeStatusColors } from '../../config/constants';

export const unselectedFlowMarker = ({ iconColor, iconText, forecastCircleColor }) => {
  if (forecastCircleColor) {
    // generate marker with colored outer forecast circle
    return L.divIcon({
      className: 'gauge-icon',
      iconSize: null,
      html: `
      <div
        class="forecast-circle"
        style="
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid black;
          background-color: ${forecastCircleColor};
          display: flex;
          justify-content: center;
          position: relative;
          align-items: center;"
      >
        <div
          class="icon"
          style="
            text-align: center;
            font-weight: bold;
            background: #444;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: 1;
            left: 1;
            background-color: ${iconColor};
            border: 1px solid black;"
        >
          <p style="
            margin-top: -3px;"
          >${iconText}</p>
        </div>
      </div>`,
    });
  }
  // generate marker without forecast outer circle
  return L.divIcon({
    className: 'gauge-icon',
    iconSize: null,
    html: `
    <div
      class="icon"
      style="
        text-align: center;
        font-weight: bold;
        background: #444;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: ${iconColor};
        border: 1px solid black;"
    >
      <p style="
        margin-top: -3px;"
      >${iconText}</p>
    </div>`,
  });
};

export const selectedFlowMarker = ({ iconColor, iconText, forecastCircleColor }) => {
  if (forecastCircleColor) {
    // generate marker with colored outer forecast circle
    return L.divIcon({
      className: 'gauge-icon',
      iconSize: null,
      html: `
      <div
        class="forecast-circle"
        style="
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid black;
          background-color: ${forecastCircleColor};
          box-shadow: 0px 0px 0px 3px #00a2ff;
          display: flex;
          justify-content: center;
          align-items: center;"
      >
        <div
          class="icon"
          style="
            text-align: center;
            font-weight: bold;
            background: #444;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: ${iconColor};
            border: 1px solid black;"
        >
          <p style="
            margin-top: -3px;"
          >${iconText}</p>
        </div>
      </div>`,
    });
  }
  return L.divIcon({
    className: 'gauge-icon',
    iconSize: null,
    html: `
    <div
      class="icon"
      style="
        text-align: center;
        font-weight: bold;
        background: #444;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: ${iconColor};
        box-shadow: 0px 0px 0px 3px #00a2ff;
        border: 1px solid black;"
    >
      <p style="
        margin-top: -3px;"
      >${iconText}</p>
    </div>`,
  });
};

export const getFlowMarkerStyling = (site_id, forecastIconData, gaugeThresholds, { start, end }) => {
  const currentGaugeFlowStatus = forecastIconData.find((site) => Number(site.gauge_id) === Number(site_id));
  if (currentGaugeFlowStatus?.disable_all) return { iconText: undefined, iconColor: undefined, forecastCircle: undefined };
  const currentGaugeThresholds = gaugeThresholds.data.find((site) => Number(site.gauge_id) === Number(site_id));
  if (!currentGaugeFlowStatus) return ({ iconColor: gaugeStatusColors.noData, iconText: '' });
  // MARKER ICON
  const iconText = '';
  // MARKER COLOR
  let iconColor = gaugeStatusColors.noData;
  let forecastCircleColor;
  const { current_stage, current_stage_time, max_forecast_stage } = currentGaugeFlowStatus;
  //const currentStageDate = moment(current_stage_time);
  //const startDate = moment(start);
  //const endDate = moment(end);
  let currentStageNumber = false;
  //if (currentStageDate.isSameOrAfter(startDate) && currentStageDate.isSameOrBefore(endDate)) currentStageNumber = Number(current_stage);
   currentStageNumber = Number(current_stage);
  const maxStageNumber = Number(max_forecast_stage);
  if (currentStageNumber && Number(currentGaugeThresholds?.el_fld_act) !== -999 && Number(currentGaugeThresholds?.el_chtop) !== -999) {
    if (currentStageNumber < Number(currentGaugeThresholds?.el_fld_act)) iconColor = gaugeStatusColors.normal;
    else if (currentStageNumber < Number(currentGaugeThresholds?.el_chtop)) iconColor = gaugeStatusColors.nearFlooding;
    else if (currentStageNumber >= Number(currentGaugeThresholds?.el_chtop)) iconColor = gaugeStatusColors.flooding;
    else if (!currentStageNumber) iconColor = gaugeStatusColors.noData;
    else iconColor = gaugeStatusColors.normal;
  } else {
    iconColor = gaugeStatusColors.noData;
  }
  // FORECAST CIRCLE
  if (maxStageNumber && Number(currentGaugeThresholds?.el_fld_act) !== -999 && Number(currentGaugeThresholds?.el_chtop) !== -999) {
    if (maxStageNumber < Number(currentGaugeThresholds?.el_fld_act)) forecastCircleColor = gaugeStatusColors.normal;
    else if (maxStageNumber < Number(currentGaugeThresholds?.el_chtop)) forecastCircleColor = gaugeStatusColors.nearFlooding;
    else if (maxStageNumber >= Number(currentGaugeThresholds?.el_chtop)) forecastCircleColor = gaugeStatusColors.flooding;
  }
  return { iconText, iconColor, forecastCircleColor };
};

export const getFilteredGauges = async (gaugesGeoJSON, gaugeFilters, options) => {
  console.log(gaugesGeoJSON);
  const { watershed, type } = options;
  const { forecastIconData, gaugeThresholds, dateRange } = await reduxStore.getState();
  const statusFilteredFeatures = [];
  for (let i = 0; i < gaugesGeoJSON.features.length; i += 1) {
    const site_id = gaugesGeoJSON.features[i]?.properties?.site_id;
    if (site_id) {
      const { iconColor, forecastCircleColor } = getFlowMarkerStyling(site_id, forecastIconData, gaugeThresholds, dateRange);
      if (iconColor === undefined && forecastCircleColor === undefined) continue;
      if (gaugeFilters.normalGauges === true && (iconColor === 'black' || iconColor === gaugeStatusColors.normal || iconColor === gaugeStatusColors.noData) && (forecastCircleColor === gaugeStatusColors.normal || forecastCircleColor === undefined)) {
        statusFilteredFeatures.push(gaugesGeoJSON.features[i]);
        continue;
      }
      if (gaugeFilters.nearFlooding === true && iconColor === gaugeStatusColors.nearFlooding) {
        statusFilteredFeatures.push(gaugesGeoJSON.features[i]);
        continue;
      }
      if (gaugeFilters.flooding === true && iconColor === gaugeStatusColors.flooding) {
        statusFilteredFeatures.push(gaugesGeoJSON.features[i]);
        continue;
      }
      if (gaugeFilters.forecastedNearFlooding === true && forecastCircleColor === gaugeStatusColors.nearFlooding) {
        statusFilteredFeatures.push(gaugesGeoJSON.features[i]);
      }
      if (gaugeFilters.forecastedFlooding === true && forecastCircleColor === gaugeStatusColors.flooding) {
        statusFilteredFeatures.push(gaugesGeoJSON.features[i]);
      }
    }
  }
 
   

  let optionsFilteredGauges = [...statusFilteredFeatures];
  
  if (type) {
    if (type === 'rain') {
      optionsFilteredGauges = statusFilteredFeatures.filter(({ properties }) => properties.ff_rain === 'Yes');
    } else if (type === 'flow') {
      optionsFilteredGauges = statusFilteredFeatures.filter(({ properties }) => properties.ff_stage === 'Yes');
    }
  }
  return { ...gaugesGeoJSON, features: optionsFilteredGauges};
  
};
