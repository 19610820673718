import React, { useState, useEffect } from 'react';
import { Route, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import history from '../../history';
import { layers } from '../../utils/map/layers';
import Show from '../HOC/Show';
import SiteDetails from './SiteDetails';
import DashboardItemWrapper from './DashboardItemWrapper';
import SummaryTable from './SummaryTable';
import LeafletMap from '../LeafletMap';
import NoData from '../NoData';
import { createDispatchBindings } from '../../utils/redux';
import { fetchGaugeData } from '../../reducers/selectedGaugeData';
import { fetchAGOLayers } from '../../reducers/mapLayers';
import config from '../../config';

const useStyles = makeStyles({
  dashboardWrapper: {
    padding: '10px',
    overflowY: 'scroll',
    height: `calc(100vh - ${config.headerHeight}px)`,
  },
  dashboardItem: {
    height: '100%',
  },
  mapButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    zIndex: 1500,
  },
  rainfallPlotWrapper: {
    height: '100%',
  },
  leftColumnItem: {
    height: '100%',
  },
  leftColumnContainer: {
    height: '100%',
  },
  datePickerWrapper: {
    height: config.datePickerHeight,
  },
  stagePlotGrid: {
    flexGrow: 1,
    marginTop: 8,
  },
  rainfallPlotGrid: {
    marginTop: 8,
    flexGrow: 1,
  },
  summaryTableGrid: {
    height: 190,
    overflow: 'scroll'
  },
  rainfallSummaryTableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '45%',
  },
  dashboardLeftWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  // DASHBOARD RIGHT
  rightWrapper: {
    height: '100%',
  },
  dashboardRightWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${config.DashboardMapHeight}px)`,
  },
  siteDetailsContainer: {
    height: '35%',
    padding: 0,
  },
  siteDetailsWrapper: {
    padding: 0,
  },
  // MAP
  mapContainer: {
    height: '62%',
    marginTop: 8,
    position: 'relative',
  },
});

const DashboardPage = ({ fetchGaugeData, fetchAGOLayers, selectedGaugeData, gauges, gaugeThresholds, modal }) => {
  const classes = useStyles();
  const { gaugeId } = useParams();
  const [currentGauge, setCurrentGauge] = useState();

  useEffect(() => {
    if (!layers.flowIcons && !layers.rainIcons) {
      fetchAGOLayers();
    }
  }, []);

  useEffect(() => {
    if (gauges.features) {
      const selectedGage = gauges.features.find((gauge) => gauge.properties.site_id === parseInt(gaugeId, 10));
      setCurrentGauge(selectedGage);
    }
  }, [gaugeId, gauges.features]);

  useEffect(() => {
    if (gaugeId && selectedGaugeData.site_id !== gaugeId) {
      fetchGaugeData(gaugeId);
    }
  }, [gaugeId]);

  return (
    <Grid container spacing={1} className={classes.dashboardWrapper}>
      <Route exact path="/dashboard/:gaugeId">
        <Grid item xs={12} md={6} className={classes.leftColumnItem}>
          <Grid container spacing={1} className={classes.leftColumnContainer}>
            <Show test={selectedGaugeData}>
              <Grid item xs={12} className={classes.dashboardLeftWrapper}>
                <div className={classes.rainfallSummaryTableWrapper}>
                  <div className={classes.summaryTableGrid}>
                    <DashboardItemWrapper title="Summary Table" className={`${classes.dashboardItem} ${classes.summaryTableWrapper}`}>
                      <Show test={selectedGaugeData['10'] || selectedGaugeData['20']}>
                        <SummaryTable />
                      </Show>
                      <Show test={!(selectedGaugeData['10'] || selectedGaugeData['20'])}>
                        <NoData />
                      </Show>
                    </DashboardItemWrapper>
                  </div>
                  <div className={classes.rainfallPlotGrid}>
                    <DashboardItemWrapper
                      expandable
                      title="Rainfall Plot"
                      graphName="rainfallPlot"
                      className={classes.rainfallPlotWrapper}
                      gaugeId={gaugeId}
                      selectedGaugeData={selectedGaugeData}
                      gaugeThresholds={gaugeThresholds}
                    />
                  </div>
                </div>
                <div className={classes.stagePlotGrid}>
                  <DashboardItemWrapper
                    expandable
                    title="Stage/Flow Plot"
                    graphName="stageFlowPlot"
                    className={`${classes.dashboardItem} ${classes.stageFlowPlotWrapper}`}
                    gaugeId={gaugeId}
                    selectedGaugeData={selectedGaugeData}
                    gaugeThresholds={gaugeThresholds}
                  />
                </div>
              </Grid>
            </Show>
            <Show test={!selectedGaugeData}>
              <NoData />
            </Show>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rightWrapper}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.dashboardRightWrapper}>
              <div className={classes.siteDetailsContainer}>
                <DashboardItemWrapper title="Site Details/Notes" className={`${classes.dashboardItem} ${classes.siteDetailsWrapper}`}>
                  <SiteDetails currentGauge={currentGauge} thresholds={gaugeThresholds.data} />
                </DashboardItemWrapper>
              </div>
              <div className={classes.mapContainer}>
                <LeafletMap />
                <Show test={!modal.open}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => history.push({
                      pathname: `/map/${gaugeId}`,
                      search: history.location.search,
                    })}
                    className={classes.mapButton}
                  >
                    Go to Map
                  </Button>
                </Show>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Route>
    </Grid>
  );
};


const mapStateToProps = ({ selectedGaugeData, gaugeThresholds, gauges, mapRefs: { mapDiv }, modal, chartRefs: { stageFlowChart } }) => ({ selectedGaugeData, gaugeThresholds, gauges, mapDiv, modal, stageFlowChart });
const mapDispatchToProps = createDispatchBindings({ fetchGaugeData, fetchAGOLayers });

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
