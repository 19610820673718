import { takeLatest, put } from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { MARK_NOTIFICATION_AS_READ, fetchNotifications } from '../../reducers/notifications';
import loading from '../../utils/loading';

function* markNotificationAsRead({ gauge_id, notification_id, subscription_id }) {
  const loadingMsg = 'Marking Notification as Read...';
  loading.start(loadingMsg);
  try {
    let queryString = '?';
    if (notification_id) queryString += `notification_id=${notification_id}&`;
    if (subscription_id) queryString += `subscription_id=${subscription_id}&`;
    if (gauge_id) queryString += `gauge_id=${gauge_id}`;
    const response = yield fetch(`/api/notifications/markRead${queryString}`, {
      method: 'POST',
    });
    if (response.status > 299) throw new Error('Error fetching notifications');
    yield put(fetchNotifications());
  } catch (e) {
    console.log('ERROR marking notification as read', e);
    yield put(setError({ errorId: 'markNotificationAsReadError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(MARK_NOTIFICATION_AS_READ, markNotificationAsRead);
}
