// action type constants
export const GET_MRMS_LAYER_ACTIVE = 'GET_MRMS_LAYER_ACTIVE';
export const SET_MRMS_LAYER_ACTIVE = 'SET_MRMS_LAYER_ACTIVE';

// actionCreators
export const getMrmsLayerActive = () => ({ type: GET_MRMS_LAYER_ACTIVE });
export const setMrmsLayerActive = (payload) => ({ type: SET_MRMS_LAYER_ACTIVE, payload });

const initialState = {
  active: false,
};

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MRMS_LAYER_ACTIVE:
      state.active = payload;
      return state;
    default:
      return state;
  }
};
