// action type constants
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// actionCreators
export const fetchNotifications = () => ({ type: FETCH_NOTIFICATIONS });
export const setNotifications = (notifications) => ({ type: SET_NOTIFICATIONS, notifications });
export const markNotificationAsRead = ({ gauge_id, notification_id, subscription_id }) => ({ type: MARK_NOTIFICATION_AS_READ, gauge_id, notification_id, subscription_id });

const initialState = {};

// reducer
export default (state = initialState, { type, notifications }) => {
  switch (type) {
    case SET_NOTIFICATIONS:
      return notifications;
    default:
      return state;
  }
};
