import { takeLatest, put } from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { GET_TIMES, setTimes } from '../../reducers/radar';

import loading from '../../utils/loading';

function* fetchTime() {
  const loadingMsg = 'Fetching NowCoast Time Data...';
  loading.start(loadingMsg);
  try {
    const resp = yield fetch('https://nowcoast.noaa.gov/layerinfo?request=timestops&service=radar_meteo_imagery_nexrad_time&layers=3&format=json');
    if (resp.status >= 400) {
      throw new Error('Error loading time from nowcoast');
    }
    const { layers: [{ timeStops }] } = yield resp.json();
    const hourAgo = Date.now() - (1000 * 60 * 60);
    yield put(setTimes(timeStops.filter((time) => time > hourAgo)));
  } catch (e) {
    console.log('ERROR fetching now coast time data', e);
    yield put(setError({ errorId: 'getTimeStopsError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(GET_TIMES, fetchTime);
}
