import L from 'leaflet';
import tileBelt from '@mapbox/tilebelt';
// https://inundation.hcfcd.org/api/Layer/Exists/201709011200
// https://inundation.hcfcd.org/api/Tiles/201911010630/0231310221
const InundationLayer = L.TileLayer.extend({
  initialize(time, options) {
    L.TileLayer.prototype.initialize.call(this, options);
    this.time = time;
  },
  getTileUrl(coords) {
    return `https://inundation.hcfcd.org/api/Tiles/${this.time}/${this.createCoord(coords)}`;
  },
  createCoord({ x, y, z }) {
    return tileBelt.tileToQuadkey([x, y, z]);
  },
  setTime(time, noRedraw) {
    if (this.time === time && noRedraw === undefined) {
      return this;
    }

    this.time = time;

    if (!noRedraw) {
      this.redraw();
    }
    return this;
  },
});

L.InundationLayer = InundationLayer;
