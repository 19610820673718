import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import DateInput from './DateInput';

import { createDispatchBindings } from '../../utils/redux';

import { fetchRainfallStatus } from '../../reducers/rainfallStatus';
import { fetchForecastIconData } from '../../reducers/forecastIconData';

import { fetchGaugeForecast } from '../../reducers/gaugeForecast';


const useStyles = makeStyles({
  datePickerWrapper: {
    display: 'flex',
    padding: '10px 0',
    alignItems: 'center',
  },
  layerSectionTitle: {
    margin: 0,
    marginLeft: 10,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  refreshButton: {
    height: 32,
    margin: '0 10px',
  },
});

const CustomDateSection = ({selectedGaugeData, mapLayers, dateRange, fetchRainfallStatus,hiddenForecastModels,fetchForecastIconData,fetchGaugeForecast }) => {
  const classes = useStyles();
  const { site_id } = selectedGaugeData;
  const [iconType, setIconType] = useState();

  useEffect(() => {
    if (mapLayers?.gaugeIconType) setIconType(mapLayers?.gaugeIconType);
  }, [mapLayers?.gaugeIconType]);

  const refreshData = () => {
    if (site_id) 
    {
      fetchForecastIconData(hiddenForecastModels.selectedForecastId);
      fetchGaugeForecast({ forecast_id: hiddenForecastModels.selectedForecastId, gauge_id: site_id });     
    }
    if (iconType === 'rain') fetchRainfallStatus({ data_start: dateRange.start });
  };

  return (
    <div className={classes.datePickerWrapper}>
      <DateInput title="Start Date" id="start" />
      <DateInput title="End Date" id="end" />
      <Button
        variant="contained"
        color="primary"
        onClick={refreshData}
        className={classes.refreshButton}
      >
        Refresh Data
      </Button>
    </div>
  );
};

const mapStateToProps = ({ selectedGaugeData, mapLayers, dateRange,hiddenForecastModels }) => ({ selectedGaugeData, mapLayers, dateRange,hiddenForecastModels });
const mapDispatchToProps = createDispatchBindings({fetchRainfallStatus,fetchForecastIconData,fetchGaugeForecast });

export default connect(mapStateToProps, mapDispatchToProps)(CustomDateSection);
