import { takeLatest, put } from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { CREATE_SUBSCRIPTION } from '../../reducers/subscriptions';

import loading from '../../utils/loading';

function* createSubscription({ gauge_id, threshold, name }) {
  const loadingMsg = 'Creating Subscription...';
  loading.start(loadingMsg);
  try {
    const response = yield fetch(`/api/subscription/${gauge_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ threshold, name }),
    });
    if (response.status > 299) throw new Error('Error creating subscription');
  } catch (e) {
    console.log('ERROR creating subscription', e);
    yield put(setError({ errorId: 'createSubscriptionError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(CREATE_SUBSCRIPTION, createSubscription);
}
