import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Checkbox from '@material-ui/core/Checkbox';

import Show from '../HOC/Show';
import StageFlowPlot from '../Graphs/StageFlowPlot/StageFlowPlot';
import RainfallPlot from '../Graphs/RainfallPlot/RainfallPlot';
import ExportCSVButton from '../Graphs/ExportCSVButton';

import { EXPANDED_ITEM_MODAL, thresholdConfig } from '../../config/constants';
import { openModal } from '../../reducers/modal';
import { createDispatchBindings } from '../../utils/redux';
import { updateToggleState } from '../../reducers/gaugeThresholds';

const useStyles = makeStyles({
  childrenWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  summaryTableWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  expandButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
  },
  chartTitle: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 4,
    marginLeft: 10,
    padding: 0,
    fontSize: 12,
  },
  thresholdToggles: {
    position: 'absolute',
    top: 3,
    left: 120,
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: 10,
    borderRadius: 5,
    background: '#efefef',
    height: 18,
  },
  checkboxInputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    margin: 0,
    fontSize: 12,
  },
  exportButtonWrapper: {
    position: 'absolute',
    top: 0,
    right: 50,
  },
  graphWrapper: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  timezone: {
    position: 'absolute',
    margin: 5,
    padding: 0,
    top: 0,
    left: 290,
    fontSize: 12,
  },
});

const DashboardItemWrapper = ({ title, openModal, children, graphName, className, isMapWrapper, expandable, gaugeId, selectedGaugeData, gaugeThresholds, updateToggleState }) => {
  const classes = useStyles();

  const handleExpandingItem = () => {
    openModal({
      type: EXPANDED_ITEM_MODAL,
      data: {
        title,
        graphName,
        gaugeId,
      },
      options: {
        fullScreen: true,
      },
    });
  };

  return (
    <Paper className={className}>
      <div className={title === 'Summary Table' ? classes.summaryTableWrapper : classes.childrenWrapper}>
        <Show test={!isMapWrapper && expandable}>
          <IconButton onClick={handleExpandingItem} className={classes.expandButton}>
            <ZoomOutMapIcon />
          </IconButton>
        </Show>
        {graphName === 'stageFlowPlot' && (
          <div className={classes.graphWrapper}>
            <h3 key="title" className={classes.chartTitle}>Stage/Flow Plot</h3>
            <div className={classes.thresholdToggles}>
              {gaugeThresholds?.toggleState && Object.entries(gaugeThresholds.toggleState).map(([key, value]) => (
                <div key={key} className={classes.checkboxInputWrapper}>
                  <Checkbox
                    checked={value}
                    size="small"
                    onChange={(e) => updateToggleState({ key, value: e.target.checked })}
                    style={{ color: thresholdConfig[key].color, height: 4, width: 4, margin: '0 5px' }}
                  />
                  <p className={classes.checkboxLabel}>{thresholdConfig[key].label}</p>
                </div>
              ))}
            </div>
            <p className={classes.timezone}>America/El_Salvador time</p>
            <StageFlowPlot
              gauge_id={gaugeId}
              stageData={selectedGaugeData['20']}
              flowData={selectedGaugeData['25']}
              thresholds={gaugeThresholds.data}
              thresholdToggleState={gaugeThresholds.toggleState}
            />
            <div className={classes.exportButtonWrapper}>
              <ExportCSVButton location="dashboard" graphType="stageFlow" />
            </div>
          </div>
        )}
        {graphName === 'rainfallPlot' && (
          <div className={classes.graphWrapper}>
            <h3 className={classes.chartTitle}>Rainfall Plot</h3>
            <RainfallPlot />
            <div className={classes.exportButtonWrapper}>
              <ExportCSVButton location="dashboard" graphType="rainfall" />
            </div>
          </div>
        )}
        {graphName === undefined && (
          children
        )}
      </div>
    </Paper>
  );
};

const mapDispatchToProps = createDispatchBindings({ openModal, updateToggleState });

export default connect(null, mapDispatchToProps)(DashboardItemWrapper);
