// modal constants
export const EXPANDED_ITEM_MODAL = 'EXPANDED_ITEM_MODAL';
export const DELETE_USER_MODAL = 'DELETE_USER_MODAL';
export const EDIT_USER_MODAL = 'EDIT_USER_MODAL';
export const GAUGE_SUB_MODAL = 'GAUGE_SUB_MODAL';
export const SUB_MANAGEMENT_MODAL = 'SUB_MANAGEMENT_MODAL';
export const DELETE_FORECAST_MODAL = 'DELETE_FORECAST_MODAL';

// layer constants
export const layerToggleList = [
  {
    title: 'Inundation',
    layerName: 'inundationLayer',
  },
  {
    title: 'Gauges',
    layerName: 'gaugeIcons',
  },
  {
    title: 'Channels',
    layerName: 'AGOChannels',
  },
  {
    title: 'Addicks Barker Fedlands',
    layerName: 'AGOAddicksBarkerFedLands',
  },
  {
    title: 'Catchments',
    layerName: 'AGOCatchments',
  },
  {
    title: 'Watersheds',
    layerName: 'AGOWatersheds',
  },
];
export const initialLayerSettings = {
  inundationLayer: {
    visible: false,
    opacity: 1,
    color: '#005CE6',
  },
  gaugeIcons: {
    visible: true,
    opacity: 1,
    color: '#3387ff',
  },
  AGOChannels: {
    visible: true,
    opacity: 1,
    color: '#00EAFF',
  },
  AGOAddicksBarkerFedLands: {
    visible: false,
    opacity: 1,
    color: '#000000',
  },
  AGOCatchments: {
    visible: false,
    opacity: 1,
    color: '#F88410',
  },
  AGOWatersheds: {
    visible: false,
    opacity: 1,
    color: '#38A800',
  },
  gaugeIconType: 'flow', // flow or rain
};

// user constants
export const userRoles = [
  { value: 'NO_ACCESS', label: 'NO_ACCESS' },
  { value: 'STANDARD_USER', label: 'STANDARD_USER' },
  { value: 'ADMIN', label: 'ADMIN' },
  { value: 'SUPERUSER', label: 'SUPERUSER' },
];

export const allowedUserRoles = ['STANDARD_USER', 'ADMIN', 'SUPERUSER'];

export const plotLineColorsArray = {
  stage: [
    '#f58231',
    '#42d4f4',
    '#800000',
    '#f032e6',
    '#000075',
    '#9A6324',
    '#4363d8',
    '#911eb4',
    '#808000',
    '#e6194B',
    '#ffe119',
  ],
  rainfall: [
    'rgba(67, 99, 216, 0.6)',
    '#3cb44b',
  ],
};

export const stageFlowLineColors = {
  HCFCD: '#2991FC',
  H0STAGE: '#FD0017',
  M0STAGE: '#000000',
  V0STAGE: '#FED62A',
  F0STAGE: '#278B28',
  R0STAGE: '#FC00FF',
  X0STAGE: '#58E0D0',
  //RFCSTAGE: '#9309D0',
  N0STAGE: '#FFA500',
  A0STAGE: '#00FF00',
  G0STAGE: '#ADD8E6',
  RFCSTAGE: '#BF77F6',
  USACESTAGE: '#964B00'
};

export const stageFlowSourceAlias = {
  ObservedSTAGE: 'HCFCD Obs.',
  H0STAGE: 'HCFCD Fcst.',
  V0STAGE: 'VIEUX Fcst.',
  M0STAGE: 'MRMS Fcst.',  
  R0STAGE: 'HRRR Fcst.',
  ObservedFLOW: 'HCFCD Obs. F',
  H0FLOW: 'HCFCD Fcst. F',
  V0FLOW: 'VIEUX Fcst. F',
  M0FLOW: 'MRMS Fcst. F',
  N0STAGE: 'NBM Fcst.',
  N0FLOW: 'NBM Fcst. F',
  A0STAGE: 'HRRR-MA Fcst.',
  A0FLOW: 'HRRR-MA F',
  G0STAGE: 'NSSL Fcst.',
  G0FLOW: 'NSSL Fcst. F',
  RFCSTAGE: 'RFC Fcst.',
  USACESTAGE: 'USACE Fcst.'
};

export const stageFlowSourceAliasReverse = {
  ObservedSTAGE: 'HCFCD Obs.',
  'HCFCD Fcst.': 'H0',
  'VIEUX Fcst.': 'V0',
  'MRMS Fcst.' : 'M0',  
  'HRRR Fcst.' : 'R0',
  'NBM Fcst.': 'N0',  
  'HRRR-MA Fcst.' : 'A0',  
  'NSSL Fcst.': 'G0',
  'RFC Fcst.': 'RFC',
  'USACE Fcst.': 'USACE'  
};

export const stageFlowSourceAliasSorted = ["H0STAGE", "H0FLOW", "R0STAGE","R0FLOW", "A0STAGE","A0FLOW", "M0STAGE", "M0FLOW","G0STAGE","G0FLOW", "N0STAGE" , "N0FLOW", "V0STAGE", "V0FLOW","RFCSTAGE","RFCFLOW","USACESTAGE"];//,"G0MEAN","G0ERR_UPPER","G0ERR_LOWER"];

export const gaugeStatusColors = {
  noData: '#bbb',
  normal: '#00BF00', // grey
  nearFlooding: '#fca532', // orange
  flooding: '#ff4d4d', // red
};

export const thresholdConfig = {
  el_chtop: {
    label: 'Channel Top',
    color: '#ff8c8c',
  },
  el_fld_act: {
    label: 'Action',
    color: '#fca503',
  },
};

export const thresholdAlias = {
  el_chbot: 'Channel Bottom',
  el_fld_act: 'Flood Action',
  el_chtop: 'Channel Top',
  el_road: 'Road Elevation',
  el_record: 'Record Elevation',
  el_levee: 'Levee Elevation',
  el_spill: 'Spill Elevation',
};

export const mrmsColorRamp = [
  '#02eceb',
  '#02c8f0',
  '#03a1ff',
  '#013cff',
  '#00ff00',
  '#00dc00',
  '#00be00',
  '#008e00',
  '#feff00',
  '#f0d200',
  '#e7b400',
  '#c87900',
  '#ff9f9f',
  '#ff3c3c',
  '#e60100',
  '#b50101',
  '#ff00ff',
  '#d900d9',
  '#a400a4',
  '#780078',
  '#ffffff',
  '#c0c0ff',
  '#c0feff',
  '#feffc0',
];

// key = max hours for number scale
// values = array of numbers associated with mrmsColorRamp above ^
// EXAMPLE: mrms duration length is 6 hours, 6 > 3 && 6 < 12 so key to use is 12
export const mrmsNumberScalingRamp = {
  3: [0.01, 0.05, 0.10, 0.15, 0.2, 0.4, 0.6, 0.8, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 8],
  12: [0.01, 0.05, 0.10, 0.2, 0.4, 0.6, 0.8, 1, 1.25, 1.5, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16],
  24: [0.01, 0.05, 0.1, 0.25, 0.5, 0.75, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 24],
  48: [0.01, 0.1, 0.25, 0.5, 0.75, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16, 18, 20, 24, 28, 32],
  72: [0.01, 0.1, 0.25, 0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40],
};

export const rainfallIconColorsKey = {
  'Out of Service': '#bbb',
  '0.00"': 'black',
  '< 1"': '#00BF00',
  '1" – 2"': '#FFD100',
  '2” – 4”': '#FF9900',
  '4” – 6”': '#FF0000',
  '6” – 8”': '#CC00FF',
  '8” +': '#FF99FF',
};

export const stageFlowThresholds = [
  'el_fld_act',
  'el_chtop',
];

export const defaultZoomLevel = {
  lat: 29.7752,
  lng: -95.3103
};

export const allWatersheds = 'All Watersheds';

export const devApiEndpoint = 'https://app-fcd-forecast-prod-api.azurewebsites.net';
export const prodApiEndpoint = 'https://hcfcdforecastdev-api.azurewebsites.net';
