import config from '../../config';

const { notificationDrawerWidth } = config;

export default (theme) => ({
  drawerPaper: {
    position: 'fixed',
    right: 0,
    top: 64,
    whiteSpace: 'nowrap',
    width: notificationDrawerWidth,
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: '#fafafa',
    overflowY: 'scroll',
    padding: 0,
  },
  headerTitle: {
    margin: '0 10px 0 20px',
    padding: 0,
    fontSize: 20,
    fontWeight: 'bold',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  headerWrapper: {
    minHeight: 48,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    padding: '5px 0',
    backgroundColor: '#efefef',
  },
  leftHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});
