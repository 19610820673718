import { takeLatest, put, select } from 'redux-saga/effects';

import { setError } from '../reducers/errors';
import { UPDATE_LAYER_VALUE, selectMapLayers } from '../reducers/mapLayers';
import { SET_DATE_RANGE, getDateRange } from '../reducers/dateRange';
import { parseStateIntoParams } from '../utils/format';
import history from '../history';

function* syncUrlToRedux() {
  try {
    const { mapLayers } = yield select(selectMapLayers);
    const dateRange = yield select(getDateRange);
    const updatedQueryParams = parseStateIntoParams(mapLayers, dateRange);
    history.push({
      search: updatedQueryParams,
    });
  } catch (e) {
    console.log('ERROR fetching sample data', e);
    yield put(setError({ errorId: 'urlSync', value: e.message || true }));
  }
}

export default function* watch() {
  yield takeLatest([UPDATE_LAYER_VALUE, SET_DATE_RANGE], syncUrlToRedux);
}
