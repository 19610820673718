import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import NotificationsTable from './NotificationsTable';

const useStyles = makeStyles({
  wrapper: {
    paddingTop: 10,
  },
  notificationName: {
    margin: 0,
    marginLeft: 20,
  },
});

const PersonalNotifications = ({ data }) => {
  const classes = useStyles();
  const [personalNotifications, setPersonalNotifications] = useState([]);

  useEffect(() => {
    if (data?.length) setPersonalNotifications(data);
  }, [data]);

  return (
    <Paper className={classes.wrapper}>
      {personalNotifications.map((notification, index) => (
        <div key={notification.notification_id}>
          <p className={classes.notificationName}>{notification.name}</p>
          <NotificationsTable data={[notification]} notification_type="personalNotifications" index={index} />
        </div>
      ))}
    </Paper>
  );
};

export default PersonalNotifications;
