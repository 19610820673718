import { takeLatest, put, select } from 'redux-saga/effects';
import qs from 'querystring';
import { FETCH_RAIN_INCREMENT, setRainIncrement } from '../../reducers/rainIncrement';
import {devApiEndpoint,prodApiEndpoint} from '../../config/constants';

import loading from '../../utils/loading';
import { getGauges } from '../../reducers/gauges';

let baseUrl ='';
const currentUrl = window.location.href;


function getBaseUrl()
{ 
  let url=''; 
  if (currentUrl.includes('hcfcdforecastdev'))   
      url= `${devApiEndpoint}/odata/Observations`;
  else
      url=`${prodApiEndpoint}/odata/Observations`;

  return url;
}
   

function* FetchRainIncrement({ selected, gauge_id, start, end }) {
  const loadingMsg = 'Fetching Rain Increment Data...';
  loading.start(loadingMsg);
  try {
    const start_date = start.split('T')[0];
    const end_date = end.split('T')[0];
    let requestURL;
    switch (selected) {
      case 'contrail':
        break; // data is already in selectedGaugeData
      case 'forecast':
        baseUrl= getBaseUrl();
        requestURL = `${baseUrl}?$select=GaugeNumber,ReadingDate,Value&$expand=ReadingSource($select=Name)&$expand=DeviceType($select=Name,DeviceTypeId)&$filter=GaugeNumber eq ${gauge_id} and ReadingDate ge ${start_date} and ReadingDate le ${end_date} and DeviceTypeID eq 2&$count=true`;
        break;
      case 'mrms': {
        const gauges = yield select(getGauges);
        const selectedGauge = gauges.features.find((gauge) => Number(gauge.properties.site_id) === Number(gauge_id));
        const [lng, lat] = selectedGauge.geometry.coordinates;
        requestURL = `/api/mrms/point?${qs.stringify({ lat, lng, fromDate: start_date, toDate: end_date })}`;
        break;
      }
      default:
        break;
    }
    if (requestURL) {
      const resp = yield fetch(requestURL);
      if (resp.status > 299) throw new Error('Invalid Response Code');
      const data = yield resp.json();
      if (selected === 'forecast') yield put(setRainIncrement(data.value));
      if (selected === 'mrms') yield put(setRainIncrement(data));
    } else {
      yield put(setRainIncrement([]));
    }
  } catch (e) {
    console.log('ERROR fetching Rain Increment data', e);
    // handle error
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(FETCH_RAIN_INCREMENT, FetchRainIncrement);
}
