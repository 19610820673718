import moment from 'moment';

// action type constants
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const SET_DATE_OBJECT = 'SET_DATE_OBJECT';

// actionCreators
export const setDateRange = ({ key, value }) => ({ type: SET_DATE_RANGE, key, value });
export const setDateObject = (object) => ({ type: SET_DATE_OBJECT, object });

// selectors
export const getDateRange = ({ dateRange }) => dateRange;

const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 3);

const initialState = {
  start: moment.utc().subtract(3, 'days').format(),
  end: moment.utc().format(),
  startActive: false,
  endActive: false,
};

// reducer
export default (state = initialState, { type, key, value, object }) => {
  switch (type) {
    case SET_DATE_OBJECT: {
      return object;
    }
    case SET_DATE_RANGE: {
      const updatedState = { ...state };
      updatedState[key] = value;
      return updatedState;
    }
    default:
      return state;
  }
};
