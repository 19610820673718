import React, { useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles({
  notificationStatus: {
    height: 20,
    width: 20,
    padding: 0,
    margin: 0,
    borderRadius: 2,
  },
});

const StatusCell = withStyles({
  root: {
    padding: 0,
    paddingLeft: 10,
  },
})(TableCell);

const NotificationStatus = ({ threshold, change }) => {
  const classes = useStyles();
  const [statusColor, setStatusColor] = useState('grey');
  const [symbol, setSymbol] = useState(' ');

  useEffect(() => {
    switch (change) {
      case 'rising':
        setSymbol('+');
        break;
      case 'falling':
        setSymbol('-');
        break;
      default:
        setSymbol(' ');
        break;
    }
  }, [change]);

  useEffect(() => {
    let color;
    switch (threshold) {
      case 'el_road':
        color = '#ff0000'; // red
        break;
      case 'el_chtop':
        color = '#ff8c00'; // orange
        break;
      case 'el_fld_act':
        color = '#ffc800'; // yellow
        break;
      case 'el_chbot':
        color = '#00ff11'; // green
        break;
      default:
        color = '#ffc800'; // light grey
        break;
    }
    setStatusColor(color);
  }, [threshold]);

  return (
    <StatusCell align="center">
      <div className={classes.notificationStatus} style={{ backgroundColor: statusColor }}>
        <strong>{symbol}</strong>
      </div>
    </StatusCell>
  );
};

export default NotificationStatus;
