import { takeLatest, put } from 'redux-saga/effects';
import { GET_CURRENT_USER, setCurrentUser } from '../reducers/currentUser';

import loading from '../utils/loading';

function* getCurrentUser() {
  const loadingMsg = 'Fetching User Details';
  loading.start(loadingMsg);
  try {
    const response = yield fetch('/currentUser');
    if (response.status === 401) return window.location.assign('/login');
    const currentUser = yield response.json();
    yield put(setCurrentUser(currentUser));
  } catch (e) {
    console.log('ERROR fetching current user details', e);
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(GET_CURRENT_USER, getCurrentUser);
}
