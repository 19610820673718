// action type constants
export const SET_UNIT = 'SET_UNIT';
export const SET_AMOUNT = 'SET_AMOUNT';

// actionCreators
export const setUnit = (payload) => ({ type: SET_UNIT, payload });
export const setAmount = (payload) => ({ type: SET_AMOUNT, payload });

export const getAmount = (state) => state.mrms.amount;
export const getUnit = (state) => state.mrms.unit;

const initialState = {
  unit: 'days',
  amount: 1,
};

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_AMOUNT:
      state.amount = payload;
      return;
    case SET_UNIT:
      state.unit = payload;
      return;
    default:
      return state;
  }
};
