import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Slider from '@material-ui/core/Slider';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { createDispatchBindings } from '../../utils/redux';
import { changeLayerOpacity } from '../../utils/map/layers';
import { updateLayerValue } from '../../reducers/mapLayers';
import { setRadarOpacity } from '../../reducers/radar';
import { setOGCRadarOpacity } from '../../reducers/radar-ogc';

const useStyles = makeStyles({
  opacitySliderWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 20px',
  },
  slider: {
    margin: '0px 10px',
  },
  opacityIcon: {
    fontSize: 14,
  },
  opacityIconSmall: {
    fontSize: 12,
  },
});

const LayerOpacitySlider = ({ layerName, mapLayers, updateLayerValue, setRadarOpacity, setOGCRadarOpacity, radar: { opacity }, radarOGC: { ogcOpacity } }) => {
  const classes = useStyles();
  const [layerOpacity, setLayerOpacity] = useState(1);

  const handleUpdatingLayerOpacity = (layerName, value) => {
    if (layerName === 'radar') {
      setRadarOpacity(value);
    } else if (layerName === 'radar-ogc') {
      setOGCRadarOpacity(value);
    } else {
      changeLayerOpacity(layerName, value);
      updateLayerValue({ layerName, key: 'opacity', value });
    }
  };

  useEffect(() => {
    if (layerName === 'radar') setLayerOpacity(opacity);
    if (layerName === 'radar-ogc') setLayerOpacity(ogcOpacity);
    if (mapLayers[layerName]) setLayerOpacity(Number(mapLayers[layerName]?.opacity));
  }, [mapLayers, layerName, opacity, ogcOpacity]);

  return (
    <div className={classes.opacitySliderWrapper}>
      <RadioButtonUncheckedIcon className={classes.opacityIconSmall} />
      <Tooltip title={`Layer Opacity: ${layerOpacity}`}>
        <Slider
          value={layerOpacity}
          onChange={(e, value) => handleUpdatingLayerOpacity(layerName, value)}
          aria-labelledby="opacity slider"
          className={classes.slider}
          min={0}
          max={1}
          step={0.1}
        />
      </Tooltip>
      <FiberManualRecordIcon className={classes.opacityIcon} />
    </div>
  );
};

const mapStateToProps = ({ radar, radarOGC }) => ({ radar, radarOGC });
const mapDispatchToProps = createDispatchBindings({ updateLayerValue, setRadarOpacity, setOGCRadarOpacity });

export default connect(mapStateToProps, mapDispatchToProps)(LayerOpacitySlider);
