// action type constants
export const SET_ON_MAP = 'SET_ON_MAP';

// actionCreators
export const setOnMap = (onMap) => ({ type: SET_ON_MAP, onMap });

const initialState = true;

// reducer
export default (state = initialState, { type, onMap }) => {
  switch (type) {
    case SET_ON_MAP:
      return onMap;
    default:
      return state;
  }
};
