/* eslint-disable import/no-cycle */
import { takeLatest, put } from 'redux-saga/effects';
import { feature } from 'topojson-client';
import { setError } from '../../reducers/errors';
import { FETCH_AGO_LAYERS } from '../../reducers/mapLayers';
import { createAGOLayers } from '../../utils/map/layers';
import loading from '../../utils/loading';
import parseGaugesIntoWatersheds from '../../utils/parseGaugesIntoWatersheds';
import parseGaugesIntoThresholds from '../../utils/parseGaugesIntoThresholds';
import { setWatershedOptions } from '../../reducers/watershed';
import { setGaugeThresholds } from '../../reducers/gaugeThresholds';

const getSmall = async () => {
  const res = await fetch('/api/ago/small');
  if (res.status > 399) {
    throw new Error('failed to fetch small');
  }
  const { result } = await res.json();
  return result;
};
const getLarge = async () => {
  const res = await fetch('/api/ago/large');
  if (res.status > 399) {
    throw new Error('failed to fetch large');
  }
  const { result } = await res.json();
  return [feature(result, 'catchments'), feature(result, 'watersheds')];
};
function* fetchAGOLayers() {
  const loadingMsg = 'Fetching AGO Layer Data...';
  loading.start(loadingMsg);
  try {
    const results = yield Promise.all([getSmall(), getLarge()]);
    createAGOLayers([].concat(...results));
    // TODO: 3. Handle -999 thresholds as non-existetent (but addable later)
    const thresholds = parseGaugesIntoThresholds(results[0][0]);
    const watershedOptions = parseGaugesIntoWatersheds(results[0][0]);
    yield put(setWatershedOptions(watershedOptions));
    yield put(setGaugeThresholds(thresholds));
  } catch (e) {
    console.log('ERROR fetching AGO Layer data', e);
    yield put(setError({ errorId: 'fetchAGOLayerError', value: e.message || true }));
  }
}

export default function* watch() {
  yield takeLatest(FETCH_AGO_LAYERS, fetchAGOLayers);
}
