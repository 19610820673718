import { dynamicMapLayer } from 'esri-leaflet';

// action types
export const SET_TIMES = 'SET_TIMES';
export const UPDATE_CURRENT_TIME = 'UPDATE_CURRENT_TIME';
export const ADV_CURRENT_TIME = 'ADV_CURRENT_TIME';
export const DEC_CURRENT_TIME = 'DEC_CURRENT_TIME';
export const START_ADVANCING = 'START_ADVANCING';
export const STOP_ADVANCING = 'STOP_ADVANCING';
export const GET_TIMES = 'GET_TIMES';
export const UPDATE_FRAMERATE = 'UPDATE_FRAMERATE';
export const ADD_TO_MAP = 'ADD_TO_MAP';
export const RM_FROM_MAP = 'RM_FROM_MAP';
export const SET_REFRESH = 'SET_REFRESH';
export const SET_RADAR_OPACITY = 'SET_RADAR_OPACITY';
export const SET_ANIMATION_TICKS = 'SET_ANIMATION_TICKS';
// reducer with initial state
const initialState = {
  times: [],
  clampedTimes: [], // temp times array controled by user-selected animation ticks
  animationTicks: 1, // user-selected number of steps in animation loop
  current: 0,
  advancing: false,
  delay: 0.5,
  layer: dynamicMapLayer({
    url: 'https://nowcoast.noaa.gov/arcgis/rest/services/nowcoast/radar_meteo_imagery_nexrad_time/MapServer/',
    layers: [3],
  }),
  onMap: false,
  timesLastFetched: 0,
  autoRefresh: true,
  opacity: 0.5,
};
initialState.layer.toJSON = () => '<layer>';
// actionCreators
export const setTimes = (times) => ({ type: SET_TIMES, payload: times });
export const setAnimationTicks = (tickNumber) => ({ type: SET_ANIMATION_TICKS, payload: tickNumber });
export const getTimes = () => ({ type: GET_TIMES });
export const advTime = () => ({ type: ADV_CURRENT_TIME });
export const decTime = () => ({ type: DEC_CURRENT_TIME });
export const startAdvancing = () => ({ type: START_ADVANCING });
export const stopAdvancing = () => ({ type: STOP_ADVANCING });
export const updateTime = (newIndex) => ({ type: UPDATE_CURRENT_TIME, payload: newIndex });
export const setDelay = (payload) => ({ type: UPDATE_FRAMERATE, payload });
export const addToMap = () => ({ type: ADD_TO_MAP });
export const rmFromMap = () => ({ type: RM_FROM_MAP });
export const setRefresh = (payload) => ({ type: SET_REFRESH, payload });
export const setRadarOpacity = (opacity) => ({ type: SET_RADAR_OPACITY, opacity });
// selectors
export const getDelay = (state) => state.radar.delay;
export const getTimeAge = (state) => state.radar.timesLastFetched;

// reducer
export default (state = initialState, { type, payload, opacity }) => {
  switch (type) {
    case SET_TIMES:
      state.times = payload;
      state.clampedTimes = payload;
      state.animationTicks = payload.length;
      state.timesLastFetched = Date.now();
      return;
    case SET_ANIMATION_TICKS:
      state.animationTicks = payload;
      state.clampedTimes = state.times.filter((value, i) => i < payload);
      if (state.current > state.clampedTimes.length - 1) state.current = state.clampedTimes.length - 1;
      return;
    case UPDATE_CURRENT_TIME:
      state.current = payload;
      state.current %= state.clampedTimes.length;
      return;
    case ADV_CURRENT_TIME:
      state.current += 1;
      state.current %= state.clampedTimes.length;
      return;
    case DEC_CURRENT_TIME:
      state.current -= 1;
      if (state.current < 0) {
        state.current = state.clampedTimes.length - 1;
      }
      return;
    case START_ADVANCING:
      state.advancing = true;
      return;
    case STOP_ADVANCING:
      state.advancing = false;
      return;
    case UPDATE_FRAMERATE:
      if (!payload) {
        state.delay = initialState.delay;
        return;
      }
      state.delay = payload;
      return state;
    case ADD_TO_MAP:
      if (state.onMap) {
        return;
      }
      state.layer.addTo(window.lmap);
      state.onMap = true;
      state.layer.setOpacity(state.opacity);
      return;
    case RM_FROM_MAP:
      if (!state.onMap) {
        return;
      }
      state.layer.removeFrom(window.lmap);
      state.onMap = false;
      return;
    case SET_REFRESH:
      state.autoRefresh = payload;
      return;
    case SET_RADAR_OPACITY:
      state.layer.setOpacity(opacity);
      state.opacity = opacity;
      break;
    default:
      return state;
  }
};
