import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import StageFlowPlot from '../Graphs/StageFlowPlot/StageFlowPlot';
import RainfallPlot from '../Graphs/RainfallPlot/RainfallPlot';

const useStyles = makeStyles({
  fullScreen: {
    width: '100%',
    height: '100%',
    padding: 20,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 1,
  },
  chartTitle: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 4,
    marginLeft: 10,
    padding: 0,
    fontSize: 12,
  },
});

const ExpandedItemModal = ({ data, handleClose, gaugeThresholds }) => {
  const { graphName, gaugeId } = data;
  const classes = useStyles();

  const handleClosingModal = () => {
    handleClose();
  };

  return (
    <div className={classes.fullScreen}>
      <IconButton onClick={handleClosingModal} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      {graphName === 'stageFlowPlot' && (
        <>
          <h3 key="title" className={classes.chartTitle}>Stage/Flow Plot</h3>
          <StageFlowPlot
            gauge_id={gaugeId}
            thresholdToggleState={gaugeThresholds.toggleState}
            thresholds={gaugeThresholds.data}
          />
        </>
      )}
      {graphName === 'rainfallPlot' && (
        <>
          <h3 className={classes.chartTitle}>Rainfall Plot</h3>
          <RainfallPlot />
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ selectedGaugeData, gaugeThresholds }) => ({ selectedGaugeData, gaugeThresholds });

export default connect(mapStateToProps)(ExpandedItemModal);
