// action type constants
export const GET_MRMS_RAINFALL = 'GET_MRMS_RAINFALL';
export const SET_MRMS_RAINFALL = 'SET_MRMS_RAINFALL';

// actionCreators
export const getMRMSRainfall = () => ({ type: GET_MRMS_RAINFALL });
export const setMRMSRainfall = (payload) => ({ type: SET_MRMS_RAINFALL, payload });

const initialState = [];

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MRMS_RAINFALL:
      state = payload;
      return state;
    default:
      return state;
  }
};
