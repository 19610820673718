import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { hideSnackbar } from '../reducers/snackbar';

import { createDispatchBindings } from '../utils/redux';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const MessageToUser = ({ snackbar, hideSnackbar }) => {
  const { open, message } = snackbar;
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    hideSnackbar();
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Alert onClose={handleClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = ({ snackbar }) => ({ snackbar });
const mapDispatchToProps = createDispatchBindings({ hideSnackbar });

export default connect(mapStateToProps, mapDispatchToProps)(MessageToUser);
