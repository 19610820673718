import React, { useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

import { userRoles } from '../../config/constants';
import { createDispatchBindings } from '../../utils/redux';
import { updateUser } from '../../reducers/users';

const useStyles = makeStyles({
  contentWrapper: {
    minHeight: 250,
    display: 'flex',
    justifyContent: 'center',
  },
  textInput: {
    width: 300,
    marginBottom: 10,
  },
});

const MessageModal = ({ data, handleClose, updateUser }) => {
  const classes = useStyles();
  const [updatedUser, setUpdatedUser] = useState(data);

  const handleUpdatingUser = () => {
    updateUser(updatedUser);
    handleClose();
  };

  return (
    <>
      <DialogTitle id="responsive-dialog-title">Edit User Details</DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <DialogContentText>
          <TextField
            value={updatedUser.name}
            onChange={(e) => setUpdatedUser({ ...updatedUser, name: e.target.value })}
            label="Name"
            variant="outlined"
            className={classes.textInput}
            size="small"
          />
          <Select
            className={classes.roleDropdown}
            classNamePrefix="select"
            options={userRoles}
            value={{ label: updatedUser.role, value: updatedUser.role }}
            onChange={({ value }) => setUpdatedUser({ ...updatedUser, role: value })}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={handleClose} color="primary">
          close
        </Button>
        <Button variant="contained" autoFocus onClick={handleUpdatingUser} color="primary">
          Save
        </Button>
      </DialogActions>
    </>
  );
};

const mapDispatchToProps = createDispatchBindings({ updateUser });

export default connect(null, mapDispatchToProps)(MessageModal);
