// action type constants
export const SET_RAINFALL_LOOKBACK_PERIOD = 'SET_RAINFALL_LOOKBACK_PERIOD';

// actionCreators
export const setRainfallLookbackPeriod = (payload) => ({ type: SET_RAINFALL_LOOKBACK_PERIOD, payload });

const initialState = '-';

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_RAINFALL_LOOKBACK_PERIOD:
      state = payload;
      return state;
    default:
      return state;
  }
};
