import moment from 'moment';
import 'moment-timezone';


export const convertToUTC = (localDateString) => {  
  var localDate = new Date(localDateString);
  var utcTime= localDate.toISOString();
  utcTime= utcTime.substring(0,utcTime.length-5);
  utcTime= utcTime +'Z';  
  return utcTime;
};

export const convertToUTCDateFormat = (localDateString) => {
  const utcTime = moment.utc(localDateString).format('YYYY-MM-DD');
  return utcTime;
};

export const convertToLocal = (utcDateString) => {
  const utcDate = moment.utc(utcDateString);
  const localDate = utcDate.clone().tz('America/Chicago');
  const localDateString = localDate.format();
  return localDateString;
};

export const GetDateWithoutTZ = (date) => {  
  var simpleDate= date.replace('T',' ').replace('Z','');
  return simpleDate;
};
