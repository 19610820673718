import { takeLatest, put } from 'redux-saga/effects';

import { setError } from '../reducers/errors';
import { SYNC_SETTINGS_TO_URL, setMapLayers } from '../reducers/mapLayers';
import { setDateObject } from '../reducers/dateRange';
import { initialLayerSettings } from '../config/constants';
import getQueryStringParams from '../utils/getQueryStringParams';
import { parseStateIntoParams, parseParamsIntoStateSettings } from '../utils/format';
import { setInitialLayerSettings } from '../utils/map/layers';
import loading from '../utils/loading';
import history from '../history';
import { fetchGaugeData } from '../reducers/selectedGaugeData';


function* syncReduxToUrl() {
  const loadingMsg = 'Syncing Data with URL...';
  loading.start(loadingMsg);
  try {
    const queryString = history.location.search;
    const site_id = history.location.pathname.split('/')[2];
    if (queryString.length) {
      const queryParams = getQueryStringParams(queryString);
      if (queryParams.start || queryParams.end) {
        yield put(setDateObject({
          start: queryParams.start || '',
          end: queryParams.end || '',
          startActive: !!queryParams.start,
          endActive: !!queryParams.end,
        }));
        if (site_id) yield put(fetchGaugeData(site_id));
      }
      const mapLayerSettings = parseParamsIntoStateSettings(queryParams);
      setInitialLayerSettings(mapLayerSettings);
      yield put(setMapLayers(mapLayerSettings));
    } else {
      setInitialLayerSettings(initialLayerSettings);
      const defaultQueryString = yield parseStateIntoParams(initialLayerSettings);
      history.push({
        search: defaultQueryString,
      });
    }
  } catch (e) {
    console.log('ERROR fetching sample data', e);
    yield put(setError({ errorId: 'urlSync', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(SYNC_SETTINGS_TO_URL, syncReduxToUrl);
}
