import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { getMap } from './leaflet';

let currentGeocodedMarker;

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

export const generateGeocodedMarker = ({ x, y }) => {
  const latlng = { lat: y, lng: x };
  const map = getMap();
  currentGeocodedMarker = L.marker(latlng, { icon: DefaultIcon }).addTo(map);
  map.flyTo(latlng, 12);
};

export const removeGeocodedMarker = () => {
  if (currentGeocodedMarker) {
    const map = getMap();
    map.removeLayer(currentGeocodedMarker);
  }
};
