import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import { createDispatchBindings } from '../../utils/redux';
import { setGaugeFilters } from '../../reducers/gaugeFilters';
import WatershedDropdown from './WatershedDropdown';

const useStyles = makeStyles({
  root: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    margin: '0px 0px 5px 0px',
    fontWeight: 'bold',
  },
  formControl: {
    paddingLeft: 10,
  },
  checkboxLabel: {
    margin: 0,
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  secondaryCheckboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 26,
  },
});

const checkboxes = [
  {
    label: 'Normal Gauges',
    key: 'normalGauges',
  },
  {
    label: 'Near Flooding',
    key: 'nearFlooding',
  },
  {
    label: 'Flooding',
    key: 'flooding',
  },
  {
    label: 'Forecasted Near Flooding',
    key: 'forecastedNearFlooding',
  },
  {
    label: 'Forecasted Flooding',
    key: 'forecastedFlooding',
  },
];

const GaugeFilters = ({ gaugeFilters, setGaugeFilters }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p className={classes.title}>Gauge Filters:</p>
      <FormControl size="small" component="fieldset" className={classes.formControl}>
        <FormGroup>
          {checkboxes.map(({ label, key }) => (
            <div key={key} className={classes.secondaryCheckboxWrapper}>
              <Checkbox
                size="small"
                checked={gaugeFilters[key]}
                onChange={(e) => setGaugeFilters({ key, value: e.target.checked })}
              />
              <p className={classes.checkboxLabel}>{label}</p>
            </div>
          ))}
        </FormGroup>
      </FormControl>
      <WatershedDropdown />
    </div>
  );
};

const mapStateToProps = ({ gaugeFilters }) => ({ gaugeFilters });
const mapDispatchToProps = createDispatchBindings({ setGaugeFilters });

export default connect(mapStateToProps, mapDispatchToProps)(GaugeFilters);
