import { takeLatest, put } from 'redux-saga/effects';
import { GET_ADMIN_FORECAST_ENSEMBLE, setAdminForecastEnsembles } from '../../reducers/adminForecastEnsembles';

import loading from '../../utils/loading';

function* GetAdminForecastEnsemble() {
  const loadingMsg = 'Fetching Forecast Ensembles...';
  loading.start(loadingMsg);
  try {
    const resp = yield fetch('/api/admin/ensembles');
    if (resp.status > 299) throw new Error('Invalid Response Code');
    const ensembles = yield resp.json();
    // do something with the data
    yield put(setAdminForecastEnsembles(ensembles));
  } catch (e) {
    console.log('ERROR fetching Forecast Ensemble data', e);
    // handle error
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(GET_ADMIN_FORECAST_ENSEMBLE, GetAdminForecastEnsemble);
}
