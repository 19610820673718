/* eslint-disable import/no-cycle */
import { layers, createFlowStatusLayer, createRainfallStatusLayer, addLayer, removeLayer,getLayer } from './layers';
import reduxStore from '../../store';
import { createMap, getMap, setBasemap } from './leaflet';
import { openDrawer, setGaugeInfoDrawerTab } from '../../reducers/gaugeInfoDrawer';
import { updateLayerValue  } from '../../reducers/mapLayers';
import { setSidebar } from '../../reducers/sidebar';
import history from '../../history';
import { unselectedFlowMarker, selectedFlowMarker, getFlowMarkerStyling, getFilteredGauges } from './flowStatusMarkers';
import { unselectedRainMarker, getRainMarkerStyling, selectedRainMarker } from './rainfallStatusMarkers';
import { defaultZoomLevel, allWatersheds } from '../../config/constants';

let activeMarker;

const getActiveGauge = (gaugeId, iconType) => {
  let gaugeIconsLayer;
  if (iconType === 'flow') gaugeIconsLayer = layers.flowIcons;
  if (iconType === 'rain') gaugeIconsLayer = layers.rainIcons;
  if (gaugeIconsLayer) {
    const layerKeys = Object.keys(gaugeIconsLayer._layers);
    for (let i = 0; i < layerKeys.length; i += 1) {
      if (gaugeIconsLayer._layers[layerKeys[i]].gaugeId === parseInt(gaugeId, 10)) {
        return gaugeIconsLayer._layers[layerKeys[i]];
      }
    }
  }
};

export const setActiveMarker = async (gaugeId, iconType) => {
  if (!iconType) iconType = reduxStore.getState().mapLayers.gaugeIconType;
  if (iconType === 'flow' && !layers.flowIcons) return;
  if (iconType === 'rain' && !layers.rainIcons) return;
  const map = getMap();
  if (gaugeId) {
    if (iconType === 'flow') {
      const { forecastIconData, gaugeThresholds, dateRange } = await reduxStore.getState();
      if (activeMarker) {
        // if there is an active marker, reset its styling to unselected
        const { iconText, iconColor, forecastCircleColor } = getFlowMarkerStyling(activeMarker.gaugeId, forecastIconData, gaugeThresholds, dateRange);
        activeMarker.setIcon(unselectedFlowMarker({ iconColor, iconText, forecastCircleColor }));
      }
      // set the selected marker's styles to active
      const newActiveGauge = getActiveGauge(gaugeId, iconType);
      const { iconText, iconColor, forecastCircleColor } = getFlowMarkerStyling(gaugeId, forecastIconData, gaugeThresholds, dateRange);
      if (typeof newActiveGauge?.setIcon !== 'function') return;
      newActiveGauge.setIcon(selectedFlowMarker({ iconColor, iconText, forecastCircleColor }));
      const activeGaugeLatLng = { ...newActiveGauge.getLatLng() };
      const pathArray = history.location.pathname.split('/');
      activeGaugeLatLng.lat += -0.1;
      if (pathArray[1] === 'dashboard') {
        map.flyTo({ lat: activeGaugeLatLng.lat + 0.095, lng: activeGaugeLatLng.lng }, 12);
      } else {
        map.flyTo(activeGaugeLatLng, 11);
      }
      activeMarker = newActiveGauge;
    }
    if (iconType === 'rain') {
      if (activeMarker) {
        // if there is an active marker, reset its styling to unselected
        const { iconColor } = getRainMarkerStyling(activeMarker.gaugeId);
        activeMarker.setIcon(unselectedRainMarker({ iconColor }));
      }
      // set the selected marker's styles to active
      const newActiveGauge = getActiveGauge(gaugeId, iconType);
      const { iconColor } = getRainMarkerStyling(gaugeId);
      newActiveGauge.setIcon(selectedRainMarker({ iconColor }));
      const activeGaugeLatLng = { ...newActiveGauge.getLatLng() };
      const pathArray = history.location.pathname.split('/');
      activeGaugeLatLng.lat += -0.1;
      if (pathArray[1] === 'dashboard') {
        map.flyTo({ lat: activeGaugeLatLng.lat + 0.095, lng: activeGaugeLatLng.lng }, 12);
      } else {
        map.flyTo(activeGaugeLatLng, 11);
      }
      activeMarker = newActiveGauge;
    }
  }
};

export const clearActiveMarker = async () => {
  const { forecastIconData, gaugeThresholds, dateRange } = await reduxStore.getState();
  const { iconText, iconColor, forecastCircleColor } = getFlowMarkerStyling(activeMarker.gaugeId, forecastIconData, gaugeThresholds, dateRange);
  activeMarker.setIcon(unselectedFlowMarker({ iconColor, iconText, forecastCircleColor }));
  const currentPage = history.location.pathname.split('/')[1];
  history.push({
    pathname: `/${currentPage}`,
    search: history.location.search,
  });
  activeMarker = undefined;
};

export const onEachFeature = (feature, layer) => {
  layer.gaugeId = feature.properties.site_id;
  layer.on({
    click: () => {
      const currentPage = history.location.pathname.split('/')[1];
      history.push({
        pathname: `/${currentPage}/${feature.properties.site_id}`,
        search: history.location.search,
      });
      if (currentPage === 'map') {
        reduxStore.dispatch(openDrawer());
        reduxStore.dispatch(setSidebar(false));
        reduxStore.dispatch(setGaugeInfoDrawerTab(0));
      }
    },
  });
};

export const updateGaugeIcons = async (iconType) => {
  const map = getMap();
  const { gaugeFilters, mapLayers, watershed } = reduxStore.getState();
  if (!iconType) iconType = mapLayers.gaugeIconType;
  const gaugesGeoJSON = layers.gauges;    
  const filteredGauges = await getFilteredGauges(gaugesGeoJSON, gaugeFilters, { watershed: watershed.value, type: iconType });

  const flowIconLayer = layers.flowIcons;
  const rainIconLayer = layers.rainIcons;
  if (flowIconLayer) map.removeLayer(flowIconLayer);
  if (rainIconLayer) map.removeLayer(rainIconLayer);

  if (mapLayers.gaugeIcons.visible) {
    if (iconType === 'flow') {
      createFlowStatusLayer(filteredGauges);      
      if (activeMarker) setActiveMarker(activeMarker.gaugeId, 'flow');
    }

    if (iconType === 'rain') {
      createRainfallStatusLayer(filteredGauges);      
      if (activeMarker) setActiveMarker(activeMarker.gaugeId, 'rain');
    }
  }
};

export const displayWatershed = async () => {
  const map = getMap();
  const { watershed} = reduxStore.getState();  
  const agoWatershedLayer= 'AGOWatersheds';
          
  addLayer(agoWatershedLayer);        
  reduxStore.dispatch(updateLayerValue({layerName: agoWatershedLayer, key: 'visible', value: true }));
  const agoLayer=  getLayer(agoWatershedLayer);
  const layers = agoLayer.getLayers();
        
  layers.forEach(item => {
    if (item.feature.properties.WTSHNAME === watershed.value.toUpperCase()) {
              
      const bounds = item.getBounds();          
      item.setStyle({
        weight: 5,
        fillColor: '#38A800',              
        color: '#666'      
        });                
        map.flyToBounds(bounds);               
    } 
    else {          
      agoLayer.resetStyle(item);          
    }
    });
  
    if (watershed.value === allWatersheds)  
      map.flyTo({ lat: defaultZoomLevel.lat, lng: defaultZoomLevel.lng  }, 10);          
    
    //if (activeMarker) setActiveMarker(activeMarker.gaugeId, mapLayers.gaugeIconType);  
};


