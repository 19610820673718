import { takeLatest, put } from 'redux-saga/effects';
import { GET_ADMIN_FORECASTS, setAdminForecasts } from '../../reducers/adminForecasts';

import loading from '../../utils/loading';

function* GetAdminForecasts() {
  const loadingMsg = 'Fetching Admin Forecasts...';
  loading.start(loadingMsg);
  try {
    const resp = yield fetch('/api/admin/forecasts');
    if (resp.status > 299) throw new Error('Invalid Response Code');
    const data = yield resp.json();
    yield put(setAdminForecasts(data));
  } catch (e) {
    console.log('ERROR fetching Admin Forecasts', e);
    // handle error
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(GET_ADMIN_FORECASTS, GetAdminForecasts);
}
