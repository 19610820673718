import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import MessageModal from './MessageModal';
import ExpandedItemModal from './ExpandedItemModal';
import EditUserModal from './EditUserModal';
import GaugeSubModal from './GaugeSubModal';
import SubscriptionManagementModal from './SubscriptionManagementModal';
import DeleteForecastModal from './DeleteForecastModal';

import { EXPANDED_ITEM_MODAL, EDIT_USER_MODAL, GAUGE_SUB_MODAL, SUB_MANAGEMENT_MODAL, DELETE_FORECAST_MODAL } from '../../config/constants';
import { closeModal } from '../../reducers/modal';

import { createDispatchBindings } from '../../utils/redux';

const Modal = ({ closeModal, open, type, data, size, fullScreen }) => {
  // hide modal if not open
  if (!open) return '';

  let Content;

  switch (type) {
    case EXPANDED_ITEM_MODAL:
      Content = ExpandedItemModal;
      break;
    case EDIT_USER_MODAL:
      Content = EditUserModal;
      break;
    case GAUGE_SUB_MODAL:
      Content = GaugeSubModal;
      break;
    case SUB_MANAGEMENT_MODAL:
      Content = SubscriptionManagementModal;
      break;
    case DELETE_FORECAST_MODAL:
      Content = DeleteForecastModal;
      break;
    default:
      Content = MessageModal;
      break;
  }
  return (
    <Dialog
      maxWidth={size}
      open={open}
      fullScreen={fullScreen}
      onClose={closeModal}
      disableBackdropClick
    >
      <Content data={data} handleClose={closeModal} />
    </Dialog>
  );
};

const mapStateToProps = ({ modal }) => ({ ...modal });

const mapDispatchToProps = createDispatchBindings({ closeModal });

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
