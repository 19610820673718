import Fuse from 'fuse.js';

const forecastFuseOptions = {
  keys: ['name', 'forecast_id'],
  findAllMatches: true,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 1000,
  maxPatternLength: 32,
  minMatchCharLength: 1,
};

let forecastFuse;

const fuzzySearchForecasts = ({ forecasts, searchValue }) => {
  if (!forecastFuse) {
    forecastFuse = new Fuse(forecasts, forecastFuseOptions);
  }
  const searchResults = forecastFuse.search(searchValue);
  const formattedResults = searchResults.map(({ item }) => item);
  if (!formattedResults?.length) return [];
  return formattedResults;
};

export default fuzzySearchForecasts;
