import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loadingWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const NoData = () => {
  const classes = useStyles();
  return (
    <div className={classes.loadingWrapper}>
      <h3>Data not available.</h3>
    </div>
  );
};

export default NoData;
