import asyncDebounce from './asyncDebounce';
import { generateGeocodedMarker, removeGeocodedMarker } from './map/geocoding';

export const geocodeSuggestSearch = asyncDebounce((query) => fetch(`https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?f=json&text=${query}&searchExtent=-96.3587,29.0805,-94.4618,30.438`)
  .then((resp) => resp.json()), 250);

export const handleAddressSelection = async (selection) => {
  if (selection?.value) {
    const { text, magicKey } = selection.value;
    const { candidates } = await fetch(`https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=json&SingleLine=${text}&magicKey=${magicKey}`)
      .then((res) => res.json());
    if (candidates.length) {
      const latlng = candidates[0].location;
      generateGeocodedMarker(latlng);
    }
  } else {
    removeGeocodedMarker();
  }
};
