import { combineReducers } from '../utils/redux';

import currentUser from './currentUser';
import mapRefs from './mapRefs';
import sidebar from './sidebar';
import modal from './modal';
import snackbar from './snackbar';
import notificationDrawer from './notificationDrawer';
import gaugeInfoDrawer from './gaugeInfoDrawer';
import onMap from './onMap';
import gauges from './gauges';
import errors from './errors';
import selectedGaugeSensors from './selectedGaugeSensors';
import selectedGaugeData from './selectedGaugeData';
import gaugeThresholds from './gaugeThresholds';
import mapLayers from './mapLayers';
import users from './users';
import subscriptions from './subscriptions';
import notifications from './notifications';
import radar from './radar';
import radarOGC from './radar-ogc';
import dateRange from './dateRange';
import hiddenForecastModels from './hiddenForecastModels';
import dateForForecastDropdown from './dateForForecastDropdown';
import gaugeForecast from './gaugeForecast';
import chartRefs from './chartRefs';
import forecastList from './forecastList';
import graphTimeSeries from './graphTimeSeries';
import rainfallStatus from './rainfallStatus';
import gaugeFilters from './gaugeFilters';
import watershed from './watershed';
import summaryTable from './summaryTable';
import rainfallLookbackPeriod from './rainfallLookbackPeriod';
import forecastIconData from './forecastIconData';
import mrmsPointRainfall from './mrmsPointRainfall';
import mrmsLayer from './mrmsLayer';
import mrms from './mrms';
import rainIncrement from './rainIncrement';
import adminForecasts from './adminForecasts';
import adminForecastEnsembles from './adminForecastEnsembles';
import adminGauges from './adminGauges';
import gaugeDescription from './gaugeDescription';

const reducers = combineReducers({
  currentUser,
  sidebar,
  mapRefs,
  modal,
  snackbar,
  notificationDrawer,
  gaugeInfoDrawer,
  onMap,
  gauges,
  errors,
  selectedGaugeSensors,
  selectedGaugeData,
  gaugeThresholds,
  mapLayers,
  users,
  subscriptions,
  notifications,
  radar,
  dateRange,
  hiddenForecastModels,
  dateForForecastDropdown,
  gaugeForecast,
  chartRefs,
  forecastList,
  radarOGC,
  graphTimeSeries,
  rainfallStatus,
  gaugeFilters,
  watershed,
  summaryTable,
  rainfallLookbackPeriod,
  forecastIconData,
  mrmsPointRainfall,
  mrmsLayer,
  rainIncrement,
  mrms,
  adminForecasts,
  adminForecastEnsembles,
  adminGauges,
  gaugeDescription,
});

export default reducers;
