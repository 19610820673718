export default {
  GOOGLE_MAPS_API_KEY: 'INSERT_GOOGLE_API_KEY_HERE',
  gmapsUrl: 'https://maps.googleapis.com/maps/api/js?key=',
  mapSideBarWidth: 200,
  notificationDrawerWidth: 650,
  drawerMinWidth: 325,
  drawerMaxWidth: 350,
  drawerHeight: 400,
  headerHeight: 64,
  DashboardMapHeight: 52,
  dashboardTableHeight: 220,
  datePickerHeight: 52,
  summaryTableHeight: 120,
};
