// action type constants
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';

// actionCreators
export const createSubscription = ({ gauge_id, threshold, name }) => ({ type: CREATE_SUBSCRIPTION, gauge_id, threshold, name });
export const fetchSubscriptions = () => ({ type: FETCH_SUBSCRIPTIONS });
export const setSubscriptions = (subscriptions) => ({ type: SET_SUBSCRIPTIONS, subscriptions });
export const deleteSubscription = (subscription_id) => ({ type: DELETE_SUBSCRIPTION, subscription_id });

const initialState = [];

// reducer
export default (state = initialState, { type, subscriptions }) => {
  switch (type) {
    case SET_SUBSCRIPTIONS:
      state = subscriptions;
      return state;
    default:
      return state;
  }
};
