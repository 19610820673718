import qs from 'querystring';

import { takeLatest, put, select } from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { getGauges } from '../../reducers/gauges';
import { FETCH_RAINFALL_STATUS, setRainfallStatus } from '../../reducers/rainfallStatus';

import { createRainfallStatusLayer } from '../../utils/map/layers';

import loading from '../../utils/loading';

function* fetchRainfallStatus({ data_start }) {
  const loadingMsg = 'Fetching Rainfall Status...';
  loading.start(loadingMsg);
  try {
    const res = yield fetch(`/api/gauges/rainfall-status?${qs.stringify({ data_start })}`);
    if (res.status > 399) throw new Error('Error fetching rainfall status');
    const data = yield res.json();
    yield put(setRainfallStatus(data));
    const gaugeGeoJSON = yield select(getGauges);
    if (gaugeGeoJSON?.features?.length) createRainfallStatusLayer(gaugeGeoJSON);
  } catch (e) {
    console.log('ERROR fetching rainfall status', e);
    yield put(setError({ errorId: 'fetchRainfallStatusError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(FETCH_RAINFALL_STATUS, fetchRainfallStatus);
}
