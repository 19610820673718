import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import SettingsIcon from '@material-ui/icons/Settings';

import notificationDrawerStyles from './notificationDrawerStyles';
import NotificationContent from './NotificationContent';

import { closeNotificationDrawer } from '../../reducers/notificationDrawer';
import { createDispatchBindings } from '../../utils/redux';
import { fetchNotifications } from '../../reducers/notifications';
import { openModal } from '../../reducers/modal';
import { SUB_MANAGEMENT_MODAL } from '../../config/constants';

const useStyles = makeStyles((theme) => (notificationDrawerStyles(theme)));

const NotificationDrawer = ({ open, closeNotificationDrawer, fetchNotifications, openModal }) => {
  const classes = useStyles();
  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleOpenningNotificationManager = () => {
    openModal({ type: SUB_MANAGEMENT_MODAL });
  };

  return (
    <Drawer
      classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <div className={classes.headerWrapper}>
        <div className={classes.leftHeaderWrapper}>
          <p className={classes.headerTitle}>Notifications</p>
          <IconButton
            className={classes.editNotificationsButton}
            onClick={handleOpenningNotificationManager}
          >
            <SettingsIcon fontSize="small" />
          </IconButton>
        </div>
        <Tooltip title="Collapse Menu">
          <IconButton onClick={closeNotificationDrawer}>
            <ChevronRightIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Divider />
      <NotificationContent />
    </Drawer>
  );
};

const mapStateToProps = ({ notificationDrawer: { open } }) => ({ open });
const mapDispatchToProps = createDispatchBindings({ closeNotificationDrawer, fetchNotifications, openModal });

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDrawer);
