import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createDispatchBindings } from '../../utils/redux';
import { deleteForecast } from '../../reducers/adminForecasts';

const DeleteForecastModal = ({ handleClose, deleteForecast, data }) => {
  const handleConfirm = () => {
    deleteForecast({ forecast_id: data.forecast.forecast_id, resync: data.resync });
  };
  const handleCancel = () => {
    handleClose();
  };

  return (
    <>
      <DialogTitle id="responsive-dialog-title">
        Delete Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this forecast?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button autoFocus onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

const mapDispatchToProps = createDispatchBindings({ deleteForecast });

export default connect(null, mapDispatchToProps)(DeleteForecastModal);
