// action type constants
export const SET_INVISIBLE_FORECAST_MODEL = 'SET_INVISIBLE_FORECAST_MODEL';
export const SET_CURRENT_FORECAST_ID = 'SET_CURRENT_FORECAST_ID';
export const SET_CURRENT_FORECAST_DateTime = 'SET_CURRENT_FORECAST_DateTime';
export const GET_INVISIBLE_FORECAST_MODELS = 'GET_INVISIBLE_FORECAST_MODELS';
export const REMOVE_FORECAST_MODEL = 'REMOVE_FORECAST_MODEL';

// actionCreators
export const setInvisibleForecastModel = ({ value}) => ({ type: SET_INVISIBLE_FORECAST_MODEL, value});
export const setCurrentForecastId = ({ value}) => ({ type: SET_CURRENT_FORECAST_ID, value});
export const setCurrentForecastDateTime = ({ value}) => ({ type: SET_CURRENT_FORECAST_DateTime, value});

// actionCreators
export const removeForecastModel = ({ value}) => ({ type: REMOVE_FORECAST_MODEL, value});

// selectors
export const getInvisibleForecastModels = ({ hiddenForecastModels }) => hiddenForecastModels;
export const getSelectedForecastDateTime = ({ hiddenForecastModels }) => hiddenForecastModels.selectedForecastDateTime;


const initialState =
{ 
  hiddenModels: [],
  selectedForecastId: '',
  selectedForecastDateTime: ''
}
 

// reducer
export default (state = initialState, { type, value}) => {
  switch (type) {
    case SET_INVISIBLE_FORECAST_MODEL: {
         
           return {
                ...state,
                hiddenModels: state.hiddenModels.concat(value)
          } 
    }
    case SET_CURRENT_FORECAST_ID: {            
      return {
        ...state,
        selectedForecastId: value
        } 
    }
    case SET_CURRENT_FORECAST_DateTime: {            
      return {
        ...state,
        selectedForecastDateTime: value
        } 
    }
    case REMOVE_FORECAST_MODEL: {      
      let updatedState = Object.assign(state);
      if (updatedState.hiddenModels.length > 0)
      {
         let index= updatedState.hiddenModels.indexOf(value);
         if (index > -1){            
            return {
              ...state,
              hiddenModels: state.hiddenModels.filter(m=> m != value)
              } 
         }         
      }      
      return updatedState;  

    }
    default:
      return state;
  }
};