import { takeLatest } from 'redux-saga/effects';
import { TOGGLE_GAUGE_DISABLE } from '../../reducers/adminGauges';

function* ToggleGaugeDisable({ payload }) {
  try {
    const { gauge_id, column, bool } = payload;
    const resp = yield fetch(`/api/admin/gauges/disable/${gauge_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ column, bool }),
    });
    if (resp.status > 299) throw new Error('Invalid Response Code');
  } catch (e) {
    console.log('ERROR toggling gauge disable', e);
    // handle error
  }
}

export default function* watch() {
  yield takeLatest(TOGGLE_GAUGE_DISABLE, ToggleGaugeDisable);
}
