import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Grid from '@material-ui/core/Grid';
import NoData from '../NoData';
import { getGaugeDescription } from '../../reducers/gaugeDescription';
import { createDispatchBindings } from '../../utils/redux';

const useStyles = makeStyles({
  mainTitle: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  tableTitle: {
    fontStyle: 'italic',
    width: '100%',
  },
  tableWrapper: {
    maxHeight: '100%',
  },
  leftTable: {
    borderRight: '1px solid #efefef',
  },
});

const TitleCell = withStyles({
  root: {
    padding: '2px 0',
  },
})(TableCell);

const CondensedCell = withStyles({
  root: {
    padding: '2px 0',
    paddingLeft: 5,
    fontSize: 13,
  },
})(TableCell);

const StartCell = withStyles({
  root: {
    padding: '2px 0',
    paddingLeft: 10,
    paddingRight: 5,
    fontSize: 13,
  },
})(TableCell);

const SiteDetails = ({ currentGauge, thresholds, currentUser, getGaugeDescription, gaugeDescription }) => {
  const classes = useStyles();
  const [gaugeThresholds, setGaugeThresholds] = useState();

  useEffect(() => {
    if (thresholds.length && currentGauge) {
      setGaugeThresholds(thresholds.find(({ gauge_id }) => gauge_id === currentGauge.properties.site_id));
    }
  }, [thresholds, currentGauge]);

  useEffect(() => {
    if (currentGauge?.properties?.site_id) getGaugeDescription(currentGauge.properties.site_id);
  }, [currentGauge]);

  console.log(gaugeThresholds);

  if (currentGauge) {
    return (
      <Grid container spacing={0} className={classes.tableWrapper}>
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TitleCell align="center" className={classes.mainTitle}>{currentGauge.properties.site_id} - {currentGauge.properties.HCFCD_name}</TitleCell>
              </TableRow>
            </TableHead>
          </Table>
        </Grid>
        <Grid item xs={6}>
          <Table size="small" className={classes.leftTable} aria-label="simple table">
            <TableBody>
              <TableRow>
                <StartCell align="left">Site ID</StartCell>
                <CondensedCell align="left">{currentGauge.properties.site_id}</CondensedCell>
              </TableRow>
              <TableRow>
                <StartCell align="left">Name</StartCell>
                <CondensedCell align="left">{currentGauge.properties.HCFCD_name}</CondensedCell>
              </TableRow>
              <TableRow>
                <StartCell align="left">Location</StartCell>
                <CondensedCell align="left">{currentGauge.geometry.coordinates[0]}, {currentGauge.geometry.coordinates[1]}</CondensedCell>
              </TableRow>
              {/* <TableRow>
                <StartCell align="left">Reach</StartCell>
                <CondensedCell align="left">?</CondensedCell>
              </TableRow> */}
              <TableRow>
                <StartCell align="left">Watershed</StartCell>
                <CondensedCell align="left">{gaugeThresholds && gaugeThresholds.watershed !== -999 && gaugeThresholds.watershed}</CondensedCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6}>
          <Table size="small" aria-label="simple table">
            <TableBody>
              <TableRow>
                <StartCell className={classes.tableTitle} align="left">Thresholds</StartCell>
                <CondensedCell />
              </TableRow>
              <TableRow>
                <StartCell align="left">Flowline</StartCell>
                <CondensedCell align="left">{gaugeThresholds && gaugeThresholds.el_chbot !== -999 && gaugeThresholds.el_chbot}</CondensedCell>
              </TableRow>
              <TableRow>
                <StartCell align="left">Action</StartCell>
                <CondensedCell align="left">{gaugeThresholds && gaugeThresholds.el_fld_act !== -999 && gaugeThresholds.el_fld_act}</CondensedCell>
              </TableRow>
              <TableRow>
                <StartCell align="left">TOB</StartCell>
                <CondensedCell align="left">{gaugeThresholds && gaugeThresholds.el_chtop !== -999 && gaugeThresholds.el_chtop}</CondensedCell>
              </TableRow>
              <TableRow>
                <StartCell align="left">Road</StartCell>
                <CondensedCell align="left">{gaugeThresholds && gaugeThresholds.el_road !== -999 && gaugeThresholds.el_road}</CondensedCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        {(currentUser.role === 'ADMIN' || currentUser.role === 'SUPERUSER') && (
          <>
            <Grid item xs={6}>
              <Table size="small" className={classes.leftTable} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <CondensedCell className={classes.tableTitle} align="center">Flood Thresholds</CondensedCell>
                    <CondensedCell />
                  </TableRow>
                  <TableRow>
                    <StartCell align="left">Minor</StartCell>
                    <CondensedCell align="left">{gaugeThresholds && gaugeThresholds.el_fld_min !== -999 && gaugeThresholds.el_fld_min}</CondensedCell>
                  </TableRow>
                  <TableRow>
                    <StartCell align="left">Moderate</StartCell>
                    <CondensedCell align="left">{gaugeThresholds && gaugeThresholds.el_fld_mod !== -999 && gaugeThresholds.el_fld_mod}</CondensedCell>
                  </TableRow>
                  <TableRow>
                    <StartCell align="left">Major</StartCell>
                    <CondensedCell align="left">{gaugeThresholds && gaugeThresholds.el_fld_maj !== -999 && gaugeThresholds.el_fld_maj}</CondensedCell>
                  </TableRow>
                  <TableRow>
                    <StartCell align="left">Record</StartCell>
                    <CondensedCell align="left">{gaugeThresholds && gaugeThresholds.el_record !== -999 && gaugeThresholds.el_record}</CondensedCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={6}>
              <Table size="small" className={classes.leftTable} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <CondensedCell className={classes.tableTitle} align="center">Description</CondensedCell>
                  </TableRow>
                  <TableRow>
                    <StartCell align="left">{gaugeDescription}</StartCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
  return (
    <NoData />
  );
};

const mapStateToProps = ({ currentUser, gaugeDescription }) => ({ currentUser, gaugeDescription });
const mapDispatchToProps = createDispatchBindings({ getGaugeDescription });

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetails);
