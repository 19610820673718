import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { setBasemap } from '../../utils/map/leaflet';

const useStyles = makeStyles({
  formControl: {
    padding: '10px',
    width: '100%',
  },
  title: {
    margin: '0px 0px 5px 0px',
    fontWeight: 'bold',
  },
  select: {
    height: 30,
    fontSize: 14,
  },
});

const BasemapDropdown = () => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" size="small" className={classes.formControl}>
      <p className={classes.title}>Basemap:</p>
      <Select
        size="inherit"
        native
        onChange={(e) => setBasemap(e.target.value)}
        className={classes.select}
      >
        <option value="Streets">Streets</option>
        <option value="Default">Default</option>
        <option value="Imagery">Imagery</option>
        <option value="Topographic">Topographic</option>
        <option value="National Geographic">National Geographic</option>
      </Select>
    </FormControl>
  );
};

export default BasemapDropdown;
