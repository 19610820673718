import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import WavesIcon from '@material-ui/icons/Waves';
import OpacityIcon from '@material-ui/icons/Opacity';

import { updateGaugeIcons } from '../../utils/map/gaugeMarkers';
import { createDispatchBindings } from '../../utils/redux';
import { fetchRainfallStatus } from '../../reducers/rainfallStatus';
import { updateLayerValue } from '../../reducers/mapLayers';

const useStyles = makeStyles({
  toggleButton: {
    height: 26,
    width: 26,
    padding: 5,
  },
  toggleButtonGroup: {
    margin: 5,
  },
});

const GaugeIconToggle = ({ rainfallStatus, fetchRainfallStatus, dateRange, updateLayerValue, mapLayers }) => {
  const classes = useStyles();

  useEffect(() => {
    if (!rainfallStatus && mapLayers.gaugeIconType === 'rain') fetchRainfallStatus({ data_start: dateRange.start });
  }, [rainfallStatus, mapLayers.gaugeIconType]);

  const handleTogglingGaugeIconType = (e, value) => {
    updateGaugeIcons(value);
    updateLayerValue({ layerName: 'gaugeIconType', value });
  };

  return (
    <ToggleButtonGroup
      value={mapLayers.gaugeIconType}
      exclusive
      onChange={handleTogglingGaugeIconType}
      className={classes.toggleButtonGroup}
    >
      <ToggleButton className={classes.toggleButton} value="flow">
        <Tooltip title="Flow Icons">
          <WavesIcon fontSize="small" />
        </Tooltip>
      </ToggleButton>
      <ToggleButton className={classes.toggleButton} value="rain">
        <Tooltip title="Rain Icons">
          <OpacityIcon fontSize="small" />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const mapStateToProps = ({ rainfallStatus, dateRange, mapLayers }) => ({ rainfallStatus, dateRange, mapLayers });
const mapDispatchToProps = createDispatchBindings({ fetchRainfallStatus, updateLayerValue });

export default connect(mapStateToProps, mapDispatchToProps)(GaugeIconToggle);
