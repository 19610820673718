import { takeLatest, put } from 'redux-saga/effects';
import { setError } from '../../reducers/errors';
import { GET_USERS, setUsers } from '../../reducers/users';

import loading from '../../utils/loading';

function* fetchUsers() {
  const loadingMsg = 'Fetching Users...';
  loading.start(loadingMsg);
  try {
    const response = yield fetch('/api/users');
    if (response.status > 299) throw new Error('Error fetching users');
    const users = yield response.json();
    yield put(setUsers(users));
  } catch (e) {
    console.log('ERROR fetching users', e);
    yield put(setError({ errorId: 'fetchUsersError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(GET_USERS, fetchUsers);
}
