import { takeLatest, put } from 'redux-saga/effects';
import { GET_ADMIN_GAUGES, setAdminGauges } from '../../reducers/adminGauges';

import loading from '../../utils/loading';

function* GetAdminGauges() {
  const loadingMsg = 'Fetching Admin Gauges Data...';
  loading.start(loadingMsg);
  try {
    const resp = yield fetch('/api/admin/gauges');
    if (resp.status > 299) throw new Error('Invalid Response Code');
    const data = yield resp.json();
    yield put(setAdminGauges(data));
  } catch (e) {
    console.log('ERROR fetching Admin Gauges data', e);
    // handle error
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(GET_ADMIN_GAUGES, GetAdminGauges);
}
