import { put } from 'redux-saga/effects';

import { SETUP_MAP_SERVICES, setMapRefs } from '../reducers/mapRefs';

import { takeOnce } from '../utils/redux';
import { createMap } from '../utils/map/leaflet';

function* setupMapServices() {
  const mapDiv = document.getElementById('map_canvas');
  // creates map instance
  const map = yield createMap();
  map.toJSON = () => '<map>';
  // set map reference in redux store
  yield put(setMapRefs({ mapDiv, map }));
}

export default function* watch() {
  yield takeOnce(SETUP_MAP_SERVICES, setupMapServices);
}
