/* eslint-disable import/no-cycle */
import moment from 'moment';
import { takeLatest, put, select } from 'redux-saga/effects';

import { setError } from '../../reducers/errors';
import { FETCH_GAUGE_FORECAST, setGaugeForecast } from '../../reducers/gaugeForecast';
import { setDateObject,getDateRange } from '../../reducers/dateRange';
import { fetchGaugeData } from '../../reducers/selectedGaugeData';
import { clearSummaryTableForecasts } from '../../reducers/summaryTable';

import loading from '../../utils/loading';
import parseForecastsForSummaryTable from '../../utils/parseForecastsForSummaryTable';
import {stageFlowSourceAliasSorted} from '../../../src/config/constants';


function* fetchGaugeForecast({ forecast_id, gauge_id }) {
  const loadingMsg = 'Fetching Forecast Details...';
  loading.start(loadingMsg);
  try {
    yield put(setGaugeForecast(false));
    const { start, end,endActive } = yield select(getDateRange);
    let response=null;

    if (!endActive)
        response = yield fetch(`/api/gauges/gaugeforecast/${forecast_id}/${gauge_id}`);
    else
    {  
        let url = `/api/gauges/gaugeforecast/${forecast_id}/${gauge_id}?data_start='${start}'&data_end='${end}'`;      
        response = yield fetch(`/api/gauges/gaugeforecast/${forecast_id}/${gauge_id}?data_start='${start}'&data_end='${end}'`);
    }
        
    if (response.status > 299) throw new Error('Error fetching forecast');
    const rawForecast = yield response.json();
    let forecast=[];   
    
    if (rawForecast.length)
    {
      for (let index = 0; index < stageFlowSourceAliasSorted.length; index++) {
        const stageFlowSourceAlias = stageFlowSourceAliasSorted[index]; 

        for (let i= 0; i < rawForecast.length; i++)
        {
            const sourceAndDevice = `${rawForecast[i].reading_source}${rawForecast[i].device}`;
            if (sourceAndDevice === stageFlowSourceAlias) {
              forecast.push(rawForecast[i]);
              break;  
            }
        }
      }
      for (let i= 0; i < rawForecast.length; i++)
      {
          const device = `${rawForecast[i].device}`;
          if (device === 'ERR_LOWER' || device==='ERR_UPPER' || device === 'MEAN')
            forecast.push(rawForecast[i]);
      }   
    }
    
    if (forecast.length) {
      forecast[0].full_readings.sort((a, b) => moment(a.reading_time).diff(moment(b.reading_time)));
      const forecastStartTime = forecast[0].full_readings[0].reading_time;
      const forecastEndTime = forecast[0].full_readings[forecast[0].full_readings.length - 1].reading_time;

      if (!endActive) 
      {
        yield put(setDateObject({
          start: moment.utc(forecastStartTime).subtract(24, 'hours').format(),
          end: moment.utc(forecastEndTime).format(),
          startActive: true,
          endActive: true,
        }));
      }      
      if (gauge_id) yield put(fetchGaugeData(gauge_id));
    } 
    else {
      if (!endActive)
      {
        yield put(setDateObject({
          start: moment.utc().subtract(24, 'hours').format(),
          end: moment.utc().format(),
          startActive: false,
          endActive: false,
        }));
      }
      if (gauge_id) yield put(fetchGaugeData(gauge_id));
    }
    yield put(setGaugeForecast(forecast));
    yield put(clearSummaryTableForecasts());
    yield parseForecastsForSummaryTable(forecast);
  } catch (e) {
    console.log('ERROR fetching forecast', e);
    yield put(setError({ errorId: 'fetchGaugeForecastError', value: e.message || true }));
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(FETCH_GAUGE_FORECAST, fetchGaugeForecast);
}
