import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import SpeedIcon from '@material-ui/icons/Speed';
import RoomIcon from '@material-ui/icons/Room';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import history from '../../history';
import fuzzySearchGauges from '../../utils/fuzzySearch/fuzzySearchGauges';
import { geocodeSuggestSearch, handleAddressSelection } from '../../utils/geocoding';
import Show from '../HOC/Show';

const useStyles = makeStyles({
  select: {
    width: 'calc(100% - 38px)',
    maxWidth: 300,
  },
  selectWrapper: {
    marginLeft: 5,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  iconWrapper: {
    width: 38,
    border: '1px solid #efefef',
    borderRadius: 5,
    marginRight: -5,
    marginLeft: 'auto',
    paddingTop: 4,
    height: 38,
  },
  searchIcon: {
    color: '#efefef',
    zIndex: 100,
    fontSize: 28,
  },
});

const optionStyles = {
  option: (provided) => ({
    ...provided,
    color: 'black',
  }),
};

const constraint = {
  fieldType: 'autofill',
  constraintId: 'project_id',
  displayName: 'Project Name',
  searchTerm: 'Projects',
};

const AutofillInput = ({ gauges, forecastIconData }) => {
  const classes = useStyles();
  const historyHook = useHistory();

  const [activeGauges, setActiveGauges] = useState([]);
  const [inputText, setInputText] = useState('');
  const [searchType, setSearchType] = useState('gauge');
  const [inputSelection, setInputSelection] = useState({ label: '', value: '' });

  useEffect(() => {
    if (gauges?.features?.length && forecastIconData?.length) {
      const inactiveGaugeIds = forecastIconData.reduce((acc, { gauge_id, disable_all }) => {
        if (disable_all) acc = [...acc, gauge_id];
        return acc;
      }, []);
      const activeGauges = gauges.features.filter(({ properties }) => !inactiveGaugeIds.includes(properties.site_id));
      setActiveGauges({ ...gauges, features: activeGauges });
    }
  }, [gauges, forecastIconData]);

  const toggleSearchType = () => {
    const newType = searchType === 'gauge' ? 'address' : 'gauge';
    setSearchType(newType);
  };

  const handleChange = (selection) => {
    if (searchType === 'gauge') {
      // change gaugeId in URL to the selected gauge from dropdown
      if (parseInt(selection?.value, 10)) {
        historyHook.push(`/${history.location.pathname.split('/')[1]}/${selection.value}`);
      }
    } else {
      handleAddressSelection(selection);
    }
    setInputSelection(selection || { label: '', value: '' });
  };

  const handleLoadingOptions = async ({ query, gauges }) => {
    if (searchType === 'gauge') {
      console.log('gauges: ', gauges);
      const response = await fuzzySearchGauges(query, gauges);
      return response;
    }
    const { suggestions } = await geocodeSuggestSearch(query);
    const formattedResults = suggestions.map(({ text, magicKey }) => ({ label: text, value: { text, magicKey } }));
    return formattedResults;
  };

  return (
    <div className={classes.selectWrapper}>
      <Button className={classes.iconWrapper} onClick={toggleSearchType}>
        <Show test={searchType === 'gauge'}>
          <Tooltip title="Gauge Search">
            <SpeedIcon className={classes.searchIcon} />
          </Tooltip>
        </Show>
        <Show test={searchType === 'address'}>
          <Tooltip title="Address Search">
            <RoomIcon className={classes.searchIcon} />
          </Tooltip>
        </Show>
      </Button>
      <AsyncSelect
        loadOptions={(query) => handleLoadingOptions({ query, gauges: activeGauges })}
        noResultsText="No results found"
        getOptionLabel={({ label }) => label}
        getOptionValue={({ value }) => value}
        onChange={handleChange}
        onInputChange={(value) => setInputText(value)}
        isClearable
        escapeClearsValue
        value={inputSelection}
        placeholder={`Search ${constraint.searchTerm}...`}
        components={{ DropdownIndicator: () => null }}
        className={classes.select}
        styles={optionStyles}
        theme={(theme) => ({
          ...theme,
          paddingLeft: 40,
        })}
      />
    </div>
  );
};

const mapStateToProps = ({ gauges, forecastIconData }) => ({ gauges, forecastIconData });

export default connect(mapStateToProps)(AutofillInput);
