import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import DialogContentText from '@material-ui/core/DialogContentText';

import Show from '../HOC/Show';
import SubscriptionRow from './SubscriptionRow';
import { fetchSubscriptions } from '../../reducers/subscriptions';
import { createDispatchBindings } from '../../utils/redux';

const useStyles = makeStyles({
  tableCell: {
    padding: 10,
    fontWeight: 'bold',
  },
});

const SubscriptionManagementModal = ({ handleClose, fetchSubscriptions, subscriptions }) => {
  const classes = useStyles();

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <>
      <DialogTitle id="responsive-dialog-title">Subscription Management</DialogTitle>
      <DialogContent>
        <Show test={subscriptions.length}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Gauge ID</TableCell>
                  <TableCell className={classes.tableCell} align="center">Subscription Name</TableCell>
                  <TableCell className={classes.tableCell} align="center">Threshold</TableCell>
                  <TableCell className={classes.tableCell} align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions.map((subscription) => (
                  <SubscriptionRow key={subscription.subscription_id} subscription={subscription} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Show>
        <Show test={!subscriptions.length}>
          <DialogContentText>No active subscriptions.</DialogContentText>
        </Show>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus onClick={handleClose} color="primary">
          close
        </Button>
      </DialogActions>
    </>
  );
};

const mapStateToProps = ({ subscriptions }) => ({ subscriptions });
const mapDispatchToProps = createDispatchBindings({ fetchSubscriptions });

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionManagementModal);
