// action type constants
export const SETUP_MAP_SERVICES = 'SETUP_MAP_SERVICES';
export const SET_MAP_REFS = 'SET_MAP_REFS';

// actionCreators
export const setupMapServices = () => ({ type: SETUP_MAP_SERVICES });
export const setMapRefs = (mapRefs) => ({ type: SET_MAP_REFS, mapRefs });

const initialState = {
  mapDiv: '',
};
export const getMap = (state) => state.mapRefs.map;
// reducer
export default (state = initialState, { type, mapRefs }) => {
  switch (type) {
    case SET_MAP_REFS:
      return { ...state, ...mapRefs };
    default:
      return state;
  }
};
