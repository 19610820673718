import Fuse from 'fuse.js';

const gaugeFuseOptions = {
  keys: ['properties.HCFCD_name', 'properties.site_id'],
  findAllMatches: true,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 1000,
  maxPatternLength: 32,
  minMatchCharLength: 1,
};

let gaugeFuse;

const fuzzySearchGauges = async (queryValue, gauges) => {
  if (!gaugeFuse) {
    gaugeFuse = new Fuse(gauges.features, gaugeFuseOptions);
  }
  const searchResults = gaugeFuse.search(queryValue);
  const formattedResults = searchResults.map((result) => ({
    label: `${result.item.properties.site_id} - ${result.item.properties.HCFCD_name}`,
    value: result.item.properties.site_id,
  }));
  return formattedResults;
};

export default fuzzySearchGauges;
