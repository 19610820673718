// action type constants
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const SET_GAUGE_INFO_DRAWER_TAB = 'SET_GAUGE_INFO_DRAWER_TAB';

// actionCreators
export const openDrawer = () => ({ type: OPEN_DRAWER });
export const closeDrawer = () => ({ type: CLOSE_DRAWER });
export const setGaugeInfoDrawerTab = (tab) => ({ type: SET_GAUGE_INFO_DRAWER_TAB, tab });

const initialState = {
  open: false,
  tab: 0, // 0 = Stage/Flow, 1 = Mrms Rainfall
};

// reducer
export default (state = initialState, { type, tab }) => {
  switch (type) {
    case OPEN_DRAWER: {
      state.open = true;
      return state;
    }
    case CLOSE_DRAWER:
      state.open = false;
      return state;
    case SET_GAUGE_INFO_DRAWER_TAB:
      state.tab = tab;
      return state;
    default:
      return state;
  }
};
