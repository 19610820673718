import { takeLatest, put } from 'redux-saga/effects';
import { GET_GAUGE_DESCRIPTION, setGaugeDescription } from '../reducers/gaugeDescription';

function* GetGaugeDescription({ gauge_id }) {
  try {
    const resp = yield fetch(`/api/gauges/description/${gauge_id}`);
    if (resp.status > 299) throw new Error('Invalid Response Code');
    const data = yield resp.json();
    yield put(setGaugeDescription(data));
  } catch (e) {
    console.log('ERROR getting gauge description', e);
    // handle error
  }
}

export default function* watch() {
  yield takeLatest(GET_GAUGE_DESCRIPTION, GetGaugeDescription);
}
