import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Show from '../HOC/Show';
import { createDispatchBindings } from '../../utils/redux';
import { createSubscription } from '../../reducers/subscriptions';
import { thresholdAlias } from '../../config/constants';

const useStyles = makeStyles({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 500,
  },
  subscribeControlsWrapper: {
    width: '100%',
  },
  customHeightInput: {
    width: '100%',
    margin: '10px 0',
    fontSize: 14,
    padding: 5,
  },
});

const GuageSubModal = ({ data: { gaugeId }, handleClose, gaugeThresholds, createSubscription }) => {
  const classes = useStyles();
  const [thresholdValue, setThresholdValue] = useState();
  const [gaugeThresholdOptions, setGaugeThresholdOptions] = useState([]);
  const [notificationName, setNotificationName] = useState('');
  const [customHeight, setCustomHeight] = useState(0);

  useEffect(() => {
    // format threshold options for dropdown
    const availableThresholds = ['el_fld_act', 'el_chtop', 'el_road', 'el_record', 'el_levee', 'el_spill'];
    const options = [{ label: '', value: '' }];
    const gaugeThresholdData = gaugeThresholds.data.find(({ gauge_id }) => Number(gauge_id) === Number(gaugeId));
    if (gaugeThresholdData) {
      for (const [key, value] of Object.entries(gaugeThresholdData)) {
        if (availableThresholds.includes(key) && value && Number(value) !== -999) {
          options.push({ label: `${thresholdAlias[key]} (${value} ft.)`, value });
        }
      }
    }
    options.push({ label: 'Custom Height', value: 'custom' });
    setGaugeThresholdOptions(options);
  }, [gaugeThresholds.data]);

  const handleSubscribe = () => {
    createSubscription({
      gauge_id: gaugeId,
      threshold: thresholdValue?.value === 'custom' ? customHeight : thresholdValue.value,
      name: notificationName,
    });
    handleClose();
  };

  return (
    <>
      <DialogTitle id="responsive-dialog-title">Setup Custom Notifications for {gaugeId}</DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <p>Setup up custom notifications using the following fields.</p>
        <div className={classes.subscribeControlsWrapper}>
          <p>custom notification name</p>
          <TextField
            label="Notification Name"
            variant="outlined"
            className={classes.nameInput}
            size="small"
            fullWidth
            value={notificationName}
            onChange={(e) => setNotificationName(e.target.value)}
          />
          <p>threshold dropdown</p>
          <Select
            className={classes.roleDropdown}
            classNamePrefix="select"
            options={gaugeThresholdOptions}
            value={thresholdValue}
            onChange={(selection) => setThresholdValue(selection)}
          />
          <Show test={thresholdValue?.value === 'custom'}>
            <input
              className={classes.customHeightInput}
              step="0.01"
              type="number"
              value={customHeight}
              onChange={(e) => setCustomHeight(e.target.value)}
            />
          </Show>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
        >
          close
        </Button>
        <Button
          disabled={!thresholdValue?.value || !notificationName?.length || (thresholdValue?.value === 'custom' && !customHeight)}
          variant="contained"
          onClick={handleSubscribe}
          color="primary"
        >
          Subscribe
        </Button>
      </DialogActions>
    </>
  );
};

const mapStateToProps = ({ gaugeThresholds }) => ({ gaugeThresholds });
const mapDispatchToProps = createDispatchBindings({ createSubscription });

export default connect(mapStateToProps, mapDispatchToProps)(GuageSubModal);
