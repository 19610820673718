import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import { makeStyles } from '@material-ui/core/styles';

import { plotLineColorsArray } from '../../../config/constants';
import { createDispatchBindings } from '../../../utils/redux';
import ResetZoomButton from '../ResetZoomButton';
import generateRainAccumulationYValues from '../RainfallPlot/generateRainAccumulationYValues';

Chart.register(zoomPlugin);

const useStyles = makeStyles({
  chartWrapper: {
    width: '100%',
    height: '90%',
    paddingTop: 15,
  },
});

const chartConfig = {
  type: 'line',
  data: {},
  options: {
    // prevents animations
    animation: {
      duration: 0,
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    },
    // prevents canvas height changes with width changes
    maintainAspectRatio: false,
    // remove points on lines
    elements: {
      point: {
        radius: 0, // looks good with 1
      },
    },
    // axis options
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            millisecond: 'MM/DD/YYYY HH:mm',
            second: 'MM/DD/YYYY HH:mm',
            minute: 'MM/DD/YYYY HH:mm',
            hour: 'MM/DD/YYYY HH:mm',
            day: 'MM/DD/YYYY HH:mm',
            week: 'MM/DD/YYYY HH:mm',
            month: 'MM/DD/YYYY HH:mm',
            quarter: 'MM/DD/YYYY HH:mm',
            year: 'MM/DD/YYYY HH:mm',
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Incremenet (in/hr)',
        },
        type: 'linear',
        reverse: true,
        display: true,
        position: 'left',
        ticks: {
          callback: (value) => Number(value).toFixed(2),
          beginAtZero: true,
          stepSize: 0.01,
          maxTicksLimit: 5,
        },
      },
      y1: {
        title: {
          display: true,
          text: 'Accumulation (in)',
        },
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false, // hide gridlines for secondary y-axis
        },
        ticks: {
          callback: (value) => Number(value).toFixed(2),
          maxTicksLimit: 5,
        },
      },
    },
    plugins: {
      autocolors: false,
      zoom: {
        zoom: {
          drag: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  },
};

const MrmsRainfallChart = ({ mrmsPointRainfall }) => {
  const classes = useStyles();
  const chartContainer = useRef(null);
  const [rainfallChart, setRainfallChart] = useState();

  useEffect(() => {
    if (!mrmsPointRainfall?.data?.length || !rainfallChart) return;
    const rainfallData = mrmsPointRainfall.data;
    const rainfallDataSeries = [];
    console.log('rainfallData', rainfallData);
    rainfallData.forEach(({ value, time }) => {
      rainfallDataSeries.push({ x: moment(time).format(), y: value });
    });
    rainfallDataSeries.sort((a, b) => moment(a.x).diff(moment(b.x)));

    // Create chart series
    const chartSeries = [];
    if (rainfallDataSeries.length) {
      chartSeries.push({
        type: 'bar',
        barPercentage: 1,
        categoryPercentage: 1.0,
        label: 'Increment',
        data: rainfallDataSeries,
        borderColor: plotLineColorsArray.rainfall[0],
        backgroundColor: plotLineColorsArray.rainfall[0],
        borderWidth: 1,
        yAxisID: 'y',
      });
      chartSeries.push({
        type: 'line',
        label: 'Accumulation',
        data: generateRainAccumulationYValues(rainfallDataSeries),
        borderColor: plotLineColorsArray.rainfall[1],
        backgroundColor: plotLineColorsArray.rainfall[1],
        borderWidth: 1,
        yAxisID: 'y1',
      });
    }
    // SET PLOT DATA
    if (chartSeries?.length && rainfallDataSeries.length) {
      rainfallChart.data.datasets = chartSeries;
      rainfallChart.update();
    }
  }, [mrmsPointRainfall, rainfallChart]);

  useEffect(() => {
    if (chartContainer && chartContainer.current && !rainfallChart) {
      const chartInstance = new Chart(chartContainer.current, chartConfig);
      setRainfallChart(chartInstance);
    }
  }, [chartContainer]);

  return (
    <div className={classes.chartWrapper}>
      <ResetZoomButton chartRef={rainfallChart} />
      <canvas id="mrms-chart" ref={chartContainer} width="100%" />
    </div>
  );
};

const mapStateToProps = ({ mrmsPointRainfall }) => ({ mrmsPointRainfall });
const mapDispatchToProps = createDispatchBindings({ });

export default connect(mapStateToProps, mapDispatchToProps)(MrmsRainfallChart);
