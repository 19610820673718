import moment from 'moment';
import { convertToUTCDateFormat } from './../utils/convertTime';
// action type constants
export const SET_DATE_FOR_FORECAST_DROPDOWN = 'SET_DATE_FOR_FORECAST_DROPDOWN';
export const GET_DATE_FOR_FORECAST_DROPDOWN = 'GET_DATE_FOR_FORECAST_DROPDOWN';

// actionCreators
export const setDateForForecastDropdown = ({ value, isResetToCurrentDate }) => ({ type: SET_DATE_FOR_FORECAST_DROPDOWN, value, isResetToCurrentDate });
// selectors
export const getDateForForecastDropdown = ({ dateForForecastDropdown }) => dateForForecastDropdown;

const initialState = moment.utc().format('YYYY-MM-DD');
 

// reducer
export default (state = initialState, { type, value, isResetToCurrentDate}) => {
  switch (type) {
    case SET_DATE_FOR_FORECAST_DROPDOWN: {
      if (isResetToCurrentDate)
      {
          state= moment.utc().format('YYYY-MM-DD');
          return state;
      }
      else
      {
        state= convertToUTCDateFormat(value);
        return state;
      }
    }    
    default:
      return state;
  }
};
