import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import { createDispatchBindings } from '../../utils/redux';
import { startAdvancing, stopAdvancing, advTime, decTime, setDelay, getTimes, addToMap, rmFromMap, updateTime, changeSelected, setAnimationTicks } from '../../reducers/radar-ogc';
import LayerOpacitySlider from './LayerOpacitySlider';

import config from '../../config';

const { drawerMinWidth, drawerMaxWidth } = config;
const { DateTime } = require('luxon');

const useStyles = makeStyles({
  radarContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    minWidth: drawerMinWidth,
    maxWidth: drawerMaxWidth,
    overflowX: 'hidden',
  },
  layerSectionTitle: {
    margin: 0,
    marginLeft: 10,
    textAlign: 'left',
  },
  textField: {
    margin: 'auto',
    width: '100%',
    padding: '0px 20px',
  },
  timeText: {
    padding: '12px',
  },
  topTextfield: {
    marginBottom: 10,
  },
  radarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    paddingBottom: 0,
  },
  slider: {
    width: 279,
    margin: '0px 10px',
  },
  radarControlsWrapper: {
    // width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 10px',
  },
  buttonGroup: {
    padding: '0 10px 0 5px',
    display: 'flex',
    alignItems: 'center',
    width: 279,
  },
  delayInput: {
    width: '100%',
  },
  formControl: {
    padding: '10px',
    width: '100%',
  },
  title: {
    margin: '0px 0px 5px 0px',
    fontWeight: 'bold',
  },
});
const AdvancingButton = ({ stopAdvancing, startAdvancing, advancing }) => {
  if (advancing) {
    return (
      <IconButton onClick={stopAdvancing}>
        <PauseIcon />
      </IconButton>
    );
  }
  return (
    <IconButton onClick={startAdvancing}>
      <PlayArrowIcon />
    </IconButton>
  );
};
const ControlWidget = ({ radar: { current, advancing, delay, onMap, times, autoRefresh, currentLayer, clampedTimes, animationTicks }, map, mapLayers, startAdvancing, stopAdvancing, advTime, decTime, setDelay, getTimes, addToMap, rmFromMap, updateTime, changeSelected, setAnimationTicks }) => {
  const classes = useStyles();
  const timeDimension = map?.timeDimension;
  const currentTime = timeDimension?.getCurrentTime();
  const [animationDurationOptions, setAnimationDurationOptions] = useState([]);

  useEffect(() => {
    if (!times || !times.length) {
      getTimes();
    }
  }, [times, getTimes]);

  useEffect(() => {
    if (times.length) {
      // updateTime(times.length - 1);
      const options = [];
      for (let i = 2; i <= times.length; i += 1) {
        options.push(i);
      }
      setAnimationDurationOptions(options);
    }
  }, [times]);

  useEffect(() => {
    if (!timeDimension) {
      return;
    }
    if (!timeDimension || !timeDimension.setCurrentTime) {
      return;
    }
    const currentChosen = times.indexOf(currentTime);
    if (currentChosen !== current) {
      timeDimension.setCurrentTime(times[current]);
    }
  }, [current, currentTime, timeDimension, times]);

  useEffect(() => {
    let interval = false;
    if (autoRefresh) {
      interval = setInterval(() => {
        getTimes(true);
      }, 1000 * 60 * 5);
    }
    return () => {
      if (interval !== false) {
        clearInterval(interval);
      }
    };
  }, [autoRefresh]);

  if (!onMap) {
    return (
      <div className={classes.radarContentWrapper}>
        <div className={classes.radarHeader}>
          <p className={classes.layerSectionTitle}>Radar</p>
          <Switch
            checked={false}
            onChange={addToMap}
          />
        </div>
      </div>
    );
  }
  return (
    <div className={classes.radarContentWrapper}>
      <div className={classes.radarHeader}>
        <Tooltip title="Source" placement="right">
          <Select
            size="inherit"
            native
            value={currentLayer}
            onChange={(e) => changeSelected(e.target.value)}
          >
            <option value="local khgx">local khgx</option>
            <option value="national mosaic">national mosaic</option>
          </Select>
        </Tooltip>
        <div className={classes.headerRight}>
          {/* <Tooltip title="Animation Ticks" placement="left">
            <Select
              value={animationTicks}
              onChange={(e) => setAnimationTicks(e.target.value)}
            >
              {animationDurationOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </Tooltip> */}
          <Switch
            checked
            onChange={rmFromMap}
          />
        </div>
      </div>
      <div className={classes.radarControlsWrapper}>
        <ButtonGroup className={classes.buttonGroup}>
          <IconButton onClick={decTime} disabled={advancing}>
            <SkipPreviousIcon />
          </IconButton>
          <AdvancingButton advancing={advancing} startAdvancing={startAdvancing} stopAdvancing={stopAdvancing} />
          <IconButton onClick={advTime} disabled={advancing}>
            <SkipNextIcon />
          </IconButton>
          <span className={classes.timeText}>{DateTime.fromMillis(times[current] || 0).toLocaleString(DateTime.DATETIME_SHORT)}</span>
        </ButtonGroup>
        <Slider
          className={classes.slider}
          value={current}
          onChange={(e, newValue) => updateTime(newValue)}
          min={0}
          max={clampedTimes.length - 1}
        />
        <LayerOpacitySlider layerName="radar-ogc" mapLayers={mapLayers} />
        <TextField
          id="filled-helperText"
          label="Delay In Seconds"
          variant="filled"
          className={classes.delayInput}
          value={delay}
          onChange={(e) => setDelay(e.target.value)}
        />
      </div>
    </div>
  );
};
const mapStateToProps = ({ radarOGC, mapLayers, mapRefs: { map } }) => ({ radar: radarOGC, mapLayers, map });
const mapDispatchToProps = createDispatchBindings({ startAdvancing, stopAdvancing, advTime, decTime, updateTime, setDelay, getTimes, addToMap, rmFromMap, changeSelected, setAnimationTicks });

export default connect(mapStateToProps, mapDispatchToProps)(ControlWidget);
