import React, { useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { createDispatchBindings } from '../../utils/redux';
import { updateGaugeDescription } from '../../reducers/adminGauges';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    marginRight: 5,
  }
});

const DescriptionControls = ({ gauge, updateGaugeDescription }) => {
  const classes = useStyles();
  const [gaugeDescription, setGaugeDescription] = useState(gauge.description || '');

  return (
    <div className={classes.root}>
      <TextField
        value={gaugeDescription}
        size="small"
        variant="outlined"
        className={classes.textField}
        onChange={(e) => setGaugeDescription(e.target.value)}
      />
      <Button
        onClick={() => updateGaugeDescription({ gauge_id: gauge.gauge_id, description: gaugeDescription })}
        variant="contained"
        color="primary"
      >
        Save
      </Button>
    </div>
  );
};

const mapDispatchToProps = createDispatchBindings({ updateGaugeDescription });

export default connect(null, mapDispatchToProps)(DescriptionControls);
