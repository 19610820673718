// action type constants
export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

// actionCreators
export const getUsers = () => ({ type: GET_USERS });
export const setUsers = (users) => ({ type: SET_USERS, users });
export const updateUser = (user) => ({ type: UPDATE_USER, user });

const initialState = [];

// reducer
export default (state = initialState, { type, users }) => {
  switch (type) {
    case SET_USERS:
      return users;
    default:
      return state;
  }
};
