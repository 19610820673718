import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import { createDispatchBindings } from '../../../utils/redux';
import { setRainfallLookbackPeriod } from '../../../reducers/rainfallLookbackPeriod';

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 140,
  },
  select: {
    height: 20,
    marginTop: 2,
    fontSize: 12,
    padding: 0,
  },
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const dropdownOptions = ['-', 1, 3, 6, 12, 24];

const RainfallLookbackDropdown = ({ rainfallLookbackPeriod, setRainfallLookbackPeriod }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Tooltip placement="left" title="Lookback Period">
        <Select
          value={rainfallLookbackPeriod}
          onChange={(e) => setRainfallLookbackPeriod(e.target.value)}
          className={classes.select}
          variant="outlined"
          classes={{
            root: classes.root,
          }}
        >
          {dropdownOptions.map((option) => (
            <MenuItem key={option} value={option}>{option}{option !== '-' && ' hr'}</MenuItem>
          ))}
        </Select>
      </Tooltip>
    </div>
  );
};

const mapStateToProps = ({ rainfallLookbackPeriod }) => ({ rainfallLookbackPeriod });
const mapDispatchToProps = createDispatchBindings({ setRainfallLookbackPeriod });

export default connect(mapStateToProps, mapDispatchToProps)(RainfallLookbackDropdown);
