// action type constants
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// actionCreators
export const getCurrentUser = () => ({ type: GET_CURRENT_USER });
export const setCurrentUser = (user) => ({ type: SET_CURRENT_USER, user });

const initialState = {};

// reducer
export default (state = initialState, { type, user }) => {
  switch (type) {
    case SET_CURRENT_USER:
      return user;
    default:
      return state;
  }
};
