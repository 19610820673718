// action type constants
export const SET_GAUGES = 'SET_GAUGES';
export const MAYBE_RUN_ICON_STUFF = 'MAYBE_RUN_ICON_STUFF';
// actionCreators
export const setGauges = (data) => ({ type: SET_GAUGES, data });
export const maybeRunIconStuff = () => ({ type: MAYBE_RUN_ICON_STUFF });
// selector
export const getGauges = ({ gauges }) => gauges;

const initialState = {};

// reducer
export default (state = initialState, { type, data }) => {
  switch (type) {
    case SET_GAUGES:
      return data;
    default:
      return state;
  }
};
